import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Link, LinkProps } from "react-router-dom"

//
interface BUTTON_PROPS {
  color?: 'primary' | 'white' | 'danger' | 'black';
  size?: 'large' | 'middle' | 'regular' | 'small' | 'xsmall';
  border?: boolean;
  disabled?: boolean;
}

//
type BUTTON_ELEMENT_PROPS = BUTTON_PROPS & LinkProps & React.RefAttributes<HTMLAnchorElement>


// --- --- ---

// ボンタの基本スタイル
const ButtonBasicStyle = css`
  display: block;
  background-color: var(--secondary);
  border-radius: 3px;
  padding: .5rem 1rem;
  font-weight: bold;
  line-height: 1em;
  text-align: center;
`

// props用のスタイル
const ButtonPropsStyle = ({ color, size, border, disabled}: BUTTON_PROPS) => css`


  // 文字サイズ
  ${size === 'large' && css`
    padding: 1rem 1.2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    letter-spacing: .02em;
  `}
  ${size === 'middle' && css`
    padding: .8rem 1rem;
    border-radius: 6px;
    font-size: 1rem;
    letter-spacing: .02em;
  `}
  ${size === 'small' && css`
    padding: .3rem .4rem;
    border-radius: 2px;
    font-size: .84rem;
  `}
  ${size === 'xsmall' && css`
    padding: .3rem .4rem;
    border-radius: 2px;
    font-size: .76rem;
  `}
  ${!size && css`
    font-size: .92rem;
  `}

  // ノーマルボーダー
  ${(border) && css`border: 1px solid var(--color-100);`}


  // プライマリー
  ${color === 'primary' && 
    css`
      background-color: var(--primary);
      color: var(--color-100);
    `
  }
  ${(color === 'primary' && border) &&
    css`
      background-color: var(--color-100);
      border-color: var(--primary);
      color: var(--primary);
    `
  }

  // 白
  ${color === 'white' && css`
    background-color: var(--color-100);
    border-color: var(--primary);
    color: var(--primary);
  `}

  // 警告色
  ${color === 'danger' &&
    css`
      background-color: var(--danger);
      color: var(--color-100);
    `
  }
  ${(color === 'danger' && border) &&
    css`
      background-color: var(--color-100);
      border-color: var(--danger);
      color: var(--danger);
    `
  }

  // ノーマル
  ${!color && css`
    color: var(--color-100);
  `}


  // disabled
  ${disabled && css`
    background-color: var(--color-400);
    border-color: var(--color-400);
    color: var(--color-100);
  `}
`

// --- --- ---

/**
 * 基本のボタン
 */
export const BasicButton = styled.button<BUTTON_PROPS>`
  ${ButtonBasicStyle}
  ${ButtonPropsStyle}
`

/**
 * ボタンスタイルのLinkエレメント
 */
export const LinkButton = styled(({ color, size, border, ...props }: BUTTON_ELEMENT_PROPS) => (<Link {...props} />))`
  ${ButtonBasicStyle}
  ${ButtonPropsStyle}
`