import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import useSWRImmutable from 'swr/immutable'
import IntegrationHead from "../../../components/elements/integration/IntegrationHead"
import JiraDeleteConfirm from "../../../components/elements/integration/jira/JiraDeleteConfirm"
import JiraHead from "../../../components/elements/integration/jira/JiraHead"
import JiraNodeView from "../../../components/elements/integration/jira/JiraNodeView"
import { JIRA_NODE, popNode } from "../../../components/elements/integration/jira/NodeUtils"
import ModalPortal from "../../../components/elements/modal/ModalPortal"
import CollaboratorContext from "../../../components/elements/projects/detail/CollaboratorContext"
import tokenState from "../../../state/Auth0"
import { MainWrapper } from "../../../styles/styled/layouts/MainWrapper.styled"

//
const ProjectJira: React.FC = () => {
  // 当該ユーザーがコラボレーターに含まれているかのコンテキスト
  const isCollaborator = useContext(CollaboratorContext)
  const navigator = useNavigate()

  // プロジェクトの概要データを取得
  const { projectId } = useParams()
  // トークン
  const authToken = useRecoilValue(tokenState)
  //
  const { data: projectData } = useSWRImmutable([ `/project/${projectId}`, authToken ], { suspense: true })
  //
  const { data: jiraData } = useSWRImmutable([ `/project/${projectId}/ticket`, authToken], { suspense: true })

  //
  const [jiraDataState, setJiraDataState] = useState<JIRA_NODE>({ id: '-1', label: 'root', nodes: []})

  //
  const [modalVisible, setModalVisible] = useState(false)
  const deleteBlockPath = useRef<string[]>([])

  // 直リンク対応（ルーター側へ移設した方が良いかも）
  useEffect(() => {
    if (projectData.isPrivate && isCollaborator !== 2) {
      // プライベート＆コラボレーターでは無いので、プロジェクトトップへ
      navigator(`/projects/${projectId}`)
    }
  }, [isCollaborator, navigator, projectData.isPrivate, projectId])

  // ---
  //
  useEffect(() => {
    if (jiraData) {
      // 初期化
      // データのパースはデータが完全ではないのでまだ不完全
      const setupData: JIRA_NODE = { id: '-1', label: 'root', nodes: []}
      jiraData.data.forEach((ticket: any) => {
        // カテゴリと同じく親IDを使うっぽい
        setupData.nodes.push({
          id: ticket.id,
          label: ticket.ticketObject.fields.summary,
          nodes: []
        })
      })
      //
      setJiraDataState({...setupData})
    }
  }, [ jiraData ])

  // ---
  // ルート直下に子要素を追加
  const onCreateHandle = () => {
    setJiraDataState({
      id: jiraDataState.id,
      label: jiraDataState.label,
      nodes: [
        {
          id: Date.now().toString(),
          label: '新規追加ラベル',
          nodes: []
        },
        ...jiraDataState.nodes,
      ]      
    })
  }

  // ---
  // 削除イベント
  const onDeleteHandle = (blockPath: string[]) => {
    deleteBlockPath.current = blockPath
    setModalVisible(true)
  }

  // ---
  // 削除確認モーダルのコールバック
  const onDeleteConfirm = (bool: boolean) => {
    if (bool) {
      const newNodeData = Object.assign({}, jiraDataState)
      //
      popNode(newNodeData, deleteBlockPath.current.splice(1))
      //
      setJiraDataState(newNodeData)
    }
    deleteBlockPath.current = []
    setModalVisible(false)
  }

  //
  return (
    <>
      <IntegrationHead data={projectData} />
      {/* 枠 */}
      <MainWrapper expand primary>
        {/* */}
        {modalVisible &&
          <ModalPortal children={
            <JiraDeleteConfirm onCancel={() => onDeleteConfirm(false)} onDelete={() => onDeleteConfirm(true)} />
          } />
        }

        {/* */}
        <JiraHead onCreate={onCreateHandle} />
        <JiraNodeView data={jiraDataState} setData={setJiraDataState} onDelete={onDeleteHandle} />
      </MainWrapper>
    </>
  )
}

//
export default ProjectJira