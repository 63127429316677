import { Dispatch, FormEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useSWR, { useSWRConfig } from "swr";
import { ErrorType } from "../../../../lib/entity/ErrorType";
import { POST_IMAGE } from "../../../../lib/entity/PostImage";
import tokenState from "../../../../state/Auth0";
import { ResponseError } from "../../../../styles/styled/common/blocks/ResponseError.styled";
import { BasicButton } from "../../../../styles/styled/common/buttons/BasicButton.styled";
import PostJson from "../../../common/utls/PostJson";
import BaseSetting from "./BaseSetting";
import IntegrationSetting from "./IntegrationSetting";

//
const apiPath = process.env.REACT_APP_API_PATH!

//
interface AccountSettingFormProp {
  setLoadingState: Dispatch<React.SetStateAction<boolean>>;
}

//
const AccountSettingForm: React.FC<AccountSettingFormProp> = ({ setLoadingState }) => {
  // トークン
  const authToken = useRecoilValue(tokenState)
  // ログインユーザーの情報を取得
  const { data: account } = useSWR([ '/user/logging-in', authToken ], { suspense: true })
  //
  const { mutate } = useSWRConfig()
  //
  const navigate = useNavigate()
  // エラー表示用
  const [errorState, setErrorState] = useState<ErrorType>()

  //
  const putUser = useCallback(async (formData: FormData, postImage: { userImage: POST_IMAGE | null, coverImage: POST_IMAGE | null}) => {
    // FormDataからjsonへ
    const postJson = PostJson(formData)
    //
    const res = await fetch(`${apiPath}/user/${account.id}`, {
      method: 'PUT',
      body: JSON.stringify({ ...postJson, ...postImage}),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
    //
    if (res.status === 200) {
      const newData = await res.json()
      // 引数のキーの更新
      mutate([`/user/${account.id}`, authToken], newData)
      // authチェックもレスポンスが同じなので更新しておく
      mutate([`/user/logging-in`, authToken], newData)
      // 更新が成功したのでアカウントトップへ遷移
      navigate('/account')
    } else {
      const errorJson = await res.json()
      const message =  Array.isArray(errorJson.detail) ? errorJson.detail.join('\n') : errorJson.detail.toString()
      setErrorState({
        id: errorState ? errorState.id + 1 : 0,
        isError: true,
        message: message,
        status: res.status,
      })
    }
    setLoadingState(false)
  }, [account.id, authToken, errorState, mutate, navigate, setLoadingState])
  
  // ---
  // 更新
  //
  const onSubmitHandle = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    //
    if (event.target as HTMLFormElement) {
      setLoadingState(true)
      //
      const fd = new FormData(event.target as HTMLFormElement)
      //
      // ユーザー画像の情報取得
      let userImage: POST_IMAGE | null = null
      if (fd.get('userImage_name')?.toString()) {
        userImage = {
          file: fd.get('userImage_base64')?.toString() || '',
          name: fd.get('userImage_name')?.toString() || ''
        }
      }
      // 要らない要素は削除
      fd.delete('userImage')
      fd.delete('userImage_base64')
      fd.delete('userImage_name')

      // ---
      // カバー画像の情報取得
      let coverImage: POST_IMAGE | null = null
      if (fd.get('coverImage_name')?.toString()) {
        coverImage = {
          file: fd.get('coverImage_base64')?.toString() || '',
          name: fd.get('coverImage_name')?.toString() || ''
        }
      }
      // 要らない要素は削除
      fd.delete('coverImage')
      fd.delete('coverImage_base64')
      fd.delete('coverImage_name')
      //
      putUser(fd, { userImage, coverImage })
    }
  }

  return (
    <form onSubmit={onSubmitHandle} style={{display: 'contents'}}>

      {/* 基本設定 */}
      <BaseSetting account={account} />

      {/* Saas連携設定 */}
      <IntegrationSetting />

      {/** エラー */}
      {(errorState && errorState.isError) && 
        <ResponseError
          style={{width: '600px'}}
          key={`error_${errorState.id}`}
          title="エラーが発生しました"
          status={errorState.status?.toString()}
          message={errorState.message}
        />
      }

      {/* 送信 */}
      <BasicButton type="submit" color="primary" size="middle">Update</BasicButton>
    </form>
  )
}

//
export default AccountSettingForm