import styled from "@emotion/styled";

/**
 * テキスト用のInputエレメント
 */
export const InputText = styled.input`
  font-size: 1rem;
  padding: .6em .6em;
  border: 2px solid var(--color-200);
  outline: none;
  transition: all .15s linear;
  border-radius: 6px;
  &:focus {
    border-color: var(--primary);
  }
  &:disabled {
    border-color: var(--color-150);
    background-color: var(--color-150);
    &::placeholder {
      color: ${({theme}) => theme.alphaColor('#fff', 0)};
    }
  }
`
InputText.defaultProps = {
  type: 'text'
}