import styled from "@emotion/styled";

//
interface SectionTitleProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

/**
 * 
 */
export const SectionTitle = styled(({children, ...props}: SectionTitleProps) => (
  <div {...props}>
    <h3 className="section-title_label">{children}</h3>
  </div>
))`
  width: 100%;
  text-align: center;
  .section-title_label {
    font-size: 1.8rem;
  }
`