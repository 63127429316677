import { css } from "@emotion/react"
import { Link } from "react-router-dom"
import { Icon } from "../../../../styles/styled/common/icons/Icon.styled"
import { ProductIcon } from "../../../../styles/styled/common/icons/ProductIcon.styled"

type INTEGRATION_TYPE = {
  id: string,
  name: string,
  code: string
}
//
interface P {
  projectId?: string;
  integration?: INTEGRATION_TYPE[];
  isAdmin: boolean
}

//
const ProjectSidebar: React.FC<P> = ({ projectId, integration, isAdmin }) => {

  if (!projectId || !integration) {
    return (
      <div css={styles.container}></div>
    )
  }

  return (
    <div css={styles.container}>

      {/* */}
      <div css={styles.topBlock}>
        <Link to={`/projects/${projectId}`} css={styles.link}>
          <Icon name="home" />
        </Link>
        {integration.map((inte) => {
            return (
              <Link to={`/projects/${projectId}/${inte.code.toLocaleLowerCase()}`} css={styles.link} key={inte.id}>
                <ProductIcon name={inte.code.toLocaleLowerCase()} />
              </Link>
            )
          })
        }
      </div>

      {/* */}
      {isAdmin &&
        <div css={styles.bottomBlock}>
          {/*
            <Link to="/" css={styles.link}>
              <Icon name="visibility" />
            </Link>
          */}
          <Link to={`/projects/${projectId}/setting`} css={styles.link}>
            <Icon name="settings" />
          </Link>
        </div>
      }
    </div>
  )
}

//
export default ProjectSidebar

// --- --- ---
// style
//
const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--color-300);
  `,

  topBlock: css`
    position: sticky;
    top: 63px;
    margin-bottom: auto;
    padding-top: 10px;
  `,

  bottomBlock: css`
    position: sticky;
    bottom: 0;
    padding-bottom: 10px;
  `,

  link: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  `
}