import { css } from "@emotion/react";
import styled from "@emotion/styled";

/**
 * フォーム用ラベル
 */
export const FormLabel = styled.label`
  font-size: .94rem;
  line-height: 1em;
`

/**
 * フォーム用ラベル（欧文）
 */
export const EuroFormLabel = styled.label<{ required?: boolean}>`
  ${({theme}) => theme.euroFont('medium')}
  font-size: 1.04rem;
  line-height: 1em;
  letter-spacing: .01em;
  
  ${({required}) => required && css`
    &::after {
      content: '(require)';
      color: var(--danger);
      margin-left: 1em;
      font-size: 0.82em;
      font-weight: normal;
    }
  `}
`


/**
 * フォームパーツを１つのセクションとしてレイアウトします
 * @params width 横幅を指定できます（デフォルト：600px）
 * @params center 縦軸をセンターにします
 */
 export const FormSection = styled.div<{
  width?: string,
  center?: boolean
}>`
  display: flex;
  flex-direction: column;
  ${({center}) => center && 'align-items: center;'}
  row-gap: 1.9rem;
  border: 1px solid var(--color-300);
  width: ${({width}) => width ? width : '600px' };
  padding: 3.4rem 3rem;
  border-radius: 12px;
  background-color: var(--color-100);

  @media (max-width: 768px) {
    width: 100%;
    padding: 2rem .8rem;
  }
`

/**
 * ラベルと入力パーツを包むブロックです
 * @params vertical レイアウトを縦方向にします
 * @params card 背景色を追加したカードスタイルを適用します
 */
 export const FormBlock = styled.div<{
  vertical?: boolean
  card?: boolean
}>`
  display: flex;
  flex-direction: ${({vertical}) => vertical ? 'row' : 'column'};
  align-items: ${({vertical}) => vertical ? 'center' : 'auto'};
  row-gap: .6rem;
  width: 100%;
  ${({card}) => card && StyledFormBlock_card}
`

// FormBlockの拡張スタイル
const StyledFormBlock_card = css`
  padding: 1.2rem;
  border-radius: 6px;
  background-color: var(--color-150);
`