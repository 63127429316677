import { css } from "@emotion/react"
import styled from "@emotion/styled"

interface ParagraphProps {
  shrink?: boolean;
  maxHeight?: number;
}
export const Paragraph = styled.p<ParagraphProps>`
  font-size: 1rem;
  ${({shrink}) => shrink && css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  `}
  ${({maxHeight}) => maxHeight && css`
    max-height: ${maxHeight}px;
    overflow-y: auto;
  `}
`

/**
 * 短いテキストに使うことを想定したコンポーネントです
 */
 export const LabelText = styled.span<{
  small?: boolean
  xsmall?: boolean
  nowrap?: boolean
}>`
  // font-size: 1rem;
  font-size: ${({ small, xsmall }) => {
    if (xsmall) return '.84rem;'
    if (small) return '.92rem;'
    return '1rem;'
  }};
  line-height: 1em;
  ${({ nowrap }) => nowrap ? css`white-space: nowrap` : null}
`

/**
 * 小さい文字を表現するspanタグ
 * TODO: LabelTextへ統合予定
 * @param $nowrap 強制的に折り返さないようにします
 * @param $x より小さい文字サイズ
 * @param $xx $xよりさらに小さい文字サイズ
 */
// DOMエレメントにpropsが渡されないように$を付けている
// https://styled-components.com/docs/api#transient-props
export const SmallText = styled.span<{
  $x?: boolean
  $xx?: boolean
  $nowrap?: boolean
}>`
  font-size: ${({ $x, $xx}) => {
    if ($x) return '.84rem;'
    if ($xx) return '.76rem;'
    return '.92rem;'
  }};
  ${({$nowrap}) => {
    if ($nowrap) {
      return `
        white-space: nowrap;
      `
    }
  }}
`