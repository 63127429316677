import { BasicButton } from "../../../../styles/styled/common/buttons/BasicButton.styled"
import { Heading3 } from "../../../../styles/styled/common/typography/Heading.styled"
import { HorizontalBlock } from "../../../../styles/styled/layouts/HorizontalBlock.styled"
import { ModalConfirm } from "../../../../styles/styled/modal/ModalConfirm.styled"

//
interface P {
  onCancel: VoidFunction
  onDelete: VoidFunction
}

//
const JiraDeleteConfirm: React.FC<P> = ({ onCancel, onDelete }) => {
  return (
    <ModalConfirm>
      <Heading3>削除してよろしいですか？</Heading3>
      <HorizontalBlock align="flex-end">
        <BasicButton onClick={onCancel}>Cancel</BasicButton>
        <BasicButton onClick={onDelete} color="danger">Cancel</BasicButton>
      </HorizontalBlock>
    </ModalConfirm>
  )
}

//
export default JiraDeleteConfirm