import { css } from "@emotion/react";
import styled from "@emotion/styled";

//
interface HomeSectionProps {
  primary?: boolean;
  secondary?: boolean;
}
//
export const HomeSection = styled.div<HomeSectionProps>`
  padding: 6rem 0 7rem;

  ${({primary}) => primary && css`background-color: var(--primary-10);`}
  ${({secondary}) => secondary && css`background-color: #F1EEFC;`}

  > section {
    width: ${({theme}) => theme.content.maxWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    @media (max-width: 768px) {
      width: ${({theme}) => theme.spContent.maxWidth};
    }
    > div:last-of-type {
      text-align: center;
    }
  }
`