import styled from "@emotion/styled"
import { useState } from "react"
import { IconText } from "../../../../styles/styled/common/icons/IconText.styled"

interface LikeButtonProps {
  count: number
}
const LikeButton: React.FC<LikeButtonProps> = ({ count }) => {
  const [ isLiked, setIsLiked ] = useState(false)
  return (
    <LikeButtonBtn onClick={() => setIsLiked(!isLiked)}>
      <IconText name="favorite" size="1.2rem" color={ isLiked ? 'danger' : undefined } fill={isLiked}>
        { isLiked ? count + 1 : count}
      </IconText>
    </LikeButtonBtn>
  )
}

//
export default LikeButton

//
const LikeButtonBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 3px 6px 1px 4px;
  border-radius: 2px;
  &:hover {
    background-color: #f6f6f6;
  }
`