import { css } from "@emotion/react";

type EURO_FONT_WEIGHT = 'bold' | 'medium' | 'normal'
export const euroFont = (weight: EURO_FONT_WEIGHT = 'normal') => css`
  font-family: 'Figtree', sans-serif;
  ${weight === 'bold' ? 'font-weight: 700' : ''}
  ${weight === 'medium' ? 'font-weight: 600' : ''}
  ${weight === 'normal' ? 'font-weight: 400' : ''}
`

// https://zenn.dev/kawarimidoll/articles/c8e81071b4cd29
export const alphaColor = (color = "#000", alpha = 1) => {
  const rgb = Object.fromEntries(
    ((color.match(/^#?[0-9A-Fa-f]{3}([0-9A-Fa-f]{3})?$/) ? color : "000").replace(
      /^#?(.*)$/,
      (_, hex) => (hex.length === 3) ? hex.replace(/./g, "$&$&") : hex,
    ).match(/../g) ?? []).map((c, i) => ["rgb".charAt(i), parseInt("0x" + c)]),
  )
  //
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`
}
