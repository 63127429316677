import { css } from "@emotion/react"
import { useContext, useState } from "react"
import { useRecoilValue } from "recoil"
import tokenState from "../../../../state/Auth0"
import { EuroHeading4 } from "../../../../styles/styled/common/typography/EuroText.styled"
import { Paragraph, SmallText } from "../../../../styles/styled/common/typography/Text.styled"
import { StyledDetailSection, StyledDetailSectionContent } from "../../../styled/projects"
import ApplyCollaborationButton from "./ApplyCollaborationButton"
import CollaboratorContext from "./CollaboratorContext"

interface ProjectPrivateBlockProps {
  projectId: string;
}

const ProjectPrivateBlock: React.FC<ProjectPrivateBlockProps> = ({ projectId }) => {
  // トークン（ログイン確認に使用する）
  const authToken = useRecoilValue(tokenState)
  // 当該ユーザーがコラボレーターに含まれているかのコンテキスト
  const isCollaborator = useContext(CollaboratorContext)
  const [isApply, setIsApply] = useState(false)

  // ログイン前
  if (!authToken) {
    return (
      <StyledDetailSection>
        <StyledDetailSectionContent center css={styles.content}>
          <EuroHeading4>Please sign up.</EuroHeading4>
          <SmallText $x>このプロジェクトに参加するためにサインアップして下さい。</SmallText>
        </StyledDetailSectionContent>
      </StyledDetailSection>
    )
  }

  //
  return (
    <>
      <StyledDetailSection>
        <StyledDetailSectionContent center css={styles.content}>
          <EuroHeading4>Please apply collaboration to view project details.</EuroHeading4>
          {(!isApply && isCollaborator === 0) && 
            <>
              <SmallText $x>このプロジェクトの詳細を見るには、コラボレータ申請を行ってください。</SmallText>
              <ApplyCollaborationButton projectId={projectId} onApply={() => setIsApply(true)} />
            </>
          }
          {(isApply || isCollaborator === 1) &&
            <Paragraph>コラボレーター申請の承認待ちです。オーナーの承認をお待ち下さい。</Paragraph>
          }
          {(!isApply && isCollaborator === 99) &&
            <Paragraph>コラボレーターの申請が却下されました。</Paragraph>
          }
        </StyledDetailSectionContent>
      </StyledDetailSection>
    </>
  )
}

//
export default ProjectPrivateBlock

// --- --- ---
//
const styles = {
  content: css`
    row-gap: 2rem;
    margin: 3rem 0;
  `,
}