const domain = process.env.REACT_APP_AUTH0_DOMAIN!
//
const getUserMetadata = async (user: any, getAccessTokenSilently: any, getAccessTokenWithPopup: any) => {

  try {
    let accessToken = ''
    if (process.env.NODE_ENV === 'development') {
      // ローカル用（デバッグ用）
      accessToken = await getAccessTokenWithPopup({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      })
    } else {
      // リモート用
      accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      })
    }
    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`

    const metadataResponse = await fetch(userDetailsByIdUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    const meta = await metadataResponse.json()
    //
    return meta
  } catch (e: any) {
    console.log(e);
  }
}

export default getUserMetadata

/*
  // ---
  // Auth0の方のユーザー情報取得
  // TODO: API繋いだら必要ないかも
  useEffect(() => {
    //
    if (!user) {
      return
    }
    //
    const getUserMetadata = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN!

      try {
        let accessToken = ''
        if (process.env.NODE_ENV === 'development') {
          // ローカル用（デバッグ用）
          accessToken = await getAccessTokenWithPopup({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          })
        } else {
          // リモート用
          accessToken = await getAccessTokenSilently({
            audience: `https://${domain}/api/v2/`,
            scope: "read:current_user",
          })
        }
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })

        const meta = await metadataResponse.json()
        // console.log(meta)
        if (meta && meta.identities) {
          setIdentities(meta.identities)
        }
      } catch (e: any) {
        console.log(e);
      }
    }

    getUserMetadata()
  }, [user, getAccessTokenSilently, getAccessTokenWithPopup])
*/