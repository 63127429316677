
/**
 * 
 * @param formdata 
 * @returns 
 */
const PostJson = (formdata: FormData) => {
  const postJson = Object.fromEntries(formdata.entries());
  // 数値に変更
  const parseJson: {[key: string]: string | number | boolean | any} = {}
  Object.keys(postJson).forEach(key => {
    // 先頭「_」は処理しない
    if (key.substring(0, 1) !== '_') {
      // 数値に変更
      parseJson[key] = (isNaN(Number(postJson[key])) || postJson[key] === '') ? postJson[key] : Number(postJson[key])
      // 真偽値に変更
      if (parseJson[key] === 'true' || parseJson[key] === 'false') {
        parseJson[key] = parseJson[key] === 'true' ? true : false
      }
    }
  })

  //
  return parseJson
}

//
export default PostJson