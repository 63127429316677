import { Suspense, useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import useSWRImmutable from 'swr/immutable'
import IntegrationHead from "../../../components/elements/integration/IntegrationHead"
import SlackHead from "../../../components/elements/integration/slack/SlackHead"
import SlackMessages from "../../../components/elements/integration/slack/SlackMessages"
import CollaboratorContext from "../../../components/elements/projects/detail/CollaboratorContext"
import tokenState from "../../../state/Auth0"
import { MainWrapper } from "../../../styles/styled/layouts/MainWrapper.styled"

const ProjectSlack: React.FC = () => {
  // 当該ユーザーがコラボレーターに含まれているかのコンテキスト
  const isCollaborator = useContext(CollaboratorContext)
  const navigator = useNavigate()

  // トークン
  const authToken = useRecoilValue(tokenState)
  // プロジェクトの概要データを取得
  const { projectId } = useParams()
  const { data: projectData } = useSWRImmutable([ `/project/${projectId}`, authToken], { suspense: true })

  // 直リンク対応（ルーター側へ移設した方が良いかも）
  useEffect(() => {
    if (projectData.isPrivate && isCollaborator !== 2) {
      // コラボレーターでは無いので、プロジェクトトップへ
      navigator(`/projects/${projectId}`)
    }
  }, [isCollaborator, navigator, projectData.isPrivate, projectId])

  //
  return (
    <>
      <IntegrationHead data={projectData} />
      {/* */}
      <MainWrapper expand primary column>
        <Suspense>
          <SlackHead />
        </Suspense>
        {/* */}
        <Suspense>
          <SlackMessages />
        </Suspense>
      </MainWrapper>
    </>
  )
}

//
export default ProjectSlack