import { css } from "@emotion/react"
import { ProductIcon } from '../../../../styles/styled/common/icons/ProductIcon.styled'
import { Heading3 } from '../../../../styles/styled/common/typography/Heading.styled'
import { EuroLabelText } from '../../../../styles/styled/common/typography/EuroText.styled'

//
const SlackHead: React.FC = () => {
  //
  // const { data: { channel } } = useSWRImmutable('/mock/slack/channel.json', { suspense: true })
  
  //
  return (
    <div css={styles.container}>
      <Heading3>○○町デジタル推進PJ</Heading3>

      <button css={styles.button}>
        <ProductIcon name={'slack'} size="16px" />
        <EuroLabelText>Open Slack</EuroLabelText>
      </button>
    </div>
  )
}

//
export default SlackHead

// --- --- ---
// style
//
const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    background-color: var(--color-100);
    border-radius: 4px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      gap: 10px;
    }
  `,
  button: css`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    background-color: #611F69;
    color: var(--color-100);
    padding: .4em .8em;
    border-radius: 6px;
    /* TODO: 仮 */
    path {
      fill: var(--color-100);
    }
  `
}