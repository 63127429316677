import useSWRImmutable from 'swr/immutable'
import { CategoriesCard, CategoriesCardWrapper } from '../../../styles/styled/categories/CategoriesCard.styled'

const CategoriesList = () => {
  //
  const { data: categories } = useSWRImmutable('/category', { suspense: true })

  //
  return (
    <CategoriesCardWrapper>
      {categories.data.map((cate: any) => 
        <CategoriesCard to={`/projects?${new URLSearchParams({ categoryId: cate.id })}`} name={cate.iconName} key={cate.id}>{cate.name}</CategoriesCard>)
      }
    </CategoriesCardWrapper>
  )
}

//
export default CategoriesList
