import { DndContext, DragEndEvent } from "@dnd-kit/core"
import { Dispatch } from "react"
import JiraNodeBlock from "./JiraNodeBlock"
import { JIRA_NODE, popNode, pushNode, spliceNode } from "./NodeUtils"

// ---
interface P {
  data: JIRA_NODE
  setData: Dispatch<React.SetStateAction<JIRA_NODE>>
  onDelete: (blockPath: string[]) => void
}

// --- --- ---
//
const JiraNodeView: React.FC<P> = ({ data: nodeData, setData: setNodeData, onDelete }) => {

  // ---
  // ドロップ後のイベントハンドラ
  //
  const dragEndHandle = (event: DragEndEvent) => {
    // 念の為ドロップ先がrootの場合は何もしない
    if (event.over && event.over.id === 'root') {
      return
    }

    // DnDアイテムが持っているデータ
    const activeData = event.active?.data.current
    const overData = event.over?.data.current

    //
    if (activeData && overData && event.over) {
      // 現状のデータをコピー（処理を完了しない場合があるので）
      const newNodeData = Object.assign({}, nodeData)

      // DnDアイテムのパス
      const activePath = activeData.path.concat()
      const overPath = overData.path.concat()

      // 移動先パスが同じ場合は処理しない（元の位置に戻している）
      if (activePath.join('/') === overPath.join('/')) {
        return
      }

      // ドラッグアイテム（子を含む）をデータから抜き出す
      const activeNode = popNode(newNodeData, activePath.splice(1))

      //
      if (!activeNode) {
        return
      }

      // typeが設定されている場合は、スペーサーへのドロップ
      if (overData.type) {
        // スペーサー（子に追加するのではなく、nodesの間に入れる）
        spliceNode(newNodeData, overPath.splice(1), activeNode, overData.type)
      } else if (event.active.id !== event.over.id) {
        // ドロップ先のアイテムの子として追加する
        pushNode(newNodeData, overPath.splice(1), activeNode)
      }

      // ---
      // ノードステートの更新
      setNodeData(newNodeData)
    }
  }

  // ---
  // 追加イベントハンドラ
  //
  const onCreateHandle = (blockPath: string[]) => {
    const newNodeData = Object.assign({}, nodeData)
    //
    pushNode(newNodeData, blockPath.splice(1), {
      id: Date.now().toString(),
      label: '新規：ラベル',
      nodes: []
    })
    //
    setNodeData(newNodeData)
  }

  // ---
  //
  return (
    <DndContext onDragEnd={dragEndHandle}>
      <JiraNodeBlock node={nodeData} path={[]} onCreate={onCreateHandle} onDelete={onDelete} />
    </DndContext>
  )
}

//
export default JiraNodeView