import { css } from "@emotion/react";
import styled from "@emotion/styled";

//
interface KickoffButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  disabled: boolean;
}
//
export const KickoffButton = styled(({disabled, ...props}: KickoffButtonProps) => (
  <button type="submit" {...props}>
    <svg id="kickoff-button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185.09 25.99" className="kickoff_button-svg">
      <path d="M15.23,25.32c-.25,0-.48-.11-.63-.31L5.8,13.97h-.07v10.52c0,.46-.36,.83-.81,.83H.81c-.45,0-.81-.37-.81-.83V1.5c0-.46,.36-.83,.81-.83H4.92c.45,0,.81,.37,.81,.83V10.7h.1L14.29,.95c.15-.18,.38-.28,.61-.28h5.11c.71,0,1.08,.86,.6,1.39L11.9,11.48c-.28,.31-.29,.78-.03,1.1l9.5,11.38c.45,.54,.07,1.36-.62,1.36h-5.52Z"/>
      <path d="M30.93,24.5V1.5c0-.46,.36-.83,.81-.83h4.28c.45,0,.81,.37,.81,.83V24.5c0,.46-.36,.83-.81,.83h-4.28c-.45,0-.81-.37-.81-.83Z"/>
      <path d="M64.41,24.89c-1.58,.73-3.4,1.1-5.47,1.1-1.88,0-3.62-.31-5.21-.94-1.59-.63-2.97-1.51-4.14-2.66-1.17-1.15-2.09-2.52-2.75-4.12-.67-1.6-1-3.36-1-5.29s.34-3.76,1.01-5.36c.68-1.6,1.61-2.96,2.8-4.09,1.19-1.13,2.59-1.99,4.19-2.59,1.6-.6,3.34-.91,5.19-.91,1.72,0,3.41,.31,5.07,.92,1.36,.5,2.5,1.19,3.44,2.08,.34,.32,.36,.87,.03,1.2l-2.8,2.84c-.32,.33-.83,.31-1.16-.01-.46-.45-1-.81-1.62-1.07-.89-.37-1.81-.56-2.75-.56-1.03,0-1.98,.19-2.84,.57s-1.6,.91-2.22,1.58-1.1,1.47-1.44,2.38c-.34,.92-.52,1.92-.52,3.01s.17,2.14,.52,3.06c.34,.93,.82,1.72,1.43,2.38,.61,.66,1.34,1.18,2.18,1.55,.85,.37,1.78,.56,2.79,.56,1.17,0,2.19-.23,3.06-.7,.61-.33,1.14-.72,1.58-1.19,.31-.32,.81-.34,1.14-.03l2.87,2.74c.34,.32,.34,.87,0,1.2-.96,.96-2.09,1.74-3.4,2.34h.02Z"/>
      <path d="M92.32,25.32c-.25,0-.48-.11-.63-.31l-8.8-11.04h-.07v10.52c0,.46-.36,.83-.81,.83h-4.11c-.45,0-.81-.37-.81-.83V1.5c0-.46,.36-.83,.81-.83h4.11c.45,0,.81,.37,.81,.83V10.7h.1L91.38,.95c.15-.18,.38-.28,.61-.28h5.11c.71,0,1.08,.86,.6,1.39l-8.71,9.42c-.28,.31-.29,.78-.03,1.1l9.5,11.38c.45,.54,.07,1.36-.62,1.36h-5.52Z"/>
      <path d="M131.01,12.89c0,1.95-.33,3.73-1,5.34s-1.59,2.99-2.77,4.14c-1.18,1.15-2.58,2.04-4.21,2.66-1.63,.63-3.39,.94-5.3,.94s-3.66-.31-5.28-.94c-1.62-.63-3.01-1.51-4.19-2.66-1.18-1.15-2.1-2.53-2.77-4.14-.67-1.61-1-3.39-1-5.34s.33-3.75,1-5.34c.66-1.59,1.59-2.94,2.77-4.06,1.18-1.11,2.58-1.97,4.19-2.58,1.62-.6,3.38-.91,5.28-.91s3.67,.3,5.3,.91c1.63,.6,3.03,1.46,4.21,2.58,1.18,1.11,2.1,2.47,2.77,4.06,.66,1.59,1,3.37,1,5.34Zm-6.29,0c0-1.07-.17-2.07-.52-2.99-.34-.93-.82-1.72-1.43-2.38-.61-.66-1.34-1.18-2.2-1.57-.86-.38-1.81-.57-2.84-.57s-1.97,.19-2.82,.57c-.85,.38-1.58,.91-2.2,1.57s-1.09,1.46-1.43,2.38c-.33,.93-.5,1.93-.5,2.99s.17,2.14,.52,3.08,.82,1.75,1.43,2.42c.61,.67,1.34,1.2,2.18,1.58,.85,.38,1.79,.57,2.82,.57s1.97-.19,2.82-.57,1.58-.91,2.2-1.58,1.1-1.48,1.44-2.42,.52-1.97,.52-3.08h0Z"/>
      <path d="M145.68,6.58v3.43c0,.46,.36,.83,.81,.83h9.57c.45,0,.81,.37,.81,.83v3.26c0,.46-.36,.83-.81,.83h-9.57c-.45,0-.81,.37-.81,.83v7.92c0,.46-.36,.83-.81,.83h-4.18c-.45,0-.81-.37-.81-.83V1.5c0-.46,.36-.83,.81-.83h16.17c.45,0,.81,.37,.81,.83v3.43c0,.46-.36,.83-.81,.83h-10.36c-.45,0-.81,.37-.81,.83h-.01Z"/>
      <path d="M173.1,6.58v3.43c0,.46,.36,.83,.81,.83h9.57c.45,0,.81,.37,.81,.83v3.26c0,.46-.36,.83-.81,.83h-9.57c-.45,0-.81,.37-.81,.83v7.92c0,.46-.36,.83-.81,.83h-4.18c-.45,0-.81-.37-.81-.83V1.5c0-.46,.36-.83,.81-.83h16.17c.45,0,.81,.37,.81,.83v3.43c0,.46-.36,.83-.81,.83h-10.36c-.45,0-.81,.37-.81,.83h-.01Z"/>
    </svg>
  </button>
))`
  background-color: var(--primary);
  padding: 1.2rem 2.5rem;
  border-radius: 12px;

  .kickoff_button-svg {
    display: block;
    width: 150px;
    path {
      fill: var(--color-100);
    }
  }

  ${({disabled}) => disabled && css`
    pointer-events: none;
    background-color: var(--color-400);
  `}
`