import useSWRImmutable from 'swr/immutable'
// styled
import { StyledDetailSection, StyledDetailSectionHead } from '../../../styled/projects'
import { useParams } from 'react-router-dom'
import ProjectActivitiesContents from './ProjectActivitiesContents'
import { EuroHeading4 } from '../../../../styles/styled/common/typography/EuroText.styled'

//
const ProjectActivities: React.FC = () => {
  //
  const { projectId } = useParams()
  //
  const { data: activities } = useSWRImmutable(`/project/${projectId}/activity`, { suspense: true })

  //
  return (
    <StyledDetailSection>
      <StyledDetailSectionHead>
        <EuroHeading4>Personal Activities</EuroHeading4>
      </StyledDetailSectionHead>

      {/* */}
      <ProjectActivitiesContents activities={activities.data} />
    </StyledDetailSection>
  )
}

//
export default ProjectActivities
