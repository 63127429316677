import styled from "@emotion/styled"

interface HORIZONTAL_PROPS {
  gap?: string
  align?: 'center' | 'flex-end'
}

/**
 * 水平方向に子エレメントをレイアウトする（デフォルトはDIV）
 * @param gap - column-gapの値を指定します
 * @param align アレンジを指定します
 */
 export const HorizontalBlock = styled.div<HORIZONTAL_PROPS>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align ? align : 'flex-start'};
  column-gap: ${({ gap }) => gap ? gap : '15px'};
`