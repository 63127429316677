import styled from "@emotion/styled"

export const Heading1 = styled.h1`
font-size: 1.6rem;
letter-spacing: .02em;
word-break: break-all;
`

export const Heading2 = styled.h2`
font-size: 1.4rem;
letter-spacing: .02em;
word-break: break-all;
`

export const Heading3 = styled.h3`
font-size: 1.2rem;
letter-spacing: .02em;
word-break: break-all;
`
export const Heading4 = styled.h4`
font-size: 1rem;
word-break: break-all;
`
export const Heading5 = styled.h5`
font-size: .92rem;
word-break: break-all;
`