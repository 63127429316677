import { Suspense, useState } from "react"
import { useSearchParams } from "react-router-dom"
import AccountList from "../../components/elements/account/list/AccountList"
import Pagination from "../../components/elements/list/Pagination"
import ErrorBoundary from "../../lib/swr/ErrorBoundary"
import { EuroHeading2 } from "../../styles/styled/common/typography/EuroText.styled"
import { MainWrapper } from "../../styles/styled/layouts/MainWrapper.styled"

const CollaboratorIndex: React.FC = () => {
  // 検索キーワードなど
  const [ searchQuery ] = useSearchParams()
  
  //
  const [totalCount, setTotalCount] = useState(0)

  //
  return (
    <MainWrapper>
      <EuroHeading2>Official Collaborators</EuroHeading2>

      {/* */}
      <ErrorBoundary>
        <Suspense>
          <AccountList
            swrKey={`/user?isOfficial=true&${searchQuery}`}
            setTotalCount={setTotalCount}
          />
        </Suspense>
      </ErrorBoundary>

      {/** */}
      <Pagination query={searchQuery} count={totalCount} page={Number(searchQuery.get('page') || 1)} path="/collaborators" />
    </MainWrapper>
  )
}

//
export default CollaboratorIndex