import { css } from "@emotion/react"
import ProjectActivitiesAccount from "./ProjectActivitiesAccount"
import { StyledActivitiesType } from "../../../styled/projects"
import { Paragraph } from "../../../../styles/styled/common/typography/Text.styled"
import { EuroHeading5 } from "../../../../styles/styled/common/typography/EuroText.styled"

//
interface P {
  list: any
}

//
const ProjectActivitiesList: React.FC<P> = ({ list }) => {
  return (
    <ul css={styles.container}>
      {list.map((act: any) => {
        return (
          <li key={act.id} css={styles.item}>
            <div css={styles.cell}>
              <StyledActivitiesType>{act.label}</StyledActivitiesType>
            </div>
            <div css={styles.cell}>
              <ProjectActivitiesAccount account={{
                userId: act.userId,
                userName: act.userName,
                userImageUrl: act.userImageUrl
              }} />
            </div>
            <div css={styles.cell}>
              <EuroHeading5>{act.name}</EuroHeading5>
            </div>
            <div css={styles.cell}>
              <Paragraph>{act.comment ? act.comment : ''}</Paragraph>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

//
export default ProjectActivitiesList

// --- --- ---
// style
//
const styles = {
  container: css`
    display: grid;
    grid-template-columns: 160px 200px 240px 1fr;
    border: 1px solid var(--color-150);
    border-radius: 6px;
  `,
  item: css`
    display: contents;
    &:nth-of-type(even) > div{
      background-color: var(--primary-10);
    }
  `,
  cell: css`
    display: flex;
    align-items: center;
    padding: .7rem 1rem;
  `
}
