import { css } from "@emotion/react"
import { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from "react"
import useSWRImmutable from 'swr/immutable'
import { InputText } from "../../../styles/styled/common/form/Input.styled"

export interface USER_DATA {
  id: string,
  name: string,
}

//
interface P {
  name?: string
  onSelectUser?: (user: USER_DATA) => string | void
  placeholder?: string
  disabled?: boolean
}

interface SWR_USERS {
  count: number
  data: USER_DATA[]
}

//
const UserSelectInput: React.FC<P> = ({ name, onSelectUser, placeholder, disabled=false }) => {
  //
  const { data: userData } = useSWRImmutable<SWR_USERS>('/user?limit=200', { suspense: true })

  //
  const [inputValue, setInputValue] = useState('')
  const [userList, setUserList] = useState<USER_DATA[]>([])
  const [isFocus, setIsFocus] = useState(false)
  //
  const idPrefix = useRef(`user-select-input-${Math.floor(Math.random() * 1000)}`)
  const focusTimer = 0
  //
  const focusHandle = useCallback((event: FocusEvent<HTMLInputElement>) => {
    window.clearTimeout(focusTimer)
    if (event.type === 'focus') {
      setIsFocus(true)
    } else {
      window.setTimeout(() => setIsFocus(false), 200)
    }
  }, [])

  // TODO: コラボレーターはID指定なので、自由入力は対応が必要
  const keyDownHandle = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.nativeEvent.isComposing || event.key !== 'Enter') return
    // 入力決定
    if (onSelectUser && userList.length > 0) {
      const resSelect = onSelectUser({
        id: userList[0].id,
        name: userList[0].name,
      })
      if (typeof resSelect === 'string') {
        setInputValue(resSelect)
      } else {
        setInputValue(inputValue)
      }
    }
  }, [inputValue, onSelectUser, userList])

  //
  const onChangeHandle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    //
    setInputValue(event.target.value)
  }, [])

  //
  const onSelect = useCallback((user: USER_DATA) => {
    console.log(user)
    let resSelect
    if (onSelectUser) {
      resSelect = onSelectUser(user)
    }

    if (typeof resSelect === 'string') {
      setInputValue(resSelect)
    } else {
      setInputValue(user.name)
    }
  }, [onSelectUser])

  //
  useEffect(() => {
    //
    if (inputValue !== '' && userData && isFocus) {
      setUserList(userData.data.filter(user => {
        // 名前
        if (user.name !== inputValue && user.name.indexOf(inputValue) > -1) {
          return user
        }
        //
        return null
      }).splice(0,5))
    } else {
      setUserList([])
    }
  }, [inputValue, isFocus, userData])

  //
  return (
    <div css={styles.container}>
      <InputText
        type="text"
        name={name}
        id={idPrefix.current}
        onChange={onChangeHandle}
        onFocus={focusHandle}
        onBlur={focusHandle}
        onKeyDown={keyDownHandle}
        value={inputValue}
        style={{ width: '100%' }}
        placeholder={placeholder}
        disabled={disabled}
      />
      {/* データリスト */}
      {userList.length > 0 &&
        <div css={styles.datalist}>
          {userList.map((user) => {
            return (
              <button type="button" key={`user-${user.id}`} css={styles.dataItem} onClick={() => onSelect(user)}>
                <span css={styles.itemName}>{user.name}</span>
                <span css={styles.itemCaption}></span>
              </button>
            )
          })}
        </div>
      }
    </div>
  )
}

export default UserSelectInput

// --- --- ---
// style
//
const styles = {
  container: css`
    position: relative;
    width: 100%;
  `,
  datalist: css`
    position: absolute;
    z-index: 999;
    margin-top: .5rem;
    background-color: var(--color-100);
    border: 1px solid var(--color-300);
    display: flex;
    flex-direction: column;
    column-gap: 5px;
    border-radius: 6px;
    padding: .5rem;
  `,
  dataItem: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: .5rem;
    border-radius: 6px;
    &:hover {
      background-color: var(--color-150);
    }
  `,
  itemName: css`
    font-size: 1rem;
  `,
  itemCaption: css`
    font-size: .82rem;
  `
}