import { Suspense } from "react"
import useSWRImmutable from 'swr/immutable'
import { css } from "@emotion/react"
import dayjs from "dayjs"
import SlackUser from "./SlackUser"
import { useParams } from "react-router-dom"

//
const SlackMessages: React.FC = () => {
  //
  const { projectId } = useParams()
  const { data: messages } = useSWRImmutable(`/project/${projectId}/slack/message`, { suspense: true })
  //
  return (
    <ul css={styles.container}>
      {messages.data.map((mes: any, index: number) => {
        return (
          <li css={styles.item} key={index}>
            <a href={mes.permalink} css={styles.link}>
              <Suspense>
                <SlackUser userId={mes.slackUserId} userName={mes.slackUserName} />
              </Suspense>
              <pre css={styles.message}>{mes.messageText}</pre>
              <span css={styles.date}>{dayjs(mes.postedDatetime).format('YYYY/MM/DD HH:mm')}</span>
            </a>
          </li>
        )
      })}
    </ul>
  )
}

//
export default SlackMessages

// --- --- ---
// style
//
const styles = {
  container: css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  `,
  item: css`
  `,
  link: css`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: var(--color-100);
    border-radius: 6px;
    height: 100%;
    transition: box-shadow .3s linear;
    &:hover {
      box-shadow: 0 2px 7px var(--shadow-100);
    }
  `,
  message: css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    margin: .7em 0;
    white-space: pre-wrap;
    word-break:break-all;
  `,
  date: css`
    color: var(--color-500);
    font-size: .84rem;
    margin-top: auto;
  `
}