import { EuroFormLabel, FormBlock, FormSection } from "../../../../styles/styled/common/form/FormLayout.styled"
import { InputText } from "../../../../styles/styled/common/form/Input.styled"
import { Textarea } from "../../../../styles/styled/common/form/Textarea.styled"
import UploadHeroImage from "../../create/UploadHeroImage"

//
interface P {
  account: {
    coverImageUrl: string
    description: string
    emailAddress: string
    id: number
    name: string
    userImageUrl: string
  }
}

//
const BaseSetting: React.FC<P> = ({ account }) => {
  //
  return (
    <FormSection width="800px">

      {/* ユーザー名 */}
      <FormBlock>
        <EuroFormLabel>User Name:</EuroFormLabel>
        <InputText name="name" id="name" type="text" placeholder="Enter User Name" defaultValue={account.name} required />
      </FormBlock>

      {/* メールアドレス */}
      <FormBlock>
        <EuroFormLabel>E-mail Address:</EuroFormLabel>
        <InputText name="emailAddress" id="emailAddress" type="email" placeholder="Enter email address" defaultValue={account.emailAddress} required />
      </FormBlock>
      
      {/* アバター */}
      <FormBlock vertical card>
        <EuroFormLabel style={{ marginRight: 'auto' }}>Avatar Image:</EuroFormLabel>
        <div style={{ width: '180px', height: '180px' }}>
          <UploadHeroImage name="userImage" defaultValue={account.userImageUrl} />
        </div>
      </FormBlock>

      {/* メッセージ */}
      <FormBlock>
        <EuroFormLabel>Message:</EuroFormLabel>
        <Textarea name="description" id="description" rows={6} style={{ resize: "vertical" }} placeholder="メッセージを入力して下さい。"  defaultValue={account.description}/>
      </FormBlock>

      {/* カバー画像 */}
      <FormBlock>
        <EuroFormLabel>Cover Image:</EuroFormLabel>
        <div style={{height: '180px' }}>
          <UploadHeroImage name="coverImage" defaultValue={account.coverImageUrl} />
        </div>
      </FormBlock>
    </FormSection>
  )
}

//
export default BaseSetting