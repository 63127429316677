import { css } from "@emotion/react"
import { Dispatch, FormEvent, Suspense, useCallback, useEffect, useRef, useState } from "react"
import { useRecoilValue } from "recoil"
import { useSWRConfig } from "swr"
import useSWRImmutable from 'swr/immutable'
import { ErrorType } from "../../../../lib/entity/ErrorType"
import { POST_IMAGE } from "../../../../lib/entity/PostImage"
import { PhaseProps, ProjectProps } from "../../../../lib/entity/Project"
import tokenState from "../../../../state/Auth0"
import { ResponseError } from "../../../../styles/styled/common/blocks/ResponseError.styled"
import { BasicButton } from "../../../../styles/styled/common/buttons/BasicButton.styled"
import { EuroFormLabel, FormBlock, FormSection } from "../../../../styles/styled/common/form/FormLayout.styled"
import { InputText } from "../../../../styles/styled/common/form/Input.styled"
import { Textarea } from "../../../../styles/styled/common/form/Textarea.styled"
import { Icon } from "../../../../styles/styled/common/icons/Icon.styled"
import { EuroHeading3 } from "../../../../styles/styled/common/typography/EuroText.styled"
import { Heading4 } from "../../../../styles/styled/common/typography/Heading.styled"
import PostJson from "../../../common/utls/PostJson"
import CategoryBlock from "../../create/CategoryBlock"
import FormIntegration from "../../create/FormIntegration"
import FormTeamBuild from "../../create/FormTeamBuild"
import PhaseList from "../../create/PhaseList"
import ProjectVisibilityCheck from "../../create/ProjectVisibilityCheck"
import UploadHeroImage from "../../create/UploadHeroImage"

// ---
const apiPath = process.env.REACT_APP_API_PATH!

//
interface ProjectEditFormProps {
  editId: string;
  setLoadingState: Dispatch<React.SetStateAction<boolean>>;
  onEditComplete: VoidFunction;
}

//
const ProjectEditForm: React.FC<ProjectEditFormProps> = ({ editId, setLoadingState, onEditComplete}) => {
  // トークン
  const authToken = useRecoilValue(tokenState)
  //
  // ログインユーザーの情報を取得
  // ほかページで取得済みのハズなのでimmutableで取得
  const { data: account } = useSWRImmutable([ '/user/logging-in', authToken ], { suspense: true })
  const { data: projectData } = useSWRImmutable<ProjectProps<string>>([ `/project/${editId}`, authToken ], { suspense: true })
  const { mutate } = useSWRConfig()

  // ---
  //
  const formRef = useRef<HTMLFormElement>(null)
  // フォームバリデーションのフラグ
  const [validate, setValidate] = useState(false)
  // エラー表示用
  const [errorState, setErrorState] = useState<ErrorType>()
  // フェーズ
  const [phaseList, setPhaseList] = useState<PhaseProps<Date>[]>([])


  // ---
  // バリデーション確認用
  useEffect(() => {
    if (formRef.current) {
      const targetForm = formRef.current
      const checkValication = () => {
        if (targetForm) {
          setValidate(targetForm.checkValidity())
        }
      }
      targetForm.addEventListener('change', checkValication)
      //
      return () => {
        targetForm.removeEventListener('change', checkValication)
      }
    }
  }, [])


  // ---
  //
  const putFetch = useCallback(async (formData: FormData, addProps: any ) => {
    // FormDataからjsonへ
    const postJson = PostJson(formData)
    // ---
    const res = await fetch(`${apiPath}/project/${editId}`, {
      method: 'PUT',
      body: JSON.stringify({ ...postJson, ...addProps }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
    if (res.ok) {
      // 編集したプロジェクトの再検証を指定する
      mutate([ `/project/${editId}`, authToken ])
      // 完了イベントの通知
      onEditComplete()
    } else {
      const errorJson = await res.json()
      const message =  Array.isArray(errorJson.detail) ? errorJson.detail.join('\n') : errorJson.detail.toString()
      setErrorState({
        id: errorState ? errorState.id + 1 : 0,
        isError: true,
        message: message,
        status: res.status,
      })
    }
    setLoadingState(false)
  }, [authToken, editId, errorState, mutate, onEditComplete, setLoadingState])

  // ---
  // 送信
  const onSubmitHandle = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    
    if (formRef.current) {
      setLoadingState(true)
      //
      const fd = new FormData(formRef.current)

      // 画像の情報取得
      let postHero: POST_IMAGE | null = null
      if (fd.get('heroImage_name')?.toString()) {
        postHero = {
          file: fd.get('heroImage_base64')?.toString() || '',
          name: fd.get('heroImage_name')?.toString() || ''
        }
      }

      // ---
      // coFounderUserIds: 配列へパース
      const coFounderUserIds = fd.get('coFounderUserIds') as string
      fd.delete('coFounderUserIds')
      // collaborators: 配列へパース
      const collaborators = fd.get('collaborators') as string
      fd.delete('collaborators')

      // 要らない要素は削除
      fd.delete('heroImage')
      fd.delete('heroImage_base64')
      fd.delete('heroImage_name')
      //
      putFetch(fd, {
        heroImage: postHero,
        collaborators: collaborators ? collaborators.split(',').map(v => Number(v)) : [],
        coFounderUserIds: coFounderUserIds ? coFounderUserIds.split(',').map(v => Number(v)) : [],
        phases: phaseList,
      })
    }
  }, [phaseList, putFetch, setLoadingState])

  
  // --- --- ---
  //
  return (
    <form ref={formRef} style={{ display: 'contents' }} onSubmit={onSubmitHandle}>
      {/* 概要 */}
      <FormSection width="800px">
        <FormBlock>
          <EuroFormLabel required>Project Name</EuroFormLabel>
          <InputText name="name" id="name" type="text" placeholder="プロジェクト名を入力して下さい" defaultValue={projectData?.name} required />
        </FormBlock>
        <FormBlock>
          <EuroFormLabel>Hero Image</EuroFormLabel>
          <div style={{ height: '270px' }}>
            <UploadHeroImage name='heroImage' defaultValue={projectData?.heroImageUrl} />
          </div>
        </FormBlock>
        <FormBlock>
          <EuroFormLabel required>Description</EuroFormLabel>
          <Textarea name="description" id="description" rows={6} style={{ resize: "vertical" }} placeholder="プロジェクト概要を入力して下さい" defaultValue={projectData?.description} required />
        </FormBlock>

        {/* カテゴリ */}
        <CategoryBlock defaultSelect={projectData?.categoryId} />

        {/* */}
        <FormBlock>
          <EuroFormLabel>Project Visibility</EuroFormLabel>
          <ProjectVisibilityCheck name="isPrivate" defaultChecked={!projectData?.isPrivate} />
        </FormBlock>
      </FormSection>


      {/* チーム・ビルド */}
      <Suspense>
        <FormTeamBuild ownerName={account.name} projectId={editId} projectData={projectData} />
      </Suspense>

      {/* Integration */}
      <FormIntegration projectData={projectData} />

      {/* Start & Goal */}
      <FormSection width="800px">
        <EuroHeading3>Start &amp; Goal</EuroHeading3>
        <div css={styles.dateBlock}>
          <FormBlock>
            <EuroFormLabel>Start:</EuroFormLabel>
            <input name="projectStartDate" type="date" defaultValue={projectData?.projectStartDate} />
          </FormBlock>
          <Icon name="arrow_right_alt" />
          <FormBlock>
            <EuroFormLabel>Goal:</EuroFormLabel>
            <input name="projectCompletionDate" type="date" defaultValue={projectData?.projectCompletionDate} />
          </FormBlock>
        </div>

        <PhaseList phaseList={phaseList} setPhaseList={setPhaseList} defaultValue={projectData?.phases} />

        <FormBlock>
          <Heading4>Goal（自由記述）</Heading4>
          <Textarea name="memo" id="memo" style={{ resize: "vertical" }} cols={30} rows={10} defaultValue={projectData?.memo} />
        </FormBlock>
      </FormSection>

      {/** エラー */}
      {(errorState && errorState.isError) && 
        <ResponseError
          style={{width: '600px'}}
          key={`error_${errorState.id}`}
          title="エラーが発生しました"
          status={errorState.status?.toString()}
          message={errorState.message}
        />
      }

      {/* 送信ボタン */}
      <div>
        <BasicButton
          type="submit"
          color="primary"
          size="middle"
          disabled={!validate}
        >
          Update
        </BasicButton>
      </div>
    </form>
  )
}

//
export default ProjectEditForm

// --- --- ---
// style:
//
const styles = {
  fixedBlock: css`
    display: flex;
    > input {
      width: 100%;
    }
    > button {
      padding: 0 0 0 .5rem;
    }
  `,
  dateBlock: css`
    display: flex;
    column-gap: 2rem;
    align-items: center;

    @media (max-width: 768px) {
      column-gap: .5rem;
    }
  `,
}
