import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Icon } from "./Icon.styled";

interface IconTextProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  name: string;
  size?: string;
  color?: 'white' | 'danger' | 'primary';
  fill?: boolean;
}

//
export const IconText = styled(({ name, size, children, fill, ...props}: IconTextProps) => (
  <div {...props}>
    <Icon name={name} size={size} fill={fill} />
    <span className="icon-text_label">{children}</span>
  </div>
))`
  display: flex;
  align-items: center;
  column-gap: 5px;
  ${({color}) => color === 'white' && css`color: var(--color-100);`}
  ${({color}) => color === 'danger' && css`color: var(--danger);`}
  ${({color}) => !color && css`color: var(--primary-100);`}

  .icon-text_label {
    font-size: calc(${({size}) => size ? size : '24px'} * 0.7);
    padding-bottom: .1em;
  }
`