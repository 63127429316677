import { css } from "@emotion/react"
import dayjs from "dayjs"
import { BackImage } from "../../../../styles/styled/common/BackImage.styled"
import { LinkButton } from "../../../../styles/styled/common/buttons/BasicButton.styled"
import { TextLink } from "../../../../styles/styled/common/buttons/TextButton.styled"
import { IconText } from "../../../../styles/styled/common/icons/IconText.styled"
import { ProductIcon } from "../../../../styles/styled/common/icons/ProductIcon.styled"
import { Line } from "../../../../styles/styled/common/Line.styled"
import { Heading2 } from "../../../../styles/styled/common/typography/Heading.styled"
import { SmallText } from "../../../../styles/styled/common/typography/Text.styled"
import { HorizontalBlock } from "../../../../styles/styled/layouts/HorizontalBlock.styled"
import { DetailHero, DetailHeroCard, DetailHeroCardContents } from "../../../../styles/styled/projects/DetailHero"
import BreakLine from "../../../common/utls/BreakLIne"
import LikeButton from "../common/LikeButton"

//
interface P {
  data: any
}

//
const ProjectHero: React.FC<P> = ({ data }) => {
  //

  //
  return (
    <DetailHero>
      <BackImage>
        <img className="bg-image" src={data.heroImageUrl} alt="" />
      </BackImage>

      {/* */}
      <DetailHeroCard>
        <DetailHeroCardContents>
          <Heading2>{data.name}</Heading2>
          <BreakLine paragraph={data.description} maxHeight={260} />
          <HorizontalBlock as="ul">
            <li>
              <LinkButton size="xsmall" color="primary" to={`/projects?${new URLSearchParams({ categoryId: data.categoryId })}`}>{data.categoryName}</LinkButton>
            </li>
            <li>
              <LikeButton count={data.favoriteCount} />
              {/* <IconText name="favorite" size="1.2rem">{data.favoriteCount}</IconText> */}
            </li>
            <li>
              <IconText name="forum" size="1.2rem">{data.commentCount}</IconText>
            </li>
            {/** TODO: この部分の企画を確認 */}
            {/**
            <li>
              <ProductIcon name="discord" />
            </li>
             */}
          </HorizontalBlock>
        </DetailHeroCardContents>
      </DetailHeroCard>

      {/* */}
      <div css={styles.status}>
        <SmallText $x>last update {dayjs(data.modifiedDatetime).format('YYYY/MM/DD')}</SmallText>

        {/**  */}
        <ul css={styles.statusList}>
          <li><SmallText>Owner</SmallText></li>
          <li><SmallText>: </SmallText></li>
          <li>
            <TextLink
              to={`/collaborators/${data.ownerUserId}`}
              color="primary"
              bold={data.ownerUserId !== 0}
              data-disabled={data.ownerUserId === 0}
            >
              {data.ownerUserId === 0 ?
                '削除ユーザー'
                :
                `@${data.ownerUserName}`
              }
            </TextLink>
          </li>
          <li><SmallText>Contact</SmallText></li>
          <li><SmallText>: </SmallText></li>
          <li>
            {data.contactUserName &&
              <TextLink to={`/collaborators/${data.contactUserId}`} color="primary" bold>@{data.contactUserName}</TextLink>
            }
          </li>
          <li><SmallText>PR</SmallText></li>
          <li><SmallText>: </SmallText></li>
          <li>
            {data.prUserName &&
              <TextLink to={`/collaborators/${data.prUserId}`} color="primary" bold>@{data.prUserName}</TextLink>
            }
          </li>
        </ul>

        <HorizontalBlock as="ul" style={{ marginBottom: '1rem'}}>
          <li>
            <IconText name="hub" size="1.2rem">{data.collaborationCount}</IconText>
          </li>
          <li>
            <IconText name="person_search" size="1.2rem">{data.applicantCount}</IconText>
          </li>
        </HorizontalBlock>

        <Line style={{ marginTop: 'auto', marginBottom: '1rem' }} />

        {data.integrateSaasList &&
          <HorizontalBlock as="ul">
            {data.integrateSaasList.map((saas: any, index: number) => 
              <li key={index}>
                <ProductIcon name={saas.name.toLowerCase()} key={saas.name} />
              </li>
            )}
          </HorizontalBlock>
        }
      </div>
    </DetailHero>
  )
}

//
export default ProjectHero

// --- --- ---
// style
//
const styles = {

  // ---
  status: css`
    display: flex;
    flex-direction: column;
    background-color: var(--primary-10);
    padding: 30px;
    min-width: 280px;

    @media (max-width: 768px) {
      padding: 10px;
      width: 100%;
    }
  `,
  statusList: css`
    display: grid;
    grid-template-columns: 70px .7rem 120px;
    margin: 20px 0;
    row-gap: 1rem;
  `,
}