import { Route, Routes } from 'react-router-dom';
import BaseLayout from './components/layouts/BaseLayout';
import AccountIndex from './pages/account/AccountIndex';
import HomeIndex from './pages/HomeIndex';
import ProjectsIndex from './pages/projects/ProjectsIndex';
import ProjectDetail from './pages/projects/detail/ProjectDetail';
import ProjectSlack from './pages/projects/detail/ProjectSlack';
import ProjectDetailWrapper from './components/elements/projects/detail/ProjectDetailWrapper';
import ProjectJira from './pages/projects/detail/ProjectJira';
import CreateIndex from './pages/create/CreateIndex';
import NotFound from './pages/NotFound';
import SettingIndex from './pages/account/SettingIndex';
import RouteGuard from './lib/router/RouteGuard';
import CollaboratorIndex from './pages/collaborators/CollaboratorIndex';
import CollaboratorDetail from './pages/collaborators/CollaboratorDetail';
import ProjectSetting from './pages/projects/detail/ProjectSetting';

function App() {
  //
  //
  return (
    <Routes>
      {/* HOME */}
      <Route path="/" element={<BaseLayout />}>
        <Route index element={<HomeIndex />} />
        {/* 404 */}
        <Route path="*" element={<NotFound />}></Route>

        {/* コラボレーター */}
        <Route path="/collaborators">
          <Route index element={<CollaboratorIndex />} />
          <Route path=":userId" element={<CollaboratorDetail />}/>
        </Route>

        {/* プロジェクト */}
        <Route path="projects">
          <Route index element={<ProjectsIndex />} />
          <Route path=":projectId" element={<ProjectDetailWrapper />}>
            <Route index element={<ProjectDetail />} />
            <Route path="slack" element={<RouteGuard><ProjectSlack /></RouteGuard>} />
            <Route path="jira" element={<RouteGuard><ProjectJira /></RouteGuard>} />
            <Route path="setting" element={<RouteGuard><ProjectSetting /></RouteGuard>} />
          </Route>
        </Route>

        {/* 新規作成 */}
        <Route path="create">
          <Route index element={<RouteGuard><CreateIndex /></RouteGuard>} />
        </Route>

        {/* アカウント */}
        <Route path="account">
          <Route index element={<RouteGuard><AccountIndex /></RouteGuard>} />
          <Route path="setting" element={<RouteGuard><SettingIndex /></RouteGuard>} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
