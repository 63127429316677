import { useAuth0 } from "@auth0/auth0-react"
import { ReactNode, FC } from "react"
import { Navigate } from "react-router-dom"

//
interface P {
  children: ReactNode
}

//
const RouteGuard: FC<P> = ({ children }) => {
  //
  const { isAuthenticated, isLoading } = useAuth0()

  //
  if (isLoading) {
    return <></>
  }

  //
  if (!isAuthenticated) {
    // とりあえずトップへ
    return <Navigate to="/" replace={true} />
  }

  //
  return (
    <>{children}</>
  )
}

//
export default RouteGuard