import styled from "@emotion/styled"
import { Fragment, useCallback, useState } from "react"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { CollaboratorProps } from "../../../../lib/entity/Collaborators"
import tokenState from "../../../../state/Auth0"
import { BasicButton } from "../../../../styles/styled/common/buttons/BasicButton.styled"
import { LoadingWrapper } from "../../../../styles/styled/common/LoadingPanel"
import { Heading4 } from "../../../../styles/styled/common/typography/Heading.styled"

//
const apiPath = process.env.REACT_APP_API_PATH!

//
interface ProjectCollaboratorAppliesProps {
  collaborators: CollaboratorProps[]
}


const ProjectCollaboratorApplies: React.FC<ProjectCollaboratorAppliesProps> = ({ collaborators }) => {
  // トークン
  const authToken = useRecoilValue(tokenState)

  // applyしたリスト、ステータスを保持する
  const [applyList, setApplyList] = useState<{ [key: string]: number }>({})
  //
  const [isLoading, setIsLoading] = useState(-1)
  // フィルター
  const filterCollabo = collaborators.filter(col => col.status === 1)

  //
  const onApply = useCallback(async (isApply: boolean, collaboratorId: number) => {
    //
    setIsLoading(collaboratorId)
    //
    const status = isApply ? 2 : 99
    //
    await fetch(`${apiPath}/collaborator/${collaboratorId.toString()}/approve`, {
      method: 'PUT',
      body: JSON.stringify({
        status: status,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
    //
    setApplyList({
      ...applyList,
      ...{ [collaboratorId.toString()]: status }
    })
    //
    setIsLoading(-1)
  }, [applyList, authToken])

  if (filterCollabo.length === 0) {
    return <></>
  }

  //
  return (
    <CollaboratorApplies>
      <Heading4>コラボレーター申請中：</Heading4>
      <CollaboratorAppliesList>
        {filterCollabo.map(col => {
          return (
            <CollaboratorAppliesItem key={col.id}>
              <Link to={`/collaborators/${col.userId}`}>
                <Heading4>@{ col.userName }</Heading4>
              </Link>
              {applyList[col.id] &&
                <span>{applyList[col.id] === 2 ? '承認済み' : 'リレジェクト済み'}</span>
              }
              {!applyList[col.id] &&
                <CollaboratorAppliesButtons>
                  <BasicButton onClick={() => onApply(false, col.id)} type="button" size="xsmall" color="danger" border>Reject</BasicButton>
                  <BasicButton onClick={() => onApply(true, col.id)} type="button" size="small" color="primary">Approve</BasicButton>
                </CollaboratorAppliesButtons>
              }
              {/* */}
              {isLoading === col.id &&
                <LoadingWrapper small={true} />
              }
            </CollaboratorAppliesItem>
          )
        })}
      </CollaboratorAppliesList>
    </CollaboratorApplies>
  )
}

//
export default ProjectCollaboratorApplies

// ---
const CollaboratorApplies = styled.div`
  width: 100%;
`
const CollaboratorAppliesList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 1rem;
`

const CollaboratorAppliesItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.6rem 0.8rem;
  border: 1px solid var(--color-300);
  border-radius: 6px;
  transition: all 0.2s linear;
  :has(a:hover) {
    background-color: var(--color-150);
  }
`
const CollaboratorAppliesButtons = styled.div`
  display: flex;
  column-gap: 0.5rem;
`