import { css } from "@emotion/react"
import { Suspense, useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Pagination from "../../components/elements/list/Pagination"
import ProjectList from "../../components/elements/projects/list/ProjectList"
import ProjectListCategoryLabel from "../../components/elements/projects/list/ProjectListCategoryLabel"
import { Heading4 } from "../../styles/styled/common/typography/Heading.styled"
import { MainWrapper } from "../../styles/styled/layouts/MainWrapper.styled"

const ProjectsIndex = () => {
  // 検索キーワードなど
  const [ searchQuery ] = useSearchParams()
  //
  const [totalCount, setTotalCount] = useState(0)

  //
  const projectLoaded = useCallback(({ count }: { count: number}) => {
    setTotalCount(count)
  }, [])

  //
  return (
    <MainWrapper>
      <div css={styles.projectsHead}>
        <Heading4>Search: </Heading4>
        <Suspense>
          <ProjectListCategoryLabel categoryId={searchQuery.get('categoryId') || null} />
        </Suspense>
        {searchQuery.get('keyword') ?
          <span>{searchQuery.get('keyword')}</span>
          :
          <span></span>
        }

        <Heading4 style={{ marginLeft: 'auto' }}>{totalCount} projects</Heading4>
      </div>

      <Suspense>
        <ProjectList swrKey={"/project?" + searchQuery} onLoaded={projectLoaded} />
      </Suspense>

      {/* ページネーション */}
      <Pagination query={searchQuery} page={Number(searchQuery.get('page') || 1)} count={totalCount} path="/projects" />
    </MainWrapper>
  )
}

//
export default ProjectsIndex

// --- --- ---
//
const styles = {
  projectsHead: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: .4rem;
    margin-bottom: 2rem;
  `,
}