import { css } from '@emotion/react'

//
const radius = '12px'

//
const styles = {
  container: css`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-100);
    border-radius: ${radius};
    filter: drop-shadow(0 4px 3px var(--shadow-100));
    transition: all .25s ease-in-out .03s;
    ._project-card-hero > figure > img {
      transform: scale(1.07);
      transition: all .23s linear .03s;
    }
    &:hover {
      filter: drop-shadow(0 4px 8px var(--shadow-900));
      transform: translateY(-3px);
      ._project-card-hero > figure > img {
        transform: scale(1);
      }
    }
  `,

  // ---
  category: css`
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    background-color: var(--primary);
    border-top-right-radius: ${radius};
    border-bottom-left-radius: 10px;
    > span {
      display: block;
      font-size: .74rem;
      line-height: 1em;
      color: var(--color-100);
      padding: .4em .8em;
    }
  `,

  // ---
  noticeCount: css`
    position: absolute;
    z-index: 4;
    top: -11px;
    left: 10px;
    display: flex;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    background-color: var(--danger);
    border-radius: 50%;
    > span {
      font-size: .82rem;
      color: var(--color-100);
    }
  `,

  // ---
  image: css`
    width: 100%;
    height: 160px;
    > figure {
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: ${radius} ${radius} 0 0;
      background-color: var(--color-150);
      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        &[data-error="true"] {
          display: none;
        }
      }

      &:has(img[data-error="true"]) {
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
          content: "broken image";
        }
      }
    }
  `,

  // ---
  lock: css`
    position: absolute;
    z-index: 5;
    bottom: -8px;
    left: 10px;
    background-color: var(--primary);
    color: var(--color-100);
    padding: .3em .3em;
    border-radius: 6px;
  `,
  
  // ---
  contents: css`
    padding: 15px 10px 10px 10px;
    margin-bottom: auto;
    > h4 {
      color: var(--primary);
    }
    > p {
      font-size: .9rem;
      margin:  10px 0;
    }
  `,

  // ---
  stats: css`
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 10px;
  `,

  // ---
  bottom: css`
    padding: 0 10px 10px 10px;
    > span {
      font-size: .74rem;
      color: var(--color-900);
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 10px;
      border-top: 1px solid var(--color-300);
      padding-top: 15px;
      margin-top: 10px;
    }
  `
}

//
export default styles