import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Icon } from "../../../../styles/styled/common/icons/Icon.styled"
import { ProductIcon } from "../../../../styles/styled/common/icons/ProductIcon.styled"
import { EuroLabelText } from "../../../../styles/styled/common/typography/EuroText.styled"

//
interface P {
  onCreate: () => void
  onDelete: () => void
}

//
const ToolsMenu: React.FC<P>  = ({ onCreate, onDelete}) => {
  return (
    <div css={styles.container}>
      <StyledToolsMenuButton>
        <Icon name="edit_square" size="18px" />
        <EuroLabelText small nowrap>Edit label</EuroLabelText>
      </StyledToolsMenuButton>

      <StyledToolsMenuButton onClick={onCreate}>
        <Icon name="add_circle" size="18px" />
        <EuroLabelText small nowrap>Create a child</EuroLabelText>
      </StyledToolsMenuButton>

      <StyledToolsMenuButton onClick={onDelete}>
        <Icon name="delete" size="18px" />
        <EuroLabelText small nowrap>Delete node</EuroLabelText>
      </StyledToolsMenuButton>

      <hr css={styles.line} />

      <StyledToolsMenuButton>
        <ProductIcon name="slack" size="14px" />
        <EuroLabelText small nowrap>View on Slack</EuroLabelText>
      </StyledToolsMenuButton>
    </div>
  )
}

//
export default ToolsMenu

// --- --- ---
// style
//
const styles = {
  container: css`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 21;
    display: flex;
    flex-direction: column;
    background-color: var(--color-100);
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid var(--primary-100);
    box-shadow: 0px 4px 8px var(--shadow-100);
  `,
  line: css`
    width: 100%;
    border-top: 1px solid var(--primary-100);
  `
}

// styled
const StyledToolsMenuButton = styled.button`
  display: flex;
  column-gap: .4em;
  align-items: center;
  justify-content: flex-start;
  padding: .5rem .8rem;
  background-color: var(--color-100);
  transition: background-color .15s linear;
  &:hover {
    background-color: var(--primary-20);
  }
`