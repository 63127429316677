import styled from "@emotion/styled";

/**
 * 
 */
export const Textarea = styled.textarea`
  font-size: 1rem;
  padding: .6em .6em;
  border: 2px solid var(--color-200);
  outline: none;
  transition: border-color .15s linear;
  border-radius: 6px;
  &:focus {
    border-color: var(--primary);
  }
`