import { css } from "@emotion/react"

const style = css`

  html, body {
    padding: 0;
    margin: 0;
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    font-weight: 400;
    font-size: 16px;
    background-color: var(--color-100);
    color: var(--color-900);
  }

  *, *::before, *::after {
    position: relative;
    box-sizing: border-box;
    margin: 0;
  }

  ul, li {
    list-style: none;
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    color: var(--color-900);
  }

  #modal-portal {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
`
//
export default style