import styled from "@emotion/styled";
import { LoadingCircle } from "../common/LoadingPanel";


export const LoadingFallback = styled(({ ...props }) => (
    <div {...props}>
      <LoadingCircle color="primary" style={{ width: '80px', height: '80px'}} />
    </div>
))`
  display: grid;
  place-items: center;
  width: 100%;
  min-height: 30vh;
`


//
export const PageLoadingFallback = styled(LoadingFallback)`
  min-height: 80vh;
`