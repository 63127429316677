import styled from "@emotion/styled";

//
interface HeaderCompProps extends React.HtmlHTMLAttributes<HTMLElement> {}

/**
 * 
 */
export const HeaderComp = styled(({ children, ...props}: HeaderCompProps) => (
  <header {...props}>
    {children}
  </header>
))`
  position: sticky;
  z-index: 100;
  top: 0;
  width: 100%;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  width: 100%;
  padding: .7rem 1rem;

  @media (max-width: 768px) {
    padding: .7rem .5rem;
  }

  > .header-middle {
    width: 100%;
    max-width: 460px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`
