import { css } from "@emotion/react"
import { IconText } from "../../../styles/styled/common/icons/IconText.styled"
import { Heading2 } from "../../../styles/styled/common/typography/Heading.styled"
import { HorizontalBlock } from "../../../styles/styled/layouts/HorizontalBlock.styled"

//
interface P {
  data: any
}

//
const IntegrationHead: React.FC<P> = ({ data }) => {
  //
  return (
    <div css={styles.container}>

      <Heading2>{data.name}</Heading2>

      <HorizontalBlock as="ul" gap=".6rem">
        <li><IconText name="favorite" size="1.2rem">{data.favoriteCount}</IconText></li>
        <li><IconText name="forum" size="1.2rem">{data.commentCount}</IconText></li>
      </HorizontalBlock>

      <HorizontalBlock as="ul" gap=".6rem" style={{ marginLeft: 'auto' }}>
        <li>
          <IconText name="hub" size="1.2rem">{data.collaboratorCount}</IconText>
        </li>
        <li>
          <IconText name="person_search" size="1.2rem">{data.applicantCount}</IconText>
        </li>
      </HorizontalBlock>

    </div>
  )
}

//
export default IntegrationHead

// --- --- ---
// style
//
const styles = {
  container: css`
    display: flex;
    align-items: center;
    column-gap: 20px;
    background-color: var(--color-100);
    padding: .8rem 1.2rem;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
  `,
}