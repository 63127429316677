import styled from "@emotion/styled";

export const ModalPortalWrapper = styled.div`
  pointer-events: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.alphaColor('#000', 0.25)};
  backdrop-filter: blur(2px);
`