import { css } from "@emotion/react"
import dayjs from "dayjs"
import { PhaseProps } from "../../../lib/entity/Project"
import { CancelButton } from "../../../styles/styled/common/buttons/CancelButton.styled"
import { Icon } from "../../../styles/styled/common/icons/Icon.styled"
import { EuroLabelText } from "../../../styles/styled/common/typography/EuroText.styled"
import { LabelText } from "../../../styles/styled/common/typography/Text.styled"


//
interface P {
  data: PhaseProps<Date>
}

//
const PhaseItem: React.FC<P> = ({ data }) => {
  return (
    <li css={styles.container}>
      <LabelText style={{ width: '100%' }}>{data.name}</LabelText>
      <EuroLabelText small>{dayjs(data.startDate).format('YYYY/MM/DD')}</EuroLabelText>
      <Icon name="arrow_right_alt" size="1.2rem" />
      <EuroLabelText small>{dayjs(data.completionDate).format('YYYY/MM/DD')}</EuroLabelText>
      <CancelButton size="1.2rem" />
    </li>
  )
}

//
export default PhaseItem

// --- --- ---
// style
//
const styles = {
  container: css`
    display: flex;
    align-items: center;
    padding: .8rem 1rem;
    column-gap: .3rem;
    &:nth-of-type(odd) {
      background-color: var(--color-100);
    }
  `,
}