import { css } from "@emotion/react"
import { Suspense, useEffect, useState } from "react"
import useSWRImmutable from 'swr/immutable'
import { CollaboratorResponse } from "../../../lib/entity/Collaborators"
import { ProjectProps } from "../../../lib/entity/Project"
import ErrorBoundary from "../../../lib/swr/ErrorBoundary"
import { CancelButton } from "../../../styles/styled/common/buttons/CancelButton.styled"
import { EuroFormLabel, FormBlock, FormSection } from "../../../styles/styled/common/form/FormLayout.styled"
import { InputText } from "../../../styles/styled/common/form/Input.styled"
import { EuroHeading3 } from "../../../styles/styled/common/typography/EuroText.styled"
import UserSelectInput, { USER_DATA } from "../../common/forms/UserSelectInput"
import UserMultiSelect from "../../common/forms/UserMultiSelect"
import ProjectCollaboratorApplies from "../projects/setting/ProjectCollaboratorApplies"

interface FormTeamBuildProps {
  ownerName: string;
  projectData?: ProjectProps<string>;
  projectId?: string;
}

/**
 * チームビルドのフォーム部分
 * @param param0 
 * @returns 
 */
const FormTeamBuild: React.FC<FormTeamBuildProps> = ({ ownerName, projectData, projectId }) => {
  // コラボレーター
  const { data: collaborators} = useSWRImmutable<CollaboratorResponse>(projectId ? `/collaborator?${new URLSearchParams({ projectId: projectId })}` : null, { suspense: true })

  // コンタクトとPRのコラボレーター
  const [contactUser, setContactUser] = useState<USER_DATA | undefined>(projectData ? { id: projectData.contactUserId.toString(), name: projectData.contactUserName } : undefined)
  const [prUser, setPrUser] = useState<USER_DATA | undefined>(projectData ? { id: projectData.prUserId.toString(), name: projectData.prUserName } : undefined)

  // 登録済みコラボレーターとファウンダー
  const [defaultCollaborators, setDefaultCollaborators] = useState<USER_DATA[]>([])
  const [defaultCoFounder, setDefaultCoFounder] = useState<USER_DATA[]>([])

  // コラボのデフォルト
  useEffect(() => {
    // collaborators
    if (collaborators) {
      // collaborators(type:99)
      const defaultCol: USER_DATA[] = []
      collaborators.data.filter(col => col.type === 99).forEach(col => {
        if (col.status === 2) {
          defaultCol.push({
            id: col.userId.toString(),
            name: col.userName,
          })
        }
      })
      setDefaultCollaborators(defaultCol)
      //
      // collaborators(type:1)
      const coFounder: USER_DATA[] = []
      collaborators.data.filter(col => col.type === 1).forEach(col => {
        coFounder.push({
          id: col.userId.toString(),
          name: col.userName,
        })
      })
      setDefaultCoFounder(coFounder)
    }
  }, [collaborators])

  //
  return (
    <FormSection width="800px">
      <EuroHeading3>Team Building</EuroHeading3>
      <FormBlock>
        <EuroFormLabel>Owner:</EuroFormLabel>
        <InputText disabled value={ownerName} />
      </FormBlock>

      <FormBlock>
        <EuroFormLabel>Contact:</EuroFormLabel>
        {(contactUser && contactUser.id && contactUser.id !== '0') ?
          <div css={styles.fixedBlock}>
            <InputText name="contact-name" disabled value={contactUser.name} />
            <CancelButton onClick={() => setContactUser(undefined)} />
            <input type="hidden" name="contactUserId" value={contactUser.id} />
          </div>
          :
          <ErrorBoundary>
            <Suspense>
              <UserSelectInput name="contact" onSelectUser={setContactUser} placeholder="問い合わせ担当を指名する" />
            </Suspense>
          </ErrorBoundary>
        }
      </FormBlock>

      <FormBlock>
        <EuroFormLabel>PR:</EuroFormLabel>
        {(prUser && prUser.id && prUser.id !== '0') ?
          <div css={styles.fixedBlock}>
            <InputText name="public-relations-name" disabled value={prUser.name} />
            <CancelButton onClick={() => setPrUser(undefined)} />
            <input type="hidden" name="prUserId" value={prUser.id} />
          </div>
          :
          <ErrorBoundary>
            <Suspense>
              <UserSelectInput onSelectUser={setPrUser} placeholder="PR担当を指名する" />
            </Suspense>
          </ErrorBoundary>
        }
      </FormBlock>

      <FormBlock>
        <EuroFormLabel>Co-Founder:</EuroFormLabel>
        <Suspense>
          <UserMultiSelect placeholder="コアメンバーを指名して下さい" name="coFounderUserIds" defaulValue={defaultCoFounder} />
        </Suspense>
      </FormBlock>

      <FormBlock>
        <EuroFormLabel>Collaborator:</EuroFormLabel>
        <Suspense>
          <UserMultiSelect placeholder="初期メンバーを指名して下さい" max={20} name="collaborators" defaulValue={defaultCollaborators} />
        </Suspense>
      </FormBlock>

      {/* 承認待ち */}
      <ProjectCollaboratorApplies collaborators={collaborators?.data || []} />
    </FormSection>
  )
}

//
export default FormTeamBuild

//
const styles = {
  fixedBlock: css`
    display: flex;
    > input {
      width: 100%;
    }
    > button {
      padding: 0 0 0 .5rem;
    }
  `,
}