import { css, keyframes } from "@emotion/react"
import styled from "@emotion/styled"

// ---
// 円形ローディング
const LoadingCircleRoation = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const LoadingCircleDash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
`

interface LoadingCircleProps extends React.HtmlHTMLAttributes<SVGElement> {
  color?: 'primary' 
}
export const LoadingCircle = styled(({ color, ...props }: LoadingCircleProps) => (
  <svg viewBox="25 25 50 50" {...props}>
    <circle className="loading-circle_path" cx="50" cy="50" r="20" fill="none" stroke="#fff" strokeWidth="5" />
  </svg>
))`
  animation: ${LoadingCircleRoation} 1.6s linear infinite;

  .loading-circle_path {
    stroke-dasharray: 150,200;
    stroke-dashoffset: -10;
    animation: ${LoadingCircleDash} 1.3s ease-in-out infinite, color 6s ease-in-out infinite;
  }

  ${({color}) => color === 'primary' && css`circle { stroke: var(--primary); }`}
`

const LoadingCircleWrapper = styled.div<{ $small?: boolean }>`
  width: 36px;
  height: 36px;
  ${({$small}) => $small && css`
    width: 22px;
    height: 22px;
  `}
`


// ローディング
interface LoadinWrapperProps extends React.HtmlHTMLAttributes<HTMLDivElement>{
  small?: boolean;
}
export const LoadingWrapper = styled(({small, ...props}: LoadinWrapperProps) => (
  <div {...props}>
    <LoadingCircleWrapper $small={small}>
      <LoadingCircle />
    </LoadingCircleWrapper>
  </div>
))`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--shadow-900);
  // backdrop-filter: blur(4px);
`