import { Suspense } from "react"
import AccountList from "../components/elements/account/list/AccountList"
import CategoriesList from "../components/elements/categories/CategoriesList"
import Cover from "../components/elements/cover/Cover"
import ProjectList from "../components/elements/projects/list/ProjectList"
import ErrorBoundary from "../lib/swr/ErrorBoundary"
import { TextLink } from "../styles/styled/common/buttons/TextButton.styled"
import { SectionTitle } from "../styles/styled/common/Titles.styled"
import { MainWrapper } from "../styles/styled/layouts/MainWrapper.styled"
import { HomeSection } from "../styles/styled/pages/Home.styled"
import { LoadingFallback } from "../styles/styled/suspense/LoadingFallback"

const HomeIndex: React.FC = () => {
  return (
    <>
      <Cover />
      <MainWrapper expand style={{ padding: '0' }}>
        {/* プロジェクト */}
        <HomeSection primary>
          <section>
            <SectionTitle>New Arrival</SectionTitle>
            <ErrorBoundary>
              <Suspense fallback={<LoadingFallback />}>
                <ProjectList swiper={true} swrKey={`/project?${new URLSearchParams({ limit: '4' }).toString()}`} />
              </Suspense>
            </ErrorBoundary>
            <div>
              <TextLink to={"/projects"} color="primary">View More</TextLink>
            </div>
          </section>
        </HomeSection>
        <HomeSection secondary>
          <section>
            <SectionTitle>Collaborators hiring now!</SectionTitle>
            <ErrorBoundary>
              <Suspense fallback={<LoadingFallback />}>
                <ProjectList swiper={true} swrKey={`/project?${new URLSearchParams({ limit: '4', page: '2' }).toString()}`} />
              </Suspense>
            </ErrorBoundary>
            <div>
              <TextLink to={"/projects"} color="primary">View More</TextLink>
            </div>
          </section>
        </HomeSection>
        <HomeSection primary>
          <section>
            <SectionTitle>Education Projects</SectionTitle>
            <ErrorBoundary>
              <Suspense fallback={<LoadingFallback />}>
                <ProjectList swiper={true} swrKey={`/project?${new URLSearchParams({ limit: '4', categoryId: '1' }).toString()}`} />
              </Suspense>
            </ErrorBoundary>
            <div>
              <TextLink to={"/projects"} color="primary">View More</TextLink>
            </div>
          </section>
        </HomeSection>

        {/*　公式コラボレーター */}
        <HomeSection>
          <section>
            <SectionTitle>Official Collaborators</SectionTitle>
            <ErrorBoundary>
              <Suspense fallback={<LoadingFallback />}>
                <AccountList swrKey={`/user?${new URLSearchParams({ limit: '12', isOfficial: 'true' }).toString()}`} />
              </Suspense>
            </ErrorBoundary>
            <div>
              <TextLink to={"/collaborators"} color="primary">View More</TextLink>
            </div>
          </section>
        </HomeSection>

        {/* カテゴリー */}
        <HomeSection primary>
          <section>
            <SectionTitle>Popular Project Categories</SectionTitle>
            <ErrorBoundary>
              <Suspense fallback={<LoadingFallback />}>
                <CategoriesList />
              </Suspense>
            </ErrorBoundary>
          </section>
        </HomeSection>
      </MainWrapper>
    </>
  )
}

//
export default HomeIndex
