import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Icon } from "../../../styles/styled/common/icons/Icon.styled";

//
interface PaginationProps {
  page: number;
  count: number;
  query: URLSearchParams;
  path: string
}

//
const Pagination: React.FC<PaginationProps> = ({ page, count, query, path }) => {
  //
  const totalPages = Math.ceil(count / Number(query.get('limit') || 20))
  const [pageNums, setPageNums] = useState<number[]>()
  const queryObject = Object.fromEntries(query)
  //
  useEffect(() => {
    let step = 1
    const nums = [page]
    // 表示するページ番号数
    const maxNums = 5
    while(step < 5) {
      if (page - step > 0) {
        nums.unshift(page - step)
      }
      // 
      if (nums.length > maxNums) break
      //
      if (page + step < count && page + step <= totalPages) {
        nums.push(page + step)
      }
      //
      if (nums.length > maxNums) break
      //
      step++
    }
    //
    setPageNums(nums)
  }, [count, page, totalPages])

  //
  if (!pageNums || !totalPages || totalPages === 1) {
    return <></>
  }
  //
  return (
    <PaginationWrapper>
      <li>
        <PaginationLink
          to={`${path}?${new URLSearchParams({ ...queryObject, ...{ page: (page - 1).toString() } })}`}
          disabled={page === 1}
        >
          <Icon name="chevron_left" />
        </PaginationLink>
      </li>

      {pageNums.map((v) => {
        return (
          <li key={`page_${v}`}>
            <PaginationLink to={`${path}?${new URLSearchParams({ ...queryObject, ...{ page: v.toString() } })}`} active={page === v}>
              <span className="pagination_link-label">{v}</span>
            </PaginationLink>
          </li>
        )
      })}

      <li>
        <PaginationLink
          to={`${path}?${new URLSearchParams({ ...queryObject, ...{ page: (page + 1).toString() } })}`}
          disabled={page === totalPages + 1}
        >
          <Icon name="chevron_right" />
        </PaginationLink>
      </li>
    </PaginationWrapper>
  )
}

export default Pagination

// --- --- ---
//
const PaginationWrapper = styled.ul`
  display: flex;
  align-items: center;
  margin: 4rem auto;
  column-gap: 0.8em;
`

// ---
interface PaginationLinkProps extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  active?: boolean;
  disabled?: boolean;
}
const PaginationLink = styled(({ active, disabled, ...props }: PaginationLinkProps) => (
  <Link {...props}/>
))`
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${({active}) => active && css`
    pointer-events: none;
    background-color: var(--primary-100);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    .pagination_link-label {
      color: var(--color-100);
    }
  `}

  ${({disabled}) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}

  .pagination_link-label {
    display: inline-block;
    ${({theme}) => theme.euroFont('normal')};
    font-size: 0.92rem;
  }
`