import { useAuth0 } from "@auth0/auth0-react"
import styled from "@emotion/styled"
import { LinkButton, BasicButton } from "../../../styles/styled/common/buttons/BasicButton.styled"
import { EuroHeading2 } from "../../../styles/styled/common/typography/EuroText.styled"

const Cover: React.FC = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  // サインアップ
  const signupClickHandle = () => {
    loginWithRedirect({
      screen_hint: 'signup'
    })
  }

  return (
    <StyledCover>
      <StyledCoverBackground>
        <img src="/images/dummy/spacex-uj3hvdfQujI-unsplash.jpg" width="1920" height="1280" alt="" />
      </StyledCoverBackground>
      {/* */}
      <StyledCoverContent>
        {/* */}
        <EuroHeading2 style={{ color: 'var(--color-100)'}}>Fast Project KICKOFF.</EuroHeading2>
        {!isAuthenticated &&
          <BasicButton size="large" color="primary" onClick={signupClickHandle}>Sign Up Now</BasicButton>
        }
        {/* プロジェクト作成ボタン */}
        {isAuthenticated &&
          <LinkButton size="large" to="/create">Start Your Project Now</LinkButton>
        }
      </StyledCoverContent>
    </StyledCover>
  )
}

//
export default Cover

//
const StyledCover = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 30px 0;
`
//
const StyledCoverBackground = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

//
const StyledCoverContent = styled.div`
  position: relative;
  z-index: 5;
  width: 1200px;
  min-height: 340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 2rem;

  @media (max-width: 768px) {
    width: ${({theme}) => theme.spContent.maxWidth};
    padding: 0 1rem;
  }
`
