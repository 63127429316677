import { useDraggable, useDroppable } from "@dnd-kit/core"
import { Transform } from "@dnd-kit/utilities"
import { css } from "@emotion/react"
import React from "react"
import DropSpacer from "./DropSpacer"
import JiraNodeBlockHead from "./JiraNodeBlockHead"
import { JIRA_NODE } from "./NodeUtils"

//
interface P {
  node: JIRA_NODE
  path: string[]
  onCreate: (blockPath: string[]) => void
  onDelete: (blockPath: string[]) => void
}

// ドラッグ＆ドロップ可能なコンポーネント
const JiraNodeBlock: React.FC<P> = ({ node, path, onCreate, onDelete }) => {

  //
  const blockPath = [...path, node.id]

  //
  const {isOver, setNodeRef: setDropNodeRef} = useDroppable({
    id: node.id,
    data: {
      path: blockPath
    }
  });

  //
  const { attributes, listeners, setNodeRef: setDragNodeRef, transform } = useDraggable({
    id: node.id,
    data: {
      path: blockPath
    }
  })

  // ルート要素の場合：
  if (path.length === 0) {
    return (
      <div>
        {node.nodes.map((childNode, index) => {
          // 子要素のレンダリング
          // 要素同士の間に入れるためのスペーサーも配置
          return (
            <React.Fragment key={`${node.id}-children${index}`}>
              <DropSpacer key={`${childNode.id}-before`} spaceId={`${childNode.id}-before`} path={[...blockPath, childNode.id]} type={'before'} />
              <JiraNodeBlock node={childNode} path={blockPath} onCreate={onCreate} onDelete={onDelete} />
              
              {/* リストの最後のアイテムの場合: */}
              {index === node.nodes.length -1 &&
                <DropSpacer key={`${childNode.id}-after`} spaceId={`${childNode.id}-after`} path={[...blockPath, childNode.id]} type={'after'} />
              }
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  // ---
  //
  return (
    <div ref={setDragNodeRef} css={[
      styles.container(path.length),
      transform ? styles.dragStyle(transform) : undefined,
      styles.dropStyle(isOver && !transform)
    ]}>

      {/* ドラッグターゲットのヘッダ */}
      <JiraNodeBlockHead
        label={node.label}
        isDrag={transform ? true : false}
        dropRef={setDropNodeRef}
        listeners={listeners}
        attributes={attributes}
        onCreate={() => onCreate(blockPath)}
        onDelete={() => onDelete(blockPath)}
      />

      {/* 子要素 / ドラッグ中は非表示 */}
      {!transform &&
        <div css={styles.childWrapper}>
          {node.nodes.map((childNode, index) => {
            // 子要素のレンダリング
            // 要素同士の間に入れるためのスペーサーも配置
            return (
              <React.Fragment key={`${node.id}-children${index}`}>
                <DropSpacer key={`${childNode.id}-before`} spaceId={`${childNode.id}-before`} path={[...blockPath, childNode.id]} type={'before'} />
                <JiraNodeBlock node={childNode} path={blockPath} onCreate={onCreate} onDelete={onDelete} />
                
                {/* リストの最後のアイテムの場合: */}
                {index === node.nodes.length -1 &&
                  <DropSpacer key={`${childNode.id}-after`} spaceId={`${childNode.id}-after`} path={[...blockPath, childNode.id]} type={'after'} />
                }
              </React.Fragment>
            )
          })}
        </div>
      }
    </div>
  )
}

export default JiraNodeBlock

// --- --- ---
// style
//
const styles = {
  container: (deep: number) => css`
    border: ${deep === 0 ? '0px': '2px'} solid hsl(${deep * 50}deg, 50%, 70%);
    border-radius: 6px;
  `,
  dragStyle: (tf: Transform) => css`
    z-index: 100;
    transform: translate3d(${tf.x}px, ${tf.y}px, 0);
  `,
  dropStyle: (isOver: boolean) => css`
    background-color: ${isOver ? 'var(--primary-30)' : 'var(--color-100)'};
    width: 100%;
  `,
  childWrapper: css`
    padding-left: 15px;
    padding-right: 15px;
    background-color: var(--primary-10);
    border-radius: 0 0 4px 4px;
  `
}