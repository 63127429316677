import { Suspense, useCallback, useState } from "react"
import ModalPortal from "../../components/elements/modal/ModalPortal"
import { useNavigate } from "react-router-dom"
import { EuroHeading2 } from "../../styles/styled/common/typography/EuroText.styled"
import { MainWrapper } from "../../styles/styled/layouts/MainWrapper.styled"
import ProjectCreateForm from "../../components/elements/create/ProjectCreateForm"
import { LoadingWrapper } from "../../styles/styled/common/LoadingPanel"

//
const CreateIndex: React.FC = () => {
  //
  const navigate = useNavigate()
  //
  const [isSubmitting, setIsSubmitting] = useState(false)

  // ---
  const onComplete = useCallback((projectId: string) => {
    // 作成されたプロジェクトのIDへリダイレクト
    navigate(`/projects/${projectId}`)
  }, [navigate])

  // ---
  //
  return (
    <MainWrapper center column>
      <EuroHeading2>Create New Project</EuroHeading2>

      <Suspense>
        <ProjectCreateForm setLoadingState={setIsSubmitting} onCreateComplete={onComplete} />
      </Suspense>

      {/** */}
      {isSubmitting && <ModalPortal children={<LoadingWrapper />} />}
      
    </MainWrapper>
  )
}

//
export default CreateIndex
