import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import useSWR, { Key } from 'swr'
import tokenState from '../../../../state/Auth0'
import ProjectCard from './ProjectCard'
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

//
interface P {
  swrKey: Key
  swiper?: Boolean
  onLoaded?: (payload: { count: number }) => void
}

//
const ProjectList: React.FC<P> = ({ swrKey, swiper, onLoaded}) => {
  //
  const authToken = useRecoilValue(tokenState)
  //
  const { data: projects } = useSWR([swrKey, authToken], { suspense: true })

  //
  useEffect(() => {
    if (projects && onLoaded) {
      onLoaded({ count: projects.count })
    }
  }, [onLoaded, projects])

  // ローディング
  if (!projects) {
    return <></>
  }

  // 件数0の時
  if (projects.data.length === 0) {
    return <div>
      <span>表示するプロジェクトがありません。</span>
    </div>
  }

  if (swiper) {
    return (
      <ul css={styles.swiperContainer}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          breakpoints={{
            768: {slidesPerView: 4}
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
        {projects.data.map((project: any) => {
          return (
            <SwiperSlide key={project.id}>
              <li>
                <ProjectCard data={project} />
              </li>
            </SwiperSlide>
            )
        })}
  
        </Swiper>
      </ul>
    )
  
  }
  //
  return (
    <ul css={styles.container}>
      {projects.data.map((project: any) => {
        return (
          <li key={project.id}>
            <ProjectCard data={project} />
          </li>)
      })}
    </ul>
  )
}

//
export default ProjectList

// --- --- ---
// style
//
const styles = {
  swiperContainer: css`
    @media (max-width: 768px) {
      padding: 0 1rem;
    }
    li {
      display: block;
      height: 100%;
    }
    .swiper-slide {
      height: auto;
    }
  `,
  container: css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px 20px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
    > li {
      display: block;
      height: 100%;
    }
  `
}