import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { EuroHeading5 } from "../../../../styles/styled/common/typography/EuroText.styled"
import { SmallText } from "../../../../styles/styled/common/typography/Text.styled"
import { StyledDetailSectionContent } from "../../../styled/projects"
import ProjectActivitiesList from "./ProjectActivitiesList"

interface P {
  activities: any
}
//
const ProjectActivitiesContents: React.FC<P> = ({ activities }) => {
  //
  const [renderList, setRenderList] = useState<{[key: string]: any[]}>()
  //
  useEffect(() => {
    if (activities.length > 0) {
      const list: {[key: string]: any[]} = {}
      activities.forEach((act: any) => {
        const d = dayjs(act.createdDatetime).format('YYYY/MM/DD')
        //
        if (!list[d]) {
          list[d] = []
        }
        //
        list[d].push(act)
      })
      //
      setRenderList(list)
    }
  }, [activities])
  //
  return (
    <StyledDetailSectionContent style={{ rowGap: '2rem' }}>
      {renderList && Object.keys(renderList).map((keyDate) => {
        return (
          <div key={`active_${keyDate}`}>
            <EuroHeading5 style={{ marginBottom: '.6rem' }}>{keyDate}</EuroHeading5>
            <ProjectActivitiesList list={renderList[keyDate]} />
          </div>
        )
      })}
      {/* */}
      {!renderList &&
        <SmallText>アクティビティはありません</SmallText>
      }
    </StyledDetailSectionContent>
  )
}

//
export default ProjectActivitiesContents