import { useState } from "react"
import { DangerZone } from "../../../../styles/styled/common/blocks/DangerZone.styled"
import ModalPortal from "../../modal/ModalPortal"
import ProjectDeleteConfirm from "./ProjectDeleteConfirm"

//
interface ProjectDangerZoneProps {
  onDelete: VoidFunction;
}
//
const ProjectDangerZone: React.FC<ProjectDangerZoneProps> = ({ onDelete }) => {
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  //
  const onClickHandle = () => {
    setIsDeleteModal(true)
  }

  //
  const onCancelHandle = () => {
    setIsDeleteModal(false)
  }

  //
  const onDeleteHandle = () => {
    onDelete()
  }

  //
  return (
    <>
      <DangerZone onDangerClick={onClickHandle}>Delete this project</DangerZone>

      {/* 削除確認モーダル */}
      {isDeleteModal &&
        <ModalPortal children={
          <ProjectDeleteConfirm onCancel={onCancelHandle} onDelete={onDeleteHandle} />
        } />
      }
    </>
  )
}

//
export default ProjectDangerZone
