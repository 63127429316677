import { useCallback, useState } from "react"
import { BasicButton } from "../../../../styles/styled/common/buttons/BasicButton.styled"
import ModalPortal from "../../modal/ModalPortal"
import CollaboratorApplyConfirm from "./modal/CollaboratorApplyConfirm"

interface ApplyCollaborationButtonProps {
  projectId: string;
  onApply?: VoidFunction;
}

const ApplyCollaborationButton: React.FC<ApplyCollaborationButtonProps> = ({ projectId, onApply }) => {
  //
  const [isApplyConfirm, setIsApplyConfirm] = useState(false)

  //
  const onApplyHandle = useCallback(async () => {
    setIsApplyConfirm(false)
    if (onApply) {
      onApply()
    }
  }, [onApply])
  //
  const onCancelHandle = useCallback(() => {
    setIsApplyConfirm(false)
  }, [])

  //
  return (
    <>
      <BasicButton color="white" border onClick={() => setIsApplyConfirm(true)}>apply collaboration</BasicButton>

      {/* コラボレーター追加確認モーダル */}
      {isApplyConfirm &&
        <ModalPortal children={
          <CollaboratorApplyConfirm projectId={projectId} onCancel={onCancelHandle} onApply={onApplyHandle} />
        } />
      }
    </>
  )
}

//
export default ApplyCollaborationButton