import styled from "@emotion/styled";
import { Link, LinkProps } from "react-router-dom";
import { Icon } from "../common/icons/Icon.styled";
import { EuroLabelText } from "../common/typography/EuroText.styled";

// --- --- ---
/**
 * カテゴリーカードを並べるラッパー
 */
export const CategoriesCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 10px;
    padding: 0 1rem;
  }
`

// --- --- ---
//
interface CategoriesCardProps extends LinkProps{
  name: string;
}

/**
 * 
 */
export const CategoriesCard = styled(({ name, children, ...props }: CategoriesCardProps) => (
  <Link {...props}>
    <Icon name={name} size="4rem" primary />
    <EuroLabelText small>{children}</EuroLabelText>
  </Link>
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  padding: 20px 0 25px;
  box-shadow: 0 4px 8px var(--shadow-100);
  border: 1px solid var(--primary-100);
  border-radius: 12px;
  background-color: var(--color-100);
  transition: all .15s linear;

  &:hover {
    box-shadow: 0 4px 10px var(--shadow-500);
  }
`