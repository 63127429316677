import { useAuth0 } from "@auth0/auth0-react"
import { AccountCardContent, AccountCardLinkPanel, AccountCardPanel } from "../../../../styles/styled/account/AccountCard.styled"
import { BasicButton } from "../../../../styles/styled/common/buttons/BasicButton.styled"

//
const envCallback = process.env.REACT_APP_AUTH0_CALLBACK || '/'

//
interface P {
  data: any
  owner?: boolean
  disabled?: boolean
}

const AccountCard: React.FC<P> = ({ data, owner = false, disabled = false }) => {
  //
  const { logout } = useAuth0()

  //
  const onSignoutHandle = () => {
    logout({ returnTo: envCallback })
  }

  // 表示カードとして
  if (disabled) {
    return (
      <AccountCardPanel>
        <AccountCardContent
          photoSrc={data.userImageUrl || data.imageUrl}
          name={data.name || data.userName}
          description={data.description || ''}
          count={data.ownedProjectCount || 0}
        >
          {owner && <BasicButton onClick={onSignoutHandle} size="xsmall" style={{ marginLeft: 'auto' }}>Sign Out</BasicButton>}
        </AccountCardContent>
      </AccountCardPanel>
    )
  }

  // リンクカードとしてレンダリング
  return (
    <AccountCardLinkPanel to={`/collaborators/${data.userId || data.id}`}>
      <AccountCardContent
        photoSrc={data.userImageUrl || data.imageUrl}
        name={data.name || data.userName}
        description={data.description || ''}
        count={data.ownedProjectCount || 0}
      />
    </AccountCardLinkPanel>
  )
}

//
export default AccountCard
