import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Link, LinkProps } from "react-router-dom"

interface LINK_PROPS {
  color?: 'primary' | 'white' | 'black';
  size?: 'large' | 'middle' | 'regular' | 'small';
  bold?: boolean;
  underline?: boolean;
}

// LinkコンポーネントにLIN_PROPSを追加した拡張タイプ
type LINK_ELEMENT_PROPS = LINK_PROPS & LinkProps & React.RefAttributes<HTMLAnchorElement>

const LinkPropsStyle = ({ color, size, underline, bold}: LINK_PROPS) => css`

  // 文字色
  ${color === 'primary' && css`color: var(--primary);`}
  ${color === 'white' && css`color: var(--color-100);`}
  ${!color && css`color: var(--color-850);`}

  // 文字サイズ
  ${size === 'large' && css`font-size: 1.5rem;`}
  ${size === 'middle' && css`font-size: 1rem;`}
  ${size === 'small' && css`font-size: .84rem;`}
  ${!size && css`font-size: .92rem;`}

  //
  ${bold && css`font-weight: bold;`}

  // 下線
  ${underline ?
      css`
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      `
      :
      css`
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      `
  }

  //
  &[data-disabled='true'] {
    pointer-events: none;
    text-decoration: none;
    color: var(--color-500);
  }
`

/**
 * Linkコンポーネントのテキスト用スタイリング
 * 
 * @param color テキストの文字色を指定します
 * @param size テキストの文字サイズを指定します
 * @param underline ホバー時に下線を表示します
 */
// スタイリング用のpropsをLinkに渡すとワーニングが出るので、対処
// https://tech-1natsu.hatenablog.com/entry/2019/07/06/115606
export const TextLink = styled(({ color, size, underline, bold, ...props }: LINK_ELEMENT_PROPS) => (<Link {...props} />))`
  ${LinkPropsStyle}
`

/**
 * テキストのAタグエレメント
 * 
 * @param color テキストの文字色を指定します
 * @param size テキストの文字サイズを指定します
 * @param underline ホバー時に下線を表示します
 */
export const TextAnchor = styled.a<LINK_PROPS>`
  ${LinkPropsStyle}
`

/**
 * buttonエレメントのテキスト用スタイリング
 * 
 * @param color テキストの文字色を指定します
 * @param size テキストの文字サイズを指定します
 * @param underline ホバー時に下線を表示します
 */
export const TextButton = styled.button<LINK_PROPS>`
  ${LinkPropsStyle}
`