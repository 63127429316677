import styled from "@emotion/styled";
import { Icon } from "./icons/Icon.styled";

//
interface AccountPhotoProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  src?: string;
  alt?: string;
  size?: number;
}
export const AccountPhoto = styled(({ src, alt, size, ...props }: AccountPhotoProps) => (
  <div {...props}>
    {src ? 
      <img src={src} alt={alt || ''} className="account-photo_img" /> :
      <Icon name="person" size={`${(size || 80) / 24}rem`} />
    }
  </div>
))`
  position: relative;
  width: ${({size}) => size || 80}px;
  height: ${({size}) => size || 80}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: var(--color-100);
  border-radius: 999px;
  overflow: hidden;

  @media (max-width: 768px) {
    width: ${({size}) => size ? size / 2 : 40}px;
    height: ${({size}) => size ? size / 2 : 40}px;  
  }

  .account-photo_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`