import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWRImmutable from 'swr/immutable'
import { EuroFormLabel, FormBlock } from '../../../styles/styled/common/form/FormLayout.styled';
import CategoryOptions from "../../common/forms/CategoryOptions"

//
interface CategoryBlockProps {
  defaultSelect?: number;
}
const CategoryBlock: React.FC<CategoryBlockProps> = ({ defaultSelect }) => {
  // APIからカテゴリーリストを取得する
  const { data: categories } = useSWRImmutable<{ data: categoryType[]}>('/category', { suspense: true })
  //
  const [cateState, setCateState] = useState<cateParent[]>([]) 

  // 選択された親カテゴリ
  const [selectedParent, setSelectedParent] = useState('')
  // 選択された子カテゴリ
  const [selectedChild, setSelectedChild] = useState('')

  // 親のカテゴリ選択に合わせた子カテゴリリストの抽出
  const secondCategories = useMemo(() => {
    if (selectedParent === '') {
      return []
    }
    //
    const pIdx = cateState.findIndex(p => p.id === selectedParent)
    return cateState[pIdx].children || []
  }, [cateState, selectedParent])

  //
  const onSelectedParent = useCallback((parentId: string) => {
    setSelectedParent(parentId)
    setSelectedChild('')
  }, [setSelectedParent, setSelectedChild])

  //
  useEffect(() => {
    if (categories && categories.data) {
      const cateNode: cateParent[] = []
      // カテゴリを親子の形に
      categories.data.forEach(cat => {
        // 選択の初期値
        if (defaultSelect !== undefined && cat.id === defaultSelect) {
          if (cat.parentCategoryId) {
            setSelectedParent(cat.parentCategoryId.toString())
            setSelectedChild(cat.id.toString())
          } else {
            setSelectedParent(cat.id.toString())
          }
        }
        //
        if (cat.parentCategoryId) {
          // 親カテゴリがある
          const pIdx = cateNode.findIndex(p => p.id === cat.parentCategoryId?.toString())
          if (pIdx === -1) {
            // 親がまだ作成前
            cateNode.push({
              id: cat.parentCategoryId.toString(),
              label: '',
              children: [{ id: cat.id.toString(), label: cat.name }]
            })
          } else {
            // 親がすでにある
            cateNode[pIdx].children.push({ id: cat.id.toString(), label: cat.name })
          }
        } else {
          const pIdx = cateNode.findIndex(p => p.id === cat.id?.toString())
          if (pIdx === -1) {
            // 親がまだ作成前
            cateNode.push({
              id: cat.id.toString(),
              label: cat.name,
              children: []
            })
          } else {
            // 親がすでにある（ラベルとか未指定かも）
            cateNode[pIdx].label = cat.name
          }
        }
      })
      //
      //
      setCateState([...cateNode])
    }
  }, [categories, defaultSelect])

  //
  if (!categories) {
    // ロード中
    return <></>
  }

  //
  return (
    <>
      {/* カテゴリ */}
      <FormBlock card>
        <EuroFormLabel required>Category1:</EuroFormLabel>
        <CategoryOptions name="parentCategory" options={cateState} onSelect={onSelectedParent} selected={selectedParent} required />
      </FormBlock>

      {/* サブカテゴリ */}
      {(selectedParent && secondCategories.length > 0) &&
        <FormBlock card>
          <EuroFormLabel>Category2:</EuroFormLabel>
          <CategoryOptions name="childCategory" options={secondCategories} onSelect={setSelectedChild} selected={selectedChild} />
        </FormBlock>
      }
      <input hidden name="categoryId" value={selectedChild === '' ? selectedParent : selectedChild} readOnly />
    </>
  )
}

//
export default CategoryBlock

//
type cateParent = {
  id: string
  label: string
  children: cateOption[]
}
type cateOption = {
  id: string
  label: string
}

//
type categoryType = {
  description: string
  iconName: string
  id: number
  name: string
  parentCategoryId: number | null
}