import React from 'react';
import ReactDOM from 'react-dom/client';
import { Global, ThemeProvider } from '@emotion/react'
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from 'swr'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { swrFetcher } from './lib/swr/fetcher';
import style from './styles/global.style';
import './styles/theme.css'
import { Auth0Provider } from '@auth0/auth0-react';
import ScrollToTop from './lib/router/ScrollToTop';
import { RecoilRoot } from 'recoil';
import ThemeStyled from './styles/styled/Theme.styled';
//
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//
const envDomain = process.env.REACT_APP_AUTH0_DOMAIN || ''
const envClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
const envCallback = process.env.REACT_APP_AUTH0_CALLBACK || ''

const swrOptions = {
  fetcher: swrFetcher,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
}

//
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={envDomain}
        clientId={envClientId}
        redirectUri={envCallback}
        audience="https://aws-jwt-authorizer"
      >
          <Global styles={style} />
          <RecoilRoot>
            <ScrollToTop />
            <SWRConfig value={swrOptions}>
              <ThemeProvider theme={ThemeStyled}>
                <App />
              </ThemeProvider>
            </SWRConfig>
          </RecoilRoot>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
