import { css } from "@emotion/react"
import { ProjectProps } from "../../../lib/entity/Project"
import { EuroFormLabel, FormBlock, FormSection } from "../../../styles/styled/common/form/FormLayout.styled"
import { InputText } from "../../../styles/styled/common/form/Input.styled"
import { ProductIcon } from "../../../styles/styled/common/icons/ProductIcon.styled"
import { EuroHeading3 } from "../../../styles/styled/common/typography/EuroText.styled"
import { Heading4 } from "../../../styles/styled/common/typography/Heading.styled"

interface FormIntegrationProps {
  projectData?: ProjectProps<string>;
}


const FormIntegration: React.FC<FormIntegrationProps> = ({ projectData }) => {

  return (
    <FormSection width="800px">
      <EuroHeading3>Integration</EuroHeading3>
      {/** Jira */}
      <div css={styles.integrationBlock}>
        <div className="block-head">
          <ProductIcon name="jira" />
          <Heading4>Jira</Heading4>
        </div>
        <FormBlock>
          <EuroFormLabel>Token:</EuroFormLabel>
          <InputText name="jiraApiToken" defaultValue={projectData?.jiraApiToken} />
        </FormBlock>
        <FormBlock>
          <EuroFormLabel>Domain:</EuroFormLabel>
          <InputText name="jiraDomain" defaultValue={projectData?.jiraDomain} />
        </FormBlock>
      </div>
      {/** Slack */}
      <div css={styles.integrationBlock}>
        <div className="block-head">
          <ProductIcon name="slack" />
          <Heading4>Slack</Heading4>
        </div>
        <FormBlock>
          <EuroFormLabel>API Token:</EuroFormLabel>
          <InputText name="slackApiToken" defaultValue={projectData?.slackApiToken} />
        </FormBlock>
        <FormBlock>
          <EuroFormLabel>Team ID:</EuroFormLabel>
          <InputText name="slackTeamId" defaultValue={projectData?.slackTeamId} />
        </FormBlock>
        <FormBlock>
          <EuroFormLabel>Channel ID:</EuroFormLabel>
          <InputText name="slackChannelId" defaultValue={projectData?.slackChannelId} />
        </FormBlock>
      </div>
    </FormSection>
  )
}

//
export default FormIntegration

//
const styles = {
  integrationBlock: css`
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    .block-head {
      display: flex;
      align-items: center;
      column-gap: .3em;
    }
  `,
}
