import styled from "@emotion/styled";
import { BackImage } from "../common/BackImage.styled";

interface AccountHeroProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  coverSrc: string;
}
export const AccountHero = styled(({coverSrc, children, ...props}: AccountHeroProps) => (
  <div {...props}>
    <BackImage>
      <img className="bg-image" src={coverSrc} alt="" />
    </BackImage>

    <div className="account_hero-contents">
      <div className="account_hero-card">
        {children}
      </div>
    </div>
  </div>
))`
  position: relative;

  .account_hero-contents {
    position: relative;
    z-index: 5;
    width: ${({theme}) => theme.content.maxWidth};
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 20px 0;

    @media (max-width:768px) {
      width: ${({theme}) => theme.spContent.maxWidth};
    }
  }

  .account_hero-card {
    background-color: ${({theme}) => theme.alphaColor('#fff', 0.6)};
    backdrop-filter: blur(4px);
    padding: .4rem .8rem;
    border-radius: 6px;
    margin-top: 200px;
    box-shadow: 0 4px 8px var(--shadow-100);
    transform: translateY(50px);
    max-width: 50%;
  }
`