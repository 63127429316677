import { Link } from "react-router-dom"
import { css } from "@emotion/react";
import { AccountPhoto } from "../../../../styles/styled/common/AccountPhoto.styled";
import { SmallText } from "../../../../styles/styled/common/typography/Text.styled";
import useSWR from "swr";

interface ProjectDetailHeadProps {
  projectId: string;
}
//
const ProjectDetailHead: React.FC<ProjectDetailHeadProps> = ({ projectId }) => {
  //
  const { data: collaboratorData } = useSWR(`/collaborator?${new URLSearchParams({ projectId: projectId })}`, { suspense: true })

  //
  const activeCollaborators = collaboratorData.data.filter((col: any) => {
    if (col.userId !== 0 && (col.status === 2)) {
      return true
    }
    return false
  })
  //
  if (activeCollaborators.length === 0) {
    return (<></>)
  }
  //
  return (
    <div css={styles.container}>
      <ul css={styles.accounts}>
        {activeCollaborators.map((collaborator: any) => {
          return (
            <li id={collaborator.id} css={styles.accountLink} key={collaborator.id}>
              <Link to={`/collaborators/${collaborator.userId}`}>
                <AccountPhoto src={collaborator.imageUrl} size={30} />
              </Link>
              <div className="member-display-name">
                <SmallText $x $nowrap>{collaborator.userName}</SmallText>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ProjectDetailHead

// --- --- ---
// style
//
const styles = {
  container: css`
    padding: 30px;
  `,

  // ---
  accounts: css`
    display: flex;
    padding-left: 5px;
    > li {
      margin-left: -5px;
    }
  `,
  accountLink: css`
    position: relative;
    .member-display-name {
      position: absolute;
      top: -2rem;
      left: 0;
      background-color: var(--color-100);
      border: 1px solid var(--color-300);
      border-radius: 6px;
      padding: .1em .5em;
      line-height: 1em;
      transform: translateY(5px);
      opacity: 0;
      transition: all .2s ease-in-out;
    }
    &:hover {
      z-index: 10;
      .member-display-name {
        transform: translateY(0);
        opacity: 1;
      }
    }
  `,

  // ---
  bottom: css`
    display: flex;
    column-gap: 1rem;
    margin-top: 30px;
  `,
  linkBtn: css`
    display: flex;
    align-items: center;
    column-gap: .2rem;
    &:hover {
      text-decoration: underline;
    }
  `
}