
// ---
export interface JIRA_NODE {
  id: string
  label: string
  nodes: JIRA_NODE[]
}

// --- --- ---
// nodeのpathの位置にあるデータを取り出す（nodeからは削除する）
//
export const popNode = (node: JIRA_NODE, path: string[]): JIRA_NODE | undefined => {
  //
  let nodeCount = 0
  while (nodeCount < node.nodes.length) {
    if (node.nodes[nodeCount].id === path[0]) {
      // パスに含まれるノード
      if (path.length === 1) {
        // 目的のノード
        const returnNode = Object.assign({}, node.nodes[nodeCount])
        node.nodes.splice(nodeCount, 1)
        return returnNode
      } else {
        return popNode(node.nodes[nodeCount], path.splice(1))
      }
    }
    nodeCount++
  }
  //
  return undefined
}

// ---
// nodeのpathの位置の下に、addNodeを追加する
export const pushNode = (node: JIRA_NODE, path: string[], addNode: JIRA_NODE) => {
  let nodeCount = 0
  while (nodeCount < node.nodes.length) {
    if (node.nodes[nodeCount].id === path[0]) {
      // パスに含まれるノード
      if (path.length === 1) {
        // 目的のノード
        node.nodes[nodeCount].nodes = [...node.nodes[nodeCount].nodes, addNode]
        break
      } else if (path.length > 1) {
        pushNode(node.nodes[nodeCount], path.splice(1), addNode)
      }
    }
    nodeCount++
  }
}

// ---
// nodeのpathの位置の前後にaddNodeを追加する
export const spliceNode = (node: JIRA_NODE, path: string[], addNode: JIRA_NODE, type: string) => {
  //
  let nodeCount = 0
  while (nodeCount < node.nodes.length) {
    if (node.nodes[nodeCount].id === path[0]) {
      if (path.length === 1) {
        if (type === 'before') {
          // nodeCountの前に追加
          if (nodeCount === 0) {
            node.nodes.unshift(addNode)
          } else {
            node.nodes.splice(nodeCount, 0, addNode)
          }
        } else {
          // nodeCountの後に追加
          node.nodes.splice(nodeCount+1, 0, addNode)
        }
      } else {
        // pathを下る
        spliceNode(node.nodes[nodeCount], path.splice(1), addNode, type)
      }
      // pathのidは見つかったのでループは抜ける
      break
    }
    nodeCount++
  }
}