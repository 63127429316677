import { Outlet, useParams } from "react-router-dom"
import ProjectSidebar from './ProjectSidebar'
import { useRecoilValue } from 'recoil'
import tokenState from '../../../../state/Auth0'
import { DetailWrapper } from '../../../../styles/styled/projects/DetailWrapper.styled'
import useSWRImmutable from 'swr/immutable'
import useSWR from 'swr'
import { useEffect, useState } from "react"
import CollaboratorContext from "./CollaboratorContext"

/**
 * プロジェクトの詳細ページの共通枠（レイアウト機能）
 */
const ProjectDetailWrapper: React.FC = () => {
  //
  const { projectId } = useParams()
  //
  const authToken = useRecoilValue(tokenState)

  // TODO: レイアウトファイルなので、swrは出来る限り子コンポーネントへ移動する
  // ログインユーザーの情報を取得（idが変わることは無いはずなのでimmutableに）
  const { data: account } = useSWRImmutable(authToken ? [ '/user/logging-in', authToken ] : null, { suspense: true })
  // 変更している可能性が高いので下記はリフェッチ
  const { data: projectData } = useSWR(`/project/${projectId}`, { suspense: true })
  const { data: collaboratorData } = useSWR(projectId ? `/collaborator?${new URLSearchParams({ projectId: projectId })}` : null, { suspense: true })

  // コラボレーターの状態
  const [isCollaborator, setIsCollaborator] = useState(-1)

  //
  useEffect(() => {
    //
    if (
      account && (
        projectData.ownerUserId === account.id ||
        projectData.prUserId === account.id ||
        projectData.contactUserId === account.id
      )
    ) {
      setIsCollaborator(2)
    } else if (account) {
      const findCollabo = collaboratorData.data.find((c: any) => c.userId === account.id)
      if (findCollabo) {
        setIsCollaborator(findCollabo.status)
      } else {
        setIsCollaborator(0)
      }
    } else {
      setIsCollaborator(0)
    }
  }, [account, collaboratorData.data, projectData.contactUserId, projectData.ownerUserId, projectData.prUserId])

  //
  if (!projectId || !projectData || !collaboratorData || isCollaborator === -1) {
    //
    return<></>
  }

  //
  if (projectData.isPrivate && isCollaborator !== 2) {
    return (
      <DetailWrapper>
        <div>
          <CollaboratorContext.Provider value={isCollaborator}>
            <Outlet />
          </CollaboratorContext.Provider>
        </div>
      </DetailWrapper>
    )
  }

  //
  return (
    <DetailWrapper sidebar>
      <ProjectSidebar projectId={projectId} integration={projectData.integrateSaasList} isAdmin={account && account.id === projectData.ownerUserId} />

      <div>
        <CollaboratorContext.Provider value={isCollaborator}>
          <Outlet />
        </CollaboratorContext.Provider>
      </div>
    </DetailWrapper>
  )
}

//
export default ProjectDetailWrapper
