import { Component, ErrorInfo, ReactNode } from "react";
//
interface Props {
  children: ReactNode
}
interface State {
  hasError: boolean
}
//
class ErrorBoundary extends Component<Props, State> {

  //
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: TypeError) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // エラーをSentryなどに送る場合はここ
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <b>エラーが発生しました</b>
        </div>
      )
    }

    // エラーなし
    return this.props.children; 
  }
}

export default ErrorBoundary