import { css } from "@emotion/react"
import { EuroFormLabel, FormBlock, FormSection } from "../../../../styles/styled/common/form/FormLayout.styled"
import { InputText } from "../../../../styles/styled/common/form/Input.styled"
import { ProductIcon } from "../../../../styles/styled/common/icons/ProductIcon.styled"
import { EuroHeading3 } from "../../../../styles/styled/common/typography/EuroText.styled"
import { Heading4 } from "../../../../styles/styled/common/typography/Heading.styled"

const IntegrationSetting: React.FC = () => {
  return (
    <FormSection width="800px">
      <EuroHeading3>Integration</EuroHeading3>
      <FormBlock>
        <div css={styles.integrationBlock}>
          <ProductIcon name="jira" />
          <Heading4>Jira</Heading4>
        </div>
        <FormBlock>
          <EuroFormLabel>Token:</EuroFormLabel>
          <InputText name="jiraApiToken" />
        </FormBlock>
      </FormBlock>
      <FormBlock>
        <div css={styles.integrationBlock}>
          <ProductIcon name="slack" />
          <Heading4>Slack</Heading4>
        </div>
        <FormBlock>
          <EuroFormLabel>Channel:</EuroFormLabel>
          <InputText name="slackApiToken" />
        </FormBlock>
      </FormBlock>
    </FormSection>
  )
}

//
export default IntegrationSetting


// --- --- ---
// style:
//
const styles = {
  integrationBlock: css`
    display: flex;
    align-items: center;
    column-gap: .3em;
    margin-bottom: .6rem;
  `,
}