import { HorizontalBlock } from "../../../../styles/styled/layouts/HorizontalBlock.styled"

//
interface P {
  userId: string
  userName: string
}

//
const SlackUser: React.FC<P> = ({ userId, userName }) => {
  //
  return (
    <HorizontalBlock gap='5px'>
      {/** <img css={styles.avatar} src={user.profile.image_48} alt="" width="24" height="24" /> */}
      <h4>@{userName}</h4>
    </HorizontalBlock>
  )
}

//
export default SlackUser
