import { css } from "@emotion/react"
import dayjs from "dayjs"
import { ChangeEvent, Dispatch, MouseEvent, useEffect, useState } from "react"
import { PhaseProps } from "../../../lib/entity/Project"
import { InputText } from "../../../styles/styled/common/form/Input.styled"
import { Icon } from "../../../styles/styled/common/icons/Icon.styled"
import { EuroLabelText } from "../../../styles/styled/common/typography/EuroText.styled"
import { Heading4 } from "../../../styles/styled/common/typography/Heading.styled"
import PhaseItem from "./PhaseItem"

//
interface PhaseListProps {
  defaultValue?: PhaseProps<string>[];
  phaseList: PhaseProps<Date>[];
  setPhaseList: Dispatch<React.SetStateAction<PhaseProps<Date>[]>>;
}

//
const PhaseList: React.FC<PhaseListProps> = ({ defaultValue, phaseList, setPhaseList }) => {
  // ---
  const [text, setText] = useState('')
  const [startAt, setStartAt] = useState(dayjs().format('YYYY-MM-DD'))
  const [endAt, setEndAt] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (defaultValue) {
      //
      const defaultPhase = defaultValue.map(p => {
        return {
          ...p,
          startDate: dayjs(p.startDate).toDate(),
          completionDate: dayjs(p.completionDate).toDate(),
        } as PhaseProps<Date>
      })
      //
      setPhaseList(defaultPhase)
    }
  }, [defaultValue, setPhaseList])
  // ---
  //
  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      setText(event.target.value)
    }
  }

  // ---
  //
  const onChangeDate = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      if (event.target.name === '_phaseStartDate') {
        setStartAt(event.target.value)
      } else {
        setEndAt(event.target.value)
      }
    }
  }
  
  // ---
  const onSubmitHandle = (event: MouseEvent<HTMLButtonElement>) => {
    if (
      text !== '' &&
      startAt !== '' &&
      endAt !== ''
    ) {
      // 日付が古いほうがStartになるようにしたいので、一旦変数に入れる
      const date1 = new Date(startAt)
      const date2 = new Date(endAt)
      //
      setPhaseList([
        ...phaseList,
        {
          name: text,
          startDate: (date1 < date2) ? date1 : date2,
          completionDate: (date1 < date2) ? date2 : date1,
        }
      ])
      // クリア
      setText('')
    }
  }

  // ---
  return (
    <div css={styles.phaseListBlock}>
      <Heading4>Phase</Heading4>

      {/* 設定されているフェーズ一覧 */}
      {phaseList.length > 0 &&
        <ul css={styles.phaseBlock}>
          {phaseList.map((phase, index) => {
            return <PhaseItem data={phase} key={`phase_${index}`} />
          })}
        </ul>
      }

      {/* フェーズの追加フォーム */}
      <div css={styles.formBlock}>
        <div css={styles.inputBlock}>
          <InputText css={styles.inputExp} name="_phaseName" onChange={onChangeInput} value={text} />
          <div css={styles.dateBlock}>
            <EuroLabelText as={'label'} small>From:</EuroLabelText>
            <input type="date" name="_phaseStartDate" onChange={onChangeDate} value={startAt} />
          </div>
          <Icon name="arrow_right_alt" />
          <div css={styles.dateBlock}>
            <EuroLabelText as={'label'} small>To:</EuroLabelText>
            <input type="date" name="_phaseCompletionDate" onChange={onChangeDate} value={endAt} />
          </div>
        </div>
        <div>
          <button type="button" css={styles.addButton} onClick={onSubmitHandle}>
            <Icon name="post_add" />
            <span>Add Phase</span>
          </button>
        </div>
      </div>
    </div>
  )
}

//
export default PhaseList

// --- --- ---
// style
//
const styles = {
  phaseListBlock: css`
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;
  `,
  formBlock: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
    width: 100%;
    padding: 1.2rem 1rem;
    border: 1px solid var(--color-300);
    border-radius: 12px;
    background-color: var(--color-150);

    @media (max-width: 768px) {
      padding: 1.2rem .5rem;
    }
  `,
  inputBlock: css`
    display: flex;
    align-items: flex-end;
    column-gap: 1rem;
    width: 100%;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      gap: 10px 0;
    }
  `,
  dateBlock: css`
    display: flex;
    flex-direction: column;
    row-gap: .5em;
  `,
  inputExp: css`
    width:  100%;
  `,
  phaseBlock: css`
    background-color: var(--color-150);
    border: 1px solid var(--primary-100);
    border-radius: 12px;
    overflow: hidden;
  `,
  addButton: css`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: .5rem;
    line-height: 1em;
    background-color: var(--primary);
    color: var(--color-100);
    border-radius: 6px;
    padding: .4em;
    min-width: 400px;
    transition: background-color .15s linear;

    @media (max-width: 768px) {
      min-width: 200px;
    }
    &:hover {
      background-color: var(--primary-100);
    }
  `
}