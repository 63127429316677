import styled from "@emotion/styled";
import { Icon } from "./Icon.styled";

interface IconBadgeProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  name: string;
  size?: string;
}

//
export const IconBadge = styled(({ name, size, ...props}: IconBadgeProps) => (
  <div {...props}>
    <Icon name={name} size={size} />
  </div>
))`
  background-color: var(--color-100);
  border: 1px solid var(--primary);
  padding: 1px;
  border-radius: 99px; 
`