import { css } from "@emotion/react"
import { Suspense, useEffect, useState } from "react"
import ErrorBoundary from "../../../lib/swr/ErrorBoundary"
import { CancelButton } from "../../../styles/styled/common/buttons/CancelButton.styled"
import { SmallText } from "../../../styles/styled/common/typography/Text.styled"
import UserSelectInput, { USER_DATA } from "./UserSelectInput"

//
interface P {
  name: string
  max?: number
  placeholder?: string
  //
  defaulValue?: USER_DATA[]
}

//
const UserMultiSelect: React.FC<P> = ({ name, max, placeholder, defaulValue }) => {
  //
  const [multiUser, setMultiUser] = useState<USER_DATA[]>(defaulValue || [])
  const [isMaximum, setIsMaximum] = useState(false)

  // ---
  useEffect(() => {
    if (defaulValue) {
      setMultiUser([ ...defaulValue ])
    }
  }, [defaulValue])

  // ---
  // ユーザー追加
  const selectUserHandle = (user: USER_DATA) => {
    if (!multiUser.some(u => u.id === user.id)) {
      setMultiUser([
        ...multiUser,
        user
      ])
      //
      if (max && max <= multiUser.length + 1) {
        setIsMaximum(true)
      }
    }
    // 空文字を返して入力をクリアする
    return ''
  }

  // ---
  // リストから削除
  const onDeleteHandle = (user: USER_DATA) => {
    //
    const newMultiUser = multiUser.filter(u => u.id !== user.id)
    //
    setIsMaximum(false)
    //
    setMultiUser(newMultiUser.concat())
  }

  //
  return (
    <div css={styles.container}>
      <ErrorBoundary>
        <Suspense>
          <UserSelectInput onSelectUser={selectUserHandle} placeholder={placeholder} disabled={isMaximum} />
        </Suspense>
      </ErrorBoundary>
      <input name={name} id={name} hidden value={multiUser.map(user => user.id).join(',')} readOnly />

      {multiUser.length > 0 &&
        <ul css={styles.userBlock}>
          {multiUser.map(user => {
            return (
              <li key={user.id} css={styles.userItem}>
                <span>{user.name}</span>
                <CancelButton onClick={() => onDeleteHandle(user)} size="1.2rem" />
              </li>
            )
          })}
        </ul>
      }
      {max && <SmallText>Selected: {multiUser.length}/{max}</SmallText>}
    </div>
  )
}

//
export default UserMultiSelect

// --- --- ---
// style
//
const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  `,
  userBlock: css`
    background-color: var(--color-150);
    border: 1px solid var(--primary-100);
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: .5rem;
    row-gap: .5rem;
  `,
  userItem: css`
    background-color: var(--color-100);
    border-radius: 99px;
    display: flex;
    align-items: center;
    column-gap: .2rem;
    padding: .3em .6em;
    > span {
      font-size: .92rem;
      line-height: 1em;
    }
  `
}