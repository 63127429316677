import { css } from "@emotion/react"
import { Icon } from "../../../../styles/styled/common/icons/Icon.styled"
import { EuroLabelText } from "../../../../styles/styled/common/typography/EuroText.styled"
import { Heading3 } from "../../../../styles/styled/common/typography/Heading.styled"
import { Paragraph } from "../../../../styles/styled/common/typography/Text.styled"
import { HorizontalBlock } from "../../../../styles/styled/layouts/HorizontalBlock.styled"

// ---
//
interface P {
  onCreate: () => void
}

// ---
//
const JiraHead: React.FC<P> = ({ onCreate }) => {
  return (
    <div css={styles.container}>
      <div css={styles.panel}>
        <Heading3>要件定義</Heading3>
        <Paragraph>要件定義のタスクには性能要件とビジネス要件も含まれます。要件定義のタスクには性能要件とビジネス要件も含まれます。要件定義のタスクには性能要件とビジネス要件も含まれます。</Paragraph>
      </div>

      <HorizontalBlock align="flex-end">
        <button css={styles.button} onClick={onCreate}>
          <Icon name="add_circle" size="18px" />
          <EuroLabelText small>Create a child</EuroLabelText>
        </button>
      </HorizontalBlock>
    </div>
  )
}

//
export default JiraHead

// --- --- ---
// style
//
const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
  `,
  panel: css`
    display: flex;
    flex-direction: column;
    row-gap: .6rem;
    background-color: var(--color-100);
    padding: 1.2rem 1.5rem;
    border-radius: 12px;
    width: 100%;
  `,
  button: css`
    display: flex;
    column-gap: 2px;
    align-items: center;
    padding: .3rem .4rem;
    border-radius: 6px;
    background-color: var(--color-100);
    transition: background-color .15s linear;
    &:hover {
      background-color: var(--primary-20);
    }
  `,
}