import useSWRImmutable from 'swr/immutable'


interface ProjectListCategoryLabelProps {
  categoryId: string | null
}


const ProjectListCategoryLabel: React.FC<ProjectListCategoryLabelProps> = ({ categoryId }) => {
  // TODO: いずれidからカテゴリ名を取得するAPIに変更する
  // カテゴリリストを取得
  const { data: categories } = useSWRImmutable('/category?limit=999', { suspense: true })

  const category = categories.data.find((cate: any) => cate.id === Number(categoryId))

  if (!categoryId || !category) {
    return <></>
  }

  return (
    <div>{category.name} Category</div>
  )
}

export default ProjectListCategoryLabel