import { useDroppable } from "@dnd-kit/core"
import { css } from "@emotion/react"

//
interface SpaceProps {
  spaceId: string
  path: string[]
  type: 'before' | 'after'
}

//
const DropSpacer: React.FC<SpaceProps> = ({ spaceId, path, type}) => {
  const {isOver, setNodeRef} = useDroppable({
    id: spaceId,
    data: {
      path: path,
      type: type
    }
  })
  
  //
  return (
    <div ref={setNodeRef} css={[styles.container, isOver ? styles.isOver : null]}>
      <hr css={[styles.line, isOver ? styles.lineOver : null]} />
    </div>
  )
}

//
export default DropSpacer

// --- --- ---
// style
//
const styles = {
  container: css`
    display: flex;
    align-items: center;
    z-index: 10;
    width: 100%;
    height: 20px;
    transition: background-color .15s linear, height .15s linear;
  `,
  isOver: css`
    height: 30px;
  `,
  line: css`
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    border: none;
    outline: none;
    transition: background-color .3s linear;
  `,
  lineOver: css`
    background-color: var(--primary-30);
  `
}