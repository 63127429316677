import { ListTabButton, ListTabContainer } from "../../../styles/styled/projects/ListTab.styled"

export type VALUE_TYPE = string | number | undefined
//
interface P {
  options: {
    label: string
    value: VALUE_TYPE
  }[]
  selected: VALUE_TYPE
  onSelect: (selectValue: VALUE_TYPE) => void
}

//
const ProjectListTab: React.FC<P> = ({ options, selected, onSelect }) => {

  //
  return (
    <ListTabContainer>
      {options.map((opt, index) => {
        return (
          <ListTabButton
            onClick={() => onSelect(opt.value)}
            key={`${opt.label}_${opt.value}_${index}`}
            $selected={selected === opt.value}
          >
            <span className=".list_tab_button-label">{opt.label}</span>
          </ListTabButton>
        )
      })}
    </ListTabContainer>
  )
}

//
export default ProjectListTab
