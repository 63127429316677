import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import useSWRImmutable from 'swr/immutable'
import tokenState from "../../state/Auth0"
import { AccountPhoto } from "../../styles/styled/common/AccountPhoto.styled"

//
const AccountLink: React.FC = () => {
  // トークン
  const authToken = useRecoilValue(tokenState)

  // ログインユーザー情報の取得
  const { data: account } = useSWRImmutable([ '/user/logging-in', authToken ], { suspense: true })

  return (
    <Link to="/account">
      <AccountPhoto src={account.userImageUrl} size={30} alt={account.name} />
    </Link>
  )
}

//
export default AccountLink