import styled from "@emotion/styled";
import { Component, ErrorInfo, ReactNode } from "react";
//
interface Props {
  children: ReactNode;
}
interface State {
  hasError: boolean;
  errorName: string;
  errorMessage: string;
}
//
class PageErrorBoundary extends Component<Props, State> {

  //
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorName: 'Error', errorMessage: 'エラーが発生しました' };
  }

  static getDerivedStateFromError(error: TypeError) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // エラー名とエラーの詳細がサーバーから送られているはずなのでステートにセット
    this.setState({
      errorName: error.name,
      errorMessage: error.message
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <PageErrorWrapper>
          <PageErrorTitle>{this.state.errorName}</PageErrorTitle>
          <p>{this.state.errorMessage}</p>
        </PageErrorWrapper>
      )
    }

    // エラーなし
    return this.props.children; 
  }
}

export default PageErrorBoundary

//
const PageErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  height: 80vh;
`
const PageErrorTitle = styled.h3`
`