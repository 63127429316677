import { css } from "@emotion/react"
import { ChangeEvent, DragEvent, MouseEvent, useCallback, useRef, useState } from "react"
import { CancelButton } from "../../../styles/styled/common/buttons/CancelButton.styled"

//
interface UploadHeroImageProps {
  name?: string;
  onChangeFile?: (file: File | undefined) => void;
  defaultValue?: string;
}

//
const UploadHeroImage: React.FC<UploadHeroImageProps> = ({ name, onChangeFile, defaultValue }) => {
  // ---
  //
  const inputRef = useRef<HTMLInputElement>(null)

  //
  const [previewImage, setPreviewImage] = useState(defaultValue || '')
  const [fileName, setFileName] = useState('')

  // ---
  //
  const createPreview = useCallback((file: File) => {
    if (onChangeFile) {
      onChangeFile(file)
    }
    setFileName(file.name)
    const fr = new FileReader();
    fr.onload = () => {
      if (fr.result) {
        setPreviewImage(fr.result.toString())
      }
    };
    fr.readAsDataURL(file);
  }, [onChangeFile])

  // ---
  //
  const onCancelHandle = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    //
    setPreviewImage('')
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    //
    if (onChangeFile) {
      onChangeFile(undefined)
    }
  }, [onChangeFile])

  // ---
  //
  const onFigureClick = useCallback((event: MouseEvent<HTMLElement>) => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [])

  //
  const onDropImage = useCallback((event: DragEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    //
    const files = event.dataTransfer.files
    //
    if (files.length > 0 && files[0].type.substring(0, 6) === 'image/') {
      createPreview(files[0])
    }
  }, [createPreview])

  // dropを有効にするためにオーバーの処理を止める
  const onDragOver = useCallback((event: DragEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  //
  const onChangeHandle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      createPreview(event.target.files[0])
    }
  }, [createPreview])

  // ---
  //
  return (
    <figure css={styles.figure} onClick={onFigureClick} onDrop={onDropImage} onDragOver={onDragOver}>
      {previewImage !== '' ?
        <>
          <img src={previewImage} css={styles.image} alt="hero preview" />
          <div css={styles.cancel}>
            <CancelButton onClick={onCancelHandle} size="1.2rem" />
          </div>
        </>
        :
        <figcaption css={styles.caption}>Select or Drag &amp; Drop a Image File</figcaption>
      }
      <input name={name} id={name} type="file" onChange={onChangeHandle} accept="image/*" ref={inputRef} hidden />
      <input name={`${name}_base64`} id={`${name}_base64`} type="text" value={previewImage} hidden readOnly />
      <input name={`${name}_name`} id={`${name}_name`} type="text" value={fileName} hidden readOnly />
      {/* バリデーション用 */}
    </figure>
  )
}

//
export default UploadHeroImage

// --- --- ---
// style
//
const styles = {
  container: css``,
  figure: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 50px;
    cursor: pointer;
    background-color: var(--color-150);
    border-radius: 6px;
  `,
  caption: css`
    font-size: .86rem;
  `,
  image: css`
    position: relative;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  `,
  cancel: css`
    position: absolute;
    z-index: 10;
    top: -.5rem;
    right: -.5rem;
    background-color: var(--color-100);
    border-radius: 99px;
    padding: 1px;
  `
}