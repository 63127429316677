import { DraggableAttributes } from "@dnd-kit/core"
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities"
import { css } from "@emotion/react"
import { useState } from "react"
import { Icon } from "../../../../styles/styled/common/icons/Icon.styled"
import { Heading4 } from "../../../../styles/styled/common/typography/Heading.styled"
import ToolsMenu from "./ToolsMenu"

//
interface P {
  label: string
  isDrag: boolean
  dropRef: (element: HTMLElement | null) => void
  listeners: SyntheticListenerMap | undefined
  attributes: DraggableAttributes
  onCreate: () => void
  onDelete: () => void
}

//
const JiraNodeBlockHead: React.FC<P> = ({ label, isDrag, dropRef, listeners, attributes, onCreate, onDelete }) => {
  //
  const [toolMenu, setToolMenu] = useState(false)

  //
  const onCreateHandle = () => {
    setToolMenu(false)
    onCreate()
  }
  //
  const onDeleteHandle = () => {
    setToolMenu(false)
    onDelete()
  }

  // ---
  //
  return (
    <div ref={dropRef} css={styles.container}>
      <div css={[styles.dragLabel, isDrag ? styles.dragActive : null]} {...listeners} {...attributes}>
        <Heading4 css={styles.label}>{label}</Heading4>
      </div>

      <div>
        <button onClick={() => setToolMenu(true)}>
          <Icon name="more_horiz" />
        </button>
        
        {/* tooltip menu */}
        {toolMenu &&
          <ToolsMenu
            onCreate={onCreateHandle}
            onDelete={onDeleteHandle}
          />
        }
      </div>

      {/* ツールメニューを開いた時の他のクリックエアリ用 */}
      {toolMenu && <div css={styles.toolMenuBack} onClick={() => setToolMenu(false)}></div>}
    </div>
  )
}

//
export default JiraNodeBlockHead

// --- --- ---
// style
//
const styles = {
  container: css`
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding-right: 10px;
    border-radius: 4px;
  `,
  dragLabel: css`
    cursor: grab;
    width: 100%;
    padding: 12px;
  `,
  dragActive: css`
    cursor: grabbing;
  `,
  label: css`
    pointer-events: none;
  `,
  toolMenuBack: css`
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  `
}