import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { AccountPhoto } from "../common/AccountPhoto.styled";
import { IconBadge } from "../common/icons/IconBadge.styled";
import { IconText } from "../common/icons/IconText.styled";
import { Heading4 } from "../common/typography/Heading.styled";

/**
 * アカウントカードを並べるラッパー
 */
export const AccountCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0 1rem;
  }
`

// ---
// 共通スタイル
const cardStyle = css`  
  width: 100%;
  display: flex;
  border-radius: 12px;
  padding: 1rem .4rem .8rem .6rem;
`
/**
 * アカウントカード（静的）
 */
export const AccountCardPanel = styled.div`
  ${cardStyle};
`
/**
 * リンク可能なアカウントカード
 */
export const AccountCardLinkPanel = styled(Link)`
  ${cardStyle};

  border: 1px solid ${({theme}) => theme.alphaColor('#000', 0)};
  background-color: var(--color-100);
  transition: all .25s ease-in-out;

  @media (max-width: 768px) {
    padding: 1rem 0;
    overflow: hidden;
  }

  &:hover {
    border-color: ${({theme}) => theme.alphaColor('#000', 0.1)};
    background-color: var(--color-150);
    box-shadow: 0 4px 8px var(--shadow-100);
    transform: translateY(-2px);
  }
`

interface AccountCardContentProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  photoSrc: string;
  name: string;
  description: string;
  count: string;
}
/**
 * アカウントカードのコンテンツ
 */
export const AccountCardContent = styled(({ photoSrc, name, description, count, children, ...props}: AccountCardContentProps) => (
  <div {...props}>
    <AccountCardPhoto src={photoSrc} />
    <AccountCardBody name={name} description={description} count={count}>
      {children}
    </AccountCardBody>
  </div>
))`
  display: contents;
`

// --- --- ---
//
interface AccountCardPhotoProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  src: string;
}
//
const AccountCardPhoto = styled(({src, ...props}: AccountCardPhotoProps) => (
  <div {...props}>
    <AccountPhoto src={src} />
    <IconBadge name="done" size="1rem" className="account-card_photo_badge" />
  </div>
))`
  position: relative;
  display: block;
  width: 80px;
  height: 80px;
  flex: 0 0 80px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }
  
  .account-card_photo_badge {
    position: absolute;
    bottom: 2px;
    right: 2px;
  }
`

//
interface AccountCardBodyProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  name: string;
  description: string;
  count: string;
}
const AccountCardBody = styled(({ name, description, count, children, ...props}: AccountCardBodyProps) => (
  <div {...props}>
    <Heading4>{name}</Heading4>
    <p className="account-card_body_description">{description}</p>
    <div className="account-card_body_bottom">
      <IconText name="rocket_launch" size="1.2rem">{count}</IconText>
      {children}
    </div>
  </div>
))`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .account-card_body_description {
    font-size: .9rem;
    margin-top: .5rem;
    white-space: pre-wrap;
  }

  .account-card_body_bottom {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--primary-100);
    margin-top: .5em;
    column-gap: 1rem;
  }
`