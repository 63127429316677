//
export const swrFetcher = async (url: string, token: string, init: RequestInit | undefined) => {
  //
  // console.log(init)
  const apiPath = process.env.REACT_APP_API_PATH!

  // TODO: tokenの有無を確認した方が良い？
  // ---
  let options = init
  if (token) {
    options = {
      ...init,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  }

  //
  const res = await fetch(apiPath + url, options)
  const json = await res.json()

  // エラー時
  if (!res.ok) {
    // エラーレスポンスをエラーとして投げる
    const err = new Error(json.detail || res.statusText)
    err.name = json.message || res.status.toString()
    throw err
  }

  // Swaggerとサーバーとの違いをとりあえず回避
  if (json.Body) {
    return json.Body
  }
  return json
}
