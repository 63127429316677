import { useAuth0 } from '@auth0/auth0-react'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { useSWRConfig } from 'swr'
import PageErrorBoundary from '../../lib/swr/errors/PageErrorBoundary'
import tokenState from '../../state/Auth0'
import { LogoBlock } from '../../styles/styled/common/LogoBlock.styled'
import FooterComp from '../../styles/styled/layouts/FooterComp.styled'
import { HeaderComp } from '../../styles/styled/layouts/HeaderComp.styled'
import { PageLoadingFallback } from '../../styles/styled/suspense/LoadingFallback'
import SearchInput from '../common/forms/SearchInput'
import getUserMetadata from './Auth0GetUserMetadata'
import HeaderAccount from './HeaderAccount'

//
const apiPath = process.env.REACT_APP_API_PATH!

const BaseLayout: React.FC = () => {
  //
  const { getAccessTokenSilently, isAuthenticated, isLoading, getAccessTokenWithPopup, user: Auth0User } = useAuth0()
  const [isUserCheck, setIsUserCheck] = useState(false)
  // Auth0のトークンを保持する用
  const setToken = useSetRecoilState(tokenState)
  // ログインユーザー情報の取得
  const { mutate } = useSWRConfig()
  //
  const navigate = useNavigate()
  const location = useLocation()

  // Kickoff側のユーザーが作成済みかチェックして、未作成の場合は作成する
  const checkCreateUser = useCallback(async (token: string) => {
    //
    const res = await fetch(`${apiPath}/user/logging-in`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    
    //
    if (res.ok) {
      // ユーザー作成済みなのswrのキャッシュを更新する
      const user = await res.json()
      mutate('/user/logging-in', user)
      return false
    }
    // エラー
    if (res.status === 400) {
      // BadRequest
      return false
    }

    // auth0からユーザー情報を取得
    const auth0UserMeta = await getUserMetadata(Auth0User, getAccessTokenSilently, getAccessTokenWithPopup)

    // 仮のユーザーを作成
    const createRes = await fetch(`${apiPath}/user`, {
      method: 'POST',
      body: JSON.stringify({
        name: (auth0UserMeta && auth0UserMeta.nickname)|| 'New User',
        emailAddress: (auth0UserMeta && auth0UserMeta.email) || '',
      }),
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    //
    if (createRes.ok) {
      const user = await createRes.json()
      // ユーザー作成成功したのでキャッシュを更新
      mutate('/user/logging-in', user)
      return true
    }
    // 作成失敗
    return false
  }, [Auth0User, getAccessTokenSilently, getAccessTokenWithPopup, mutate])

  //
  useEffect(() => {
    const getToken = async () => {  
      try {
        const accessToken = await getAccessTokenSilently({})
        // logging-inのチェックと必要ならユーザー作成
        const isCreateUser = await checkCreateUser(accessToken)
        //
        setToken(accessToken)
        // Authチェックの完了
        if (isCreateUser) {
          // 仮のユーザーを作ったので、アカウントページへリダイレクトしておく
          navigate('/account/setting')
        } else {
          setIsUserCheck(true)
        }
    } catch (e) {
        console.error(e)
      }
    }
    //
    if (isAuthenticated) {
      // トークンの取得
      getToken()
    }
  }, [checkCreateUser, getAccessTokenSilently, isAuthenticated, navigate, setToken])

  // ---
  // ローディングとAuthのチェック待ち
  if (isLoading || (isAuthenticated && !isUserCheck)) {
    return <></>
  }

  // ---
  //
  return (
    <div>
      <HeaderComp>
        <LogoBlock to="/" />
        <div className='header-middle'>
          <SearchInput />
        </div>
        <Suspense>
          <HeaderAccount />
        </Suspense>
      </HeaderComp>

      {/* メインコンテンツ */}
      <main>
        <PageErrorBoundary key={location.pathname}>
          <Suspense fallback={<PageLoadingFallback />}>
            <Outlet />
          </Suspense>
        </PageErrorBoundary>
      </main>

      {/* フッター */}
      <FooterComp />
    </div>
  )
}

//
export default BaseLayout