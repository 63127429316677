// styled
import { AccountPhoto } from "../../../../styles/styled/common/AccountPhoto.styled"
import { SmallText } from "../../../../styles/styled/common/typography/Text.styled"
import { HorizontalBlock } from "../../../../styles/styled/layouts/HorizontalBlock.styled"

//
interface P {
  account: {
    userId: number
    userName: string
    userImageUrl: string
  }
}

//
const ProjectActivitiesAccount: React.FC<P> = ({ account }) => {
  return (
    <HorizontalBlock gap=".4rem">
      <AccountPhoto src={account.userImageUrl} size={24} />
      <SmallText>{account.userName}</SmallText>
    </HorizontalBlock>
  )
}

//
export default ProjectActivitiesAccount