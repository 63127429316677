import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ListTabContainer = styled.div`
  display: flex;
  column-gap: 20px;
  
  @media (max-width: 768px) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

`

interface ListTabButtonProps {
  $selected: boolean;
}
export const ListTabButton = styled.button<ListTabButtonProps>`
  width: 210px;
  padding: 10px 0;
  border-bottom: 4px solid var(--color-300);
  .list_tab_button-label {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    width: auto;
  }

  ${({$selected}) => $selected && css`border-color: var(--primary);` }
`