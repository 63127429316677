import { css } from "@emotion/react"
import React, { useCallback } from "react";
import { FormLabel } from "../../../styles/styled/common/form/FormLayout.styled";
import { InputText } from "../../../styles/styled/common/form/Input.styled";

//
interface CategoryOptionsProps {
  name: string;
  options: {
    id: string
    label: string
  }[];
  //
  selected?: string;
  onSelect?: (selectId: string) => void;
  required?: boolean;
}

//
const CategoryOptions: React.FC<CategoryOptionsProps> = ({ name, options, selected, onSelect, required }) => {
  //
  const onSelectHandle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelect) {
      onSelect(event.target.value)
    }
  }, [onSelect])

  //
  return (
    <ul css={styles.container}>
      {options.map(opt => {
        return (
          <li key={opt.id} css={styles.item}>
            <InputText
              name={name}
              type="radio"
              id={`${name}-radio-${opt.id}`}
              value={opt.id}
              onChange={onSelectHandle}
              defaultChecked={selected ? selected === opt.id : false}
              required={required}
            />
            <FormLabel htmlFor={`${name}-radio-${opt.id}`}>
              {opt.label}
            </FormLabel>
          </li>
        )
      })}
    </ul>
  )
}

//
export default CategoryOptions

// --- --- ---
// style
//
const styles = {
  container: css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 1.2rem;
    row-gap: .5rem;
  `,
  item: css`
    display: flex;
    align-items: center;
    column-gap: .3rem;
    cursor: pointer;
    > * {
      cursor: pointer;
    }
  `
}