import AccountDangerZone from "../../components/elements/account/setting/AccountDangerZone"
import { MainWrapper } from "../../styles/styled/layouts/MainWrapper.styled"
import { EuroHeading2 } from "../../styles/styled/common/typography/EuroText.styled"
import { Suspense, useCallback, useState } from "react"
import ErrorBoundary from "../../lib/swr/ErrorBoundary"
import { useAuth0 } from "@auth0/auth0-react"
import AccountSettingForm from "../../components/elements/account/setting/AccountSettingForm"
import ModalPortal from "../../components/elements/modal/ModalPortal"
import { LoadingWrapper } from "../../styles/styled/common/LoadingPanel"


//
const envCallback = process.env.REACT_APP_AUTH0_CALLBACK || '/'

//
const SettingIndex: React.FC = () => {
  //
  const { logout } = useAuth0()
  //
  const [isSubmitting, setIsSubmitting] = useState(false)

  // 削除完了時はトップへ
  const deleteComplete = useCallback(() => {
    // Auth0からログアウトして、トップへ
    logout({returnTo: envCallback})
  }, [logout])

  //
  return (
    <MainWrapper center column>
      <EuroHeading2>Account Setting</EuroHeading2>
      <ErrorBoundary>
        <Suspense fallback={<Suspense />}>
          <AccountSettingForm setLoadingState={setIsSubmitting} />
        </Suspense>
      </ErrorBoundary>

      {/* 削除エリア */}
      <ErrorBoundary>
        <Suspense fallback={<Suspense />}>
          <AccountDangerZone onDeleteComplete={deleteComplete}/>
        </Suspense>
      </ErrorBoundary>

      {/** ローディング */}
      {isSubmitting && <ModalPortal children={<LoadingWrapper />} />}
    </MainWrapper>
  )
}

//
export default SettingIndex
