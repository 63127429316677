import { css } from '@emotion/react'
import { ChangeEvent, KeyboardEvent, useState } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"
import { Icon } from '../../../styles/styled/common/icons/Icon.styled'
import { alphaColor } from "../../../styles/variables"

//
const SearchInput: React.FC = () => {
  //
  const [inputValue, setInputValue] = useState('')
  const navigate = useNavigate()
  // スタイル用
  const [isFocus, setIsFocus] = useState(false)

  //
  const submitSearch = () => {
    if (inputValue.length > 0) {
      navigate({
        pathname: "/projects",
        search: createSearchParams({
            keyword: inputValue,
        }).toString()
    });
    } else {
      // 検索画面へ
      navigate(`/projects`)
    }
    setInputValue('')
  }

  //
  const keyDownHandle = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.nativeEvent.isComposing || event.key !== 'Enter') return
    // 入力決定
    submitSearch()
  }

  //
  const onChangeHandle = (event: ChangeEvent<HTMLInputElement>) => {
    //
    setInputValue(event.target.value)
  }

  //
  return (
    <div css={[styles.container, isFocus ? styles.containerFocus : null]}>
      <input
        css={[styles.input]}
        type="text"
        placeholder="Search Projects"
        onKeyDown={keyDownHandle}
        onChange={onChangeHandle}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        value={inputValue}
      />
      <button type="button" onClick={submitSearch} css={styles.searchButton}>
        <Icon name="search" dark />
      </button>
    </div>
  )
}

//
export default SearchInput

// --- --- ---
// style
//
const styles = {
  container: css`
    width: 100%;
    max-width: 600px;
    display: flex;
    align-items: center;
    border: 1px solid ${alphaColor('#fff', 0.25)};
    border-radius: 99px;
    background-color: ${alphaColor('#000', 0)};
    padding: .2rem;
    transition: all .2s ease-out;
  `,
  containerFocus: css`
    border-color: ${alphaColor('#fff', 0)};
    background-color: ${alphaColor('#000', 0.25)};
    box-shadow: 0px 2px 5px var(--shadow-100);
  `,
  input: css`
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    color: var(--color-100);
    font-size: 1rem;
    font-weight: normal;
    padding: 0 1rem;
    &::placeholder {
      color: var(--color-200);
      font-weight: normal;
      font-size: .8em;
    }
  `,
  searchButton: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 2rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: ${alphaColor('#fff', 0)};
    transition: background-color .15s linear;
    &:hover {
      background-color: ${alphaColor('#fff', 0.1)};
    }
  `
}