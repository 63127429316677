import styled from "@emotion/styled";
import { Heading4 } from "../typography/Heading.styled";
import { Paragraph, SmallText } from "../typography/Text.styled";

//
const ResponseErrorTitle = styled(Heading4)`
  color: var(--danger);
  text-align: center;
`

//
const ResponseErrorStatus = styled(SmallText)`
  color: var(--danger);
  text-align: center;
`

//
const ResponseErrorMessage = styled(Paragraph)`
  color: var(--danger);
  text-align: center;
  white-space: pre-wrap;
`

//
interface ResponseErrorProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
 title: string;
 status?: string;
 message?: string;
}
/**
 * 
 */
export const ResponseError = styled(({ title, status, message, ...props }: ResponseErrorProps) => (
  <div {...props}>
    <ResponseErrorTitle>{title}</ResponseErrorTitle>
    {status && <ResponseErrorStatus>STATUS: {status}</ResponseErrorStatus>}
    {message && <ResponseErrorMessage>{message}</ResponseErrorMessage>}
  </div>
))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.4rem;
  width: 100%;
  border: 1px solid var(--danger);
  border-radius: 8px;
  padding: 1rem;
`