import { Dispatch, useCallback, useState } from "react"
import { useRecoilValue } from "recoil";
import { DangerZone } from "../../../../styles/styled/common/blocks/DangerZone.styled"
import ModalPortal from "../../modal/ModalPortal"
import AccountDeleteConfirm from "./AccountDeleteConfirm"
import useSWRImmutable from 'swr/immutable'
import tokenState from "../../../../state/Auth0";

//
const apiPath = process.env.REACT_APP_API_PATH!

//
interface P {
  onDeleteComplete: Dispatch<void>;
}
//
const AccountDangerZone: React.FC<P> = ({ onDeleteComplete }) => {
  // トークン
  const authToken = useRecoilValue(tokenState)
  // ログインユーザーの情報を取得
  const { data: account } = useSWRImmutable([ '/user/logging-in', authToken ], { suspense: true })
  //
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  // モーダルを開く
  const onClickHandle = () => {
    setIsDeleteModal(true)
  }

  // 削除確認モーダルのキャンセル
  const onCancelHandle = () => {
    setIsDeleteModal(false)
  }

  // ---
  // ユーザーの削除
  //
  const onDeleteHandle = useCallback(async () => {
    const res = await fetch(`${apiPath}/user/${account.id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    if (res.ok && res.status === 204) {
      // リクエストが成功、削除された
      setIsDeleteModal(false)
      onDeleteComplete()
      return false
    }
    setIsDeleteModal(false)
    return false
  }, [account.id, authToken, onDeleteComplete])

  //
  return (
    <>
      <DangerZone onDangerClick={onClickHandle}>Delete this account</DangerZone>

      {/* 削除確認モーダル */}
      {isDeleteModal &&
        <ModalPortal children={
          <AccountDeleteConfirm onCancel={onCancelHandle} onDelete={onDeleteHandle} />
        } />
      }
    </>
  )
}

//
export default AccountDangerZone
