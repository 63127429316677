import { ReactNode } from "react"
import { createPortal } from "react-dom"
import { ModalPortalWrapper } from "../../../styles/styled/modal/ModalPortal.styled"

//
interface P {
  children: ReactNode
}

//
const ModalPortal: React.FC<P> = ({ children }) => {
  //
  if (typeof window === 'object') {
    return createPortal(<ModalPortalWrapper>{children}</ModalPortalWrapper>, document.getElementById('modal-portal')!)
  }

  return <></>
}

//
export default ModalPortal
