import { EuroHeading2 } from "../styles/styled/common/typography/EuroText.styled"
import { SmallText } from "../styles/styled/common/typography/Text.styled"
import { MainWrapper } from "../styles/styled/layouts/MainWrapper.styled"

//
const NotFound: React.FC = () => {
  return (
    <MainWrapper center style={{ rowGap: '2rem'}}>
      <EuroHeading2>404</EuroHeading2>
      <SmallText>Page Not Found</SmallText>
    </MainWrapper>
  )
}

//
export default NotFound