import { Suspense, useContext } from "react"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import useSWR from "swr"
import ProjectActivities from "../../../components/elements/projects/activities/ProjectActivities"
import CollaboratorContext from "../../../components/elements/projects/detail/CollaboratorContext"
import ProjectDetailHead from "../../../components/elements/projects/detail/ProjectDetailHead"
import ProjectHero from "../../../components/elements/projects/detail/ProjectHero"
import ProjectPrivateBlock from "../../../components/elements/projects/detail/ProjectPrivateBlock"
import ProjectSummary from "../../../components/elements/projects/detail/ProjectSummary"
import tokenState from "../../../state/Auth0"
import { MainWrapper } from "../../../styles/styled/layouts/MainWrapper.styled"

/**
 * プロジェクト詳細のTOP
 */
const ProjectDetail = () => {
  //
  const { projectId } = useParams()
  // 当該ユーザーがコラボレーターに含まれているかのコンテキスト
  const isCollaborator = useContext(CollaboratorContext)
  //
  const authToken = useRecoilValue(tokenState)
  //
  const { data: projectData } = useSWR([ `/project/${projectId}`, authToken], { suspense: true })

  if (!projectId) {
    return (
      <></>
    )
  }

  //
  return (
    <>
      {/* */}
      <ProjectHero data={projectData} />

      <Suspense>
        <ProjectDetailHead projectId={projectId} />
      </Suspense>

      {/* */}
      <MainWrapper expand primary>
        {(projectData.isPrivate && isCollaborator !== 2) ?
          <ProjectPrivateBlock projectId={projectId} />
          :
          <>
            <ProjectSummary projectId={projectId} />
            <Suspense>
              <ProjectActivities />
            </Suspense>
          </>
        }
      </MainWrapper>
    </>
  )
}

//
export default ProjectDetail