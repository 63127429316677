import { Suspense, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ModalPortal from '../../../components/elements/modal/ModalPortal'
import CollaboratorContext from '../../../components/elements/projects/detail/CollaboratorContext'
import ProjectDangerZone from '../../../components/elements/projects/setting/ProjectDangerZone'
import ProjectEditForm from '../../../components/elements/projects/setting/ProjectEditForm'
import { LoadingWrapper } from '../../../styles/styled/common/LoadingPanel'
import { EuroHeading3 } from '../../../styles/styled/common/typography/EuroText.styled'
import { MainWrapper } from '../../../styles/styled/layouts/MainWrapper.styled'

//
const ProjectSetting: React.FC = () => {
  
  // 当該ユーザーがコラボレーター（オーナーや役職含む）に含まれているかのコンテキスト
  const isCollaborator = useContext(CollaboratorContext)
  const navigator = useNavigate()
  //
  const { projectId } = useParams()
  //
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onComplete = useCallback(() => {
    // プロジェクトトップへ
    navigator(`/projects/${projectId}`)
  }, [navigator, projectId])

  // 直リンク対応（ルーター側へ移設した方が良いかも）
  useEffect(() => {
    if (isCollaborator !== 2) {
      // コラボレーターでは無いので、プロジェクトトップへ
      navigator(`/projects/${projectId}`)
    }
  }, [isCollaborator, navigator, projectId])

  // TODO: プロジェクト削除APIがまだ未定義
  const deleteHandler = useCallback(() => {
    //
  }, [])


  // ---
  //
  return (
    <MainWrapper center column as="div">
      <EuroHeading3>Project Setting</EuroHeading3>

      {/* */}
      {projectId &&
        <Suspense>
          <ProjectEditForm editId={projectId} setLoadingState={setIsSubmitting} onEditComplete={onComplete} />
        </Suspense>
      }

      {/* */}
      <ProjectDangerZone onDelete={deleteHandler} />

      {/** */}
      {isSubmitting && <ModalPortal children={<LoadingWrapper />} />}
    </MainWrapper>
  )
}

//
export default ProjectSetting
