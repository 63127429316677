import { useAuth0 } from "@auth0/auth0-react"
import { Suspense } from "react"
import ErrorBoundary from "../../lib/swr/ErrorBoundary"
import { BasicButton } from "../../styles/styled/common/buttons/BasicButton.styled"
import { IconLink } from "../../styles/styled/common/buttons/IconLink.styled"
import { TextButton } from "../../styles/styled/common/buttons/TextButton.styled"
import { HorizontalBlock } from "../../styles/styled/layouts/HorizontalBlock.styled"
import AccountLink from "./AccountLink"

const HeaderAccount: React.FC = () => {
  //
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  // ---
  // サインイン
  const signClickHandle = () => {
    loginWithRedirect()
  }

  // サインアップ
  const signupClickHandle = () => {
    loginWithRedirect({
      screen_hint: 'signup'
    })
  }

  // --- --- ---
  // Auth0のローディング
  if (isLoading) {
    return <div>
      <span>···</span>
    </div>
  }

  // ログイン前
  if (!isAuthenticated) {
    return (
      <HorizontalBlock>
        <TextButton color="white" size="small" onClick={signupClickHandle}>Sign Up</TextButton>
        <BasicButton onClick={signClickHandle}>Sign In</BasicButton>
      </HorizontalBlock>
    )
  }

  // ログイン後
  return (
    <HorizontalBlock>
      <ErrorBoundary>
        <Suspense>
          <AccountLink />
        </Suspense>
      </ErrorBoundary>
      <IconLink name="settings" to="/account/setting" dark />
    </HorizontalBlock>
  )
}

// ---
//
//
export default HeaderAccount