import styled from "@emotion/styled";
import { BasicButton } from "../buttons/BasicButton.styled";
import { EuroHeading5 } from "../typography/EuroText.styled";

//
interface DangerZoneProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  onDangerClick: React.MouseEventHandler<HTMLButtonElement>
}

//
export const DangerZone = styled(({onDangerClick, children, ...props}: DangerZoneProps) => (
  <div {...props}>
    <div className="danger_zone-label">
      <EuroHeading5>{children}</EuroHeading5>
    </div>
    <BasicButton onClick={onDangerClick} color="danger" size="middle">Delete</BasicButton>
  </div>
))`
  display: flex;
  align-items: center;
  width: 800px;
  border: 1px solid var(--danger);
  background-color: var(--danger-base);
  padding: 2rem;
  border-radius: 12px;
  margin-top: 3rem;

  @media (max-width: 768px) {
    width: 100%;
  }

  .danger_zone-label {
    margin-right: auto;
  }
`