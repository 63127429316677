import styled from "@emotion/styled";

export const DetailHero = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme.alphaColor('#000', 0.2)};

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`

export const DetailHeroCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 30px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px;
  }
`

export const DetailHeroCardContents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: fit-content;
  max-width: 60%;
  padding: 15px;
  background-color: ${({theme}) => theme.alphaColor('#fff', 0.8)};
  backdrop-filter: blur(2px);
  border-radius: 12px;

  @media (max-width: 768px) {
    max-width: 90%;
    align-self: center;
  }
`