import { css } from "@emotion/react"
import { useContext, useState } from "react"
import { AccountPhoto } from "../../../../styles/styled/common/AccountPhoto.styled"
import { EuroHeading4, EuroHeading5 } from "../../../../styles/styled/common/typography/EuroText.styled"
import { Paragraph, SmallText } from "../../../../styles/styled/common/typography/Text.styled"
import { HorizontalBlock } from "../../../../styles/styled/layouts/HorizontalBlock.styled"
import { StyledDetailSection, StyledDetailSectionContent, StyledDetailSectionHead } from "../../../styled/projects"
import ApplyCollaborationButton from "./ApplyCollaborationButton"
import CollaboratorContext from "./CollaboratorContext"

interface ProjectSummaryProps {
  projectId: string;
}


const ProjectSummary: React.FC<ProjectSummaryProps> = ({ projectId }) => {
  // 当該ユーザーがコラボレーターに含まれているかのコンテキスト
  const isCollaborator = useContext(CollaboratorContext)
  // 楽観的更新用
  const [isApply, setIsApply] = useState(false)
  //
  return (
    <StyledDetailSection>

      <StyledDetailSectionHead>
        <EuroHeading4>Project Summary</EuroHeading4>
        <SmallText style={{ marginRight: 'auto'}}>last update: 3day</SmallText>
        {/* コラボレーター申請など */}
        {(!isApply && isCollaborator === 0) && 
          <ApplyCollaborationButton projectId={projectId} onApply={() => setIsApply(true)} />
        }
        {(isApply || isCollaborator === 1) &&
          <SmallText>コラボレーター申請中</SmallText>
        }
        {(!isApply && isCollaborator === 99) &&
          <SmallText>コラボレーター申請が却下されました</SmallText>
        }
      </StyledDetailSectionHead>

      <StyledDetailSectionContent>
        <HorizontalBlock gap="1rem">
          <AccountPhoto size={60} />
          <div>
            <EuroHeading5 style={{ marginBottom: '.2rem' }}>Project Owner</EuroHeading5>
            <span>@marumaru-city-dx-group</span>
          </div>
        </HorizontalBlock>
        <div css={styles.description}>
          <Paragraph>
            ●●町は、山陰の山合に位置する人口9,234人の小さな町です。高齢者の人口が高く、町役場の業務のほとんどは対面や訪問の形で行っています。<br/>
            しかし、感染症の蔓延に伴い、対人での業務遂行が困難になってしまいました。国の助成金などを使って予算を確保しながら、これまで対面が前提となっていた業務を非対面に移行し、職員の感染症リスク低減と町民の利便性向上を同時に実現したいと思っています。<br/>
            <br/>
            是非、お力添えの程よろしくお願い申し上げます。
          </Paragraph>
        </div>
      </StyledDetailSectionContent>
    </StyledDetailSection>
  )
}

//
export default ProjectSummary

// --- --- ---
// style
//
const styles = {
  description: css`
    position: relative;
    background-color: var(--color-100);
    border: 1px solid var(--color-200);
    border-radius: 12px;
    margin-top: 1rem;
    padding: 1.2rem 2rem;

    @media (max-width: 768px) {
      padding: 1.2rem .5rem;
    }
  `
}