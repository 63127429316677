import styled from "@emotion/styled";

/**
 * .bg-imageを指定した子をcover表示し、背景として絶対位置に配置します
 */
export const BackImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  > .bg-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`