import { Dispatch, SetStateAction, useEffect } from 'react'
import { Key } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { AccountCardWrapper } from '../../../../styles/styled/account/AccountCard.styled'
import AccountCard from "./AccountCard"

//
interface AccountListProps {
  swrKey: Key;
  setTotalCount?: Dispatch<SetStateAction<number>>;
}
//
const AccountList: React.FC<AccountListProps> = ({ swrKey, setTotalCount }) => {
  //
  const { data: collaborators } = useSWRImmutable(swrKey, { suspense: true })

  //
  useEffect(() => {
    if (setTotalCount && collaborators.count) {
      setTotalCount(collaborators.count)
    }
  }, [collaborators.count, setTotalCount])
  
  if (collaborators.data.length === 0) {
    return (
      <AccountCardWrapper>
        <span>コラボレーターが見つかりませんでした。</span>
      </AccountCardWrapper>
    )
  }

  //
  return (
    <AccountCardWrapper>
      {collaborators.data.map((col: any) => <AccountCard data={col} key={col.id} />)}
    </AccountCardWrapper>
  )
}

//
export default AccountList
