import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { Icon } from "../../../../styles/styled/common/icons/Icon.styled"
import { IconText } from "../../../../styles/styled/common/icons/IconText.styled"
import { ProductIcon } from "../../../../styles/styled/common/icons/ProductIcon.styled"
import BreakLine from "../../../common/utls/BreakLIne"
import styles from "./ProjectCard.styles"

interface P {
  data: any
}

//
const ProjectCard: React.FC<P> = ({ data }) => {
  return (
    <Link to={`/projects/${data.id}`} css={styles.container}>
      <div css={styles.category}>
        <span>{data.categoryName}</span>
      </div>

      {/** TODO: プロジェクト内の未読通知 */}
      {0 > 0 &&
        <div css={styles.noticeCount}>
          <span>0</span>
        </div>
      }

      <div css={styles.image} className="_project-card-hero">
        {data.isPrivate === true &&
          <div css={styles.lock}><Icon name="lock" size="1.2rem" /></div>
        }
        {data.heroImageUrl && 
          <figure>
            <img src={data.heroImageUrl} alt={data.name} onError={(event) => {
              if (event.currentTarget) {
                event.currentTarget.setAttribute('data-error', 'true')
              }
            }} />
          </figure>
        }
      </div>

      <div css={styles.contents}>
        <h4>{data.name}</h4>
        <BreakLine paragraph={data.description} shrink />
      </div>

      <ul css={styles.stats}>
        <li>
          <IconText name="favorite" size="1.2rem">{data.favoriteCount}</IconText>
        </li>
        <li>
          <IconText name="forum" size="1.2rem">{data.commentCount}</IconText>
        </li>
        <li>
          <IconText name="hub" size="1.2rem">{data.collaborationCount}</IconText>
        </li>
        <li>
          <IconText name="person_search" size="1.2rem">{data.applicantCount}</IconText>
        </li>
      </ul>

      <div css={styles.bottom}>
        <span>Updated: {dayjs(data.modifiedDatetime).format('YYYY/MM/DD')}</span>
        <div>
          {data.integrateSaasList && data.integrateSaasList.map((saas: any, index: number) => {
            if (saas.name) {
              return <ProductIcon name={saas.name.toLowerCase()} key={saas.name} />
            }
            return null
          })}
        </div>
      </div>
    </Link>
  )
}

//
export default ProjectCard
