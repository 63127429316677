import { css } from "@emotion/react"
import styled from "@emotion/styled"

// ---
interface MainWrapperProps {
  center?: boolean;
  expand?: boolean;
  column?: boolean;
  primary?: boolean;
}

/**
 * メインコンテンツ用のコンテナ
 * @param center ボックスの中心レイアウトに変更します
 */
export const MainWrapper = styled.main<MainWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  margin: 0 auto;
  padding: 3rem;

  width: ${({theme, expand}) => expand ? '100%' : theme.content.maxWidth};
  ${({column}) => column && css`row-gap: 2rem;`}
  max-width: 100%;

  ${({primary}) => primary && css`background-color: var(--primary-10)`};

  ${({ center }) => center && css`
    align-items: center;
    justify-content: center;
  `}

  @media (max-width:768px) {
    width: ${({theme, expand}) => expand ? '100%' : theme.spContent.maxWidth};
    padding: 3rem 1rem;
  }
`