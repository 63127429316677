import styled from "@emotion/styled";
import { Icon } from "../icons/Icon.styled";

//
interface CancelButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: string;
}
export const CancelButton = styled(({size, ...props}: CancelButtonProps) => (
  <button {...props}>
    <Icon name="cancel" size={size} />
  </button>
))`
  display: block;
  color: var(--color-400);
  transition: color .15s linear;
  &:hover {
    color: var(--danger);
  }
`

// デフォルトタイプ
CancelButton.defaultProps = {
  type: 'button'
}