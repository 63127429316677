import { css } from "@emotion/react"
import { useState } from "react"
import { Icon } from "../../../styles/styled/common/icons/Icon.styled";
import { EuroHeading4 } from "../../../styles/styled/common/typography/EuroText.styled";

//
interface P {
  name: string;
  defaultChecked?: boolean;
}

//
const ProjectVisibilityCheck: React.FC<P> = ({ name, defaultChecked = false }) => {
  //
  const [visibility, setVisibility] = useState(defaultChecked)

  //
  const onClickHandle = () => {
    setVisibility(!visibility)
  }

  //
  return (
    <div css={[styles.container, visibility ? styles.containerActive : null ]}>
      {visibility ?
        <div css={[styles.label]}>
          <div css={styles.icon}>
            <Icon name="public" size="1.3rem" />
          </div>
          <EuroHeading4 css={[styles.labelTextActive]}>Public Project</EuroHeading4>
        </div>
        :
        <div css={[styles.label]}>
          <div css={styles.icon}>
            <Icon name="lock" size="1.3rem" />
          </div>
          <EuroHeading4>Private Project</EuroHeading4>
        </div>
      }

      {/* */}
      <button type="button" css={[styles.toggleBtn, visibility ? styles.toggleBtnActive : null]} onClick={onClickHandle}>
        <div css={[styles.toggleMark, visibility ? styles.toggleMarkActive : null]}></div>
      </button>

      {/* */}
      <input hidden type="text" name={name} value={!visibility ? 'true' : 'false'} readOnly />
    </div>
  )
}

//
export default ProjectVisibilityCheck

// --- --- ---
// style:
//
const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid var(--primary-100);
    border-radius: 6px;
    background-color: var(--color-150);
    transition: background-color .25s linear;
  `,
  containerActive: css`
    background-color: var(--primary);
  `,
  icon: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--color-100);
  `,
  label: css`
    display: flex;
    align-items: center;
    column-gap: .6rem;
  `,
  labelTextActive: css`
    color: var(--color-100);
  `,
  toggleBtn: css`
    display: block;
    width: 2.6rem;
    height: 1.6rem;
    border-radius: 99px;
    background-color: var(--color-400);
  `,
  toggleBtnActive: css`
    background-color: var(--color-850);
  `,
  toggleMark: css`
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    background-color: var(--color-100);
    border: 2px solid var(--color-400);
    border-radius: 50%;
    transition: transform .2s ease-out;
  `,
  toggleMarkActive: css`
    transform: translateX(1.1rem);
    border-color: var(--color-850);
  `
}