import { Fragment } from "react";
import { Paragraph } from "../../../styles/styled/common/typography/Text.styled";

interface BreakLineProps {
  paragraph: string;
  shrink?: boolean;
  maxHeight?: number;
}

const BreakLine: React.FC<BreakLineProps> = ({paragraph, shrink, maxHeight}) => {
  if (!paragraph) {
    return <></>
  }
  if (paragraph.split) {
    return (
      <Paragraph shrink={shrink} maxHeight={maxHeight}>
        {paragraph.split('\n').map((line, index) => {
          return <Fragment key={`line_${index}`}>
            {index > 0 && <br />}
            {line}
          </Fragment>
        })}
      </Paragraph>
    )
  }
  //
  return <p>{paragraph}</p>
}

//
export default BreakLine