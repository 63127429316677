import styled from "@emotion/styled";
import { Link, LinkProps } from "react-router-dom";

//
interface LogoBlockProps extends LinkProps {}
/**
 * 
 */
export const LogoBlock = styled((props: LogoBlockProps) => (
  <Link {...props}>
    <img src="/images/logo.svg" width="150" height="27" alt="KICKOFF" style={{ display: 'block' }} />
  </Link>
))`
`