import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { useCallback, useState } from "react"
import { useRecoilValue } from "recoil"
import tokenState from "../../../../../state/Auth0"
import { BasicButton } from "../../../../../styles/styled/common/buttons/BasicButton.styled"
import { LoadingWrapper } from "../../../../../styles/styled/common/LoadingPanel"
import { Heading3 } from "../../../../../styles/styled/common/typography/Heading.styled"
import { Paragraph } from "../../../../../styles/styled/common/typography/Text.styled"
import { HorizontalBlock } from "../../../../../styles/styled/layouts/HorizontalBlock.styled"
import { ModalConfirm } from "../../../../../styles/styled/modal/ModalConfirm.styled"
//
const apiPath = process.env.REACT_APP_API_PATH!

//
interface CollaboratorApplyConfirmProps {
  projectId: string;
  onCancel: VoidFunction;
  onApply: VoidFunction;
}

//
const CollaboratorApplyConfirm: React.FC<CollaboratorApplyConfirmProps> = ({ projectId, onCancel, onApply }) => {
  // トークン
  const authToken = useRecoilValue(tokenState)
  //
  const [isLoading, setIsLoading] = useState(false)
  //
  const [errorMessage, setErrorMessage] = useState<string>('')

  const onApplyHandle = useCallback(async () => {
    setIsLoading(true)
    setErrorMessage('')
    //
    const res = await fetch(`${apiPath}/collaborator`, {
      method: 'POST',
      body: JSON.stringify({
        projectId: Number(projectId),
        type: 99
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })

    //
    setIsLoading(false)

    if (!res.ok) {
      // エラー
      setErrorMessage(res.statusText)
      return false
    }

    // ---
    //
    onApply()
  }, [authToken, onApply, projectId])

  //
  return (
    <ModalConfirm>
      <Heading3>コラボレーターとしての参加申請をしますか？</Heading3>
      {errorMessage !== '' &&
        <Paragraph>{errorMessage}</Paragraph>
      }
      <HorizontalBlock align="flex-end">
        <BasicButton onClick={onCancel}>Cancel</BasicButton>
        <BasicButton onClick={onApplyHandle} color="primary">Apply</BasicButton>
      </HorizontalBlock>

      {/* ローディング */}
      {isLoading &&
        <LoadingWrapper />
      }
    </ModalConfirm>
  )
}

//
export default CollaboratorApplyConfirm
