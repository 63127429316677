import styled from '@emotion/styled'
import { euroFont } from '../../styles/variables'

/**
 * プロジェクト詳細のコンテンツ内のセクション
 */
export const StyledDetailSection = styled.section`
  background-color: var(--color-100);
  border-radius: 12px;
  box-shadow: 0 2px 6px var(--shadow-100);
  margin-bottom: 3rem;
`

/**
 * 
 */
 export const StyledDetailSectionHead = styled.div`
    display: flex;
    column-gap: .8rem;
    align-items: center;
    padding: 1.6rem 2rem;
    border-bottom: 1px solid var(--color-300);

    @media (max-width: 768px) {
      padding: 1.6rem 1rem;
      flex-wrap: wrap;
      row-gap: .8rem;
    }
 `

/**
 * プロジェクト詳細のセクション内のコンテンツエリア
 */
export const StyledDetailSectionContent = styled.div<{
  center?: boolean
}>`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  ${({center}) => center ? 'align-items: center;' : null}

  @media (max-width: 768px) {
    padding: 1.6rem 1rem;
  }
`

// --- --- ---
// Activities
//
export const StyledActivitiesType = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid var(--primary-100);
  color: var(--primary-100);
  ${euroFont('medium')}
  font-size: .84rem;
  letter-spacing: .02em;
  border-radius: 99px;
  padding: 3px 0;
`