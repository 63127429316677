import styled from "@emotion/styled"
import { LabelText } from "./Text.styled"


export const EuroHeading2 = styled.h2`
  ${({theme}) => theme.euroFont('bold')}
  font-size: 2.2rem;
  letter-spacing: .02em;
`
export const EuroHeading3 = styled.h3`
  ${({theme}) => theme.euroFont('bold')}
  font-size: 1.6rem;
  letter-spacing: .02em;
`
export const EuroHeading4 = styled.h4`
  ${({theme}) => theme.euroFont('medium')}
  font-size: 1.2rem;
  letter-spacing: .02em;
`
export const EuroHeading5 = styled.h5`
  ${({theme}) => theme.euroFont('medium')}
  font-size: 1rem;
  letter-spacing: .02em;
`

// LabelTextの拡張
export const EuroLabelText = styled(LabelText)`
  ${({theme}) => theme.euroFont('normal')}
`