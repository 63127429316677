import { Suspense, useCallback, useState } from "react"
import useSWRImmutable from 'swr/immutable'
import { css } from "@emotion/react"
import AccountCard from "../../components/elements/account/list/AccountCard"
import ProjectList from "../../components/elements/projects/list/ProjectList"
import ProjectListTab, { VALUE_TYPE } from "../../components/elements/projects/ProjectListTab"
import { useRecoilValue } from "recoil"
import tokenState from "../../state/Auth0"
import { MainWrapper } from "../../styles/styled/layouts/MainWrapper.styled"
import { AccountHero } from "../../styles/styled/account/AccountHero.styled"
import { LinkButton } from "../../styles/styled/common/buttons/BasicButton.styled"
import useSWR from "swr"
import { useSearchParams } from "react-router-dom"
import Pagination from "../../components/elements/list/Pagination"
import { LoadingFallback } from "../../styles/styled/suspense/LoadingFallback"

//
const options = [
  {
    label: "Owned",
    value: 1,
  },
  {
    label: "Collaborating",
    value: 2,
  },
  {
    label: "Favorited",
    value: 3,
  }
]

//
const AccountIndex: React.FC = () => {
  //
  const authToken = useRecoilValue(tokenState)
  // ログインユーザーの情報を取得
  const { data: user } = useSWRImmutable(authToken && [ '/user/logging-in', authToken ], { suspense: true })
  //
  const { data: account } = useSWR(user && [ `/user/${user.id}`, authToken ], { suspense: true })
  //
  const [ searchParams, setSearchParams ] = useSearchParams()
  //
  const [selected, setSelected] = useState<VALUE_TYPE>(options[0].value)
  const [totalCount, setTotalCount] = useState(0)
  //
  const [selectedParams, setSelectedParams] = useState<{ [key: string]: string}>({ isOwned: 'true' })

  //
  const onSelect = useCallback((value: VALUE_TYPE) => {
    setSelected(value)
    if (value === 1) {
      //owned
      setSelectedParams({
        isOwned: 'true',
      })
    } else if (value === 2) {
      // collabo
      setSelectedParams({
        isCollaborated: 'true'
      })
    } else {
      // fav
      setSelectedParams({
        isOwned: 'true',
      })
    }
    // クエリの更新（ページングを先頭に戻す）
    const q = Object.fromEntries(searchParams.entries())
    setSearchParams({...q, ...{ page: '1'}})
  }, [searchParams, setSearchParams])

  //
  const projectLoaded = useCallback(({ count }: { count: number}) => {
    setTotalCount(count)
  }, [])

  //
  return (
    <>
      {/* swrと一緒に別コンポーネントにする予定 */}
      {account && 
        <AccountHero coverSrc={account.coverImageUrl}>
          <AccountCard data={account} owner disabled />
        </AccountHero>
      }
      
      <MainWrapper>
        {/* */}
        <div css={styles.head}>
          {/* タブ */}
          <ProjectListTab onSelect={onSelect} options={options} selected={selected} />

          {/* 新規プロジェクト作成ボタン */}
          <div css={styles.headRightBlock}>
            <LinkButton to="/create">Create Project</LinkButton>
          </div>
        </div>

        {/* */}
        
        <Suspense fallback={<LoadingFallback />}>
          <ProjectList
            swrKey={`/user/${user.id}/project?${new URLSearchParams({ ...Object.fromEntries(searchParams), ...selectedParams})}`}
            onLoaded={projectLoaded}
          />
        </Suspense>

        {/* ページネーション */}
        <Pagination
          query={searchParams}
          page={Number(searchParams.get('page') || 1)}
          count={totalCount}
          path={`/account`}
        />
      </MainWrapper>
    </>
  )
}

//
export default AccountIndex

// --- --- ---
// style
//
const styles = {
  container: css`
    position: relative;
    width: 100%;
  `,
  head: css`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 60px 0;

    @media (max-width: 768px) {
      display: block;
    }
  `,
  headRightBlock: css`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      top: -70px;
    }
  `
}