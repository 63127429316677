import { Suspense, useCallback, useState } from "react"
import useSWRImmutable from 'swr/immutable'
import { css } from "@emotion/react"
import AccountCard from "../../components/elements/account/list/AccountCard"
import ProjectList from "../../components/elements/projects/list/ProjectList"
import ProjectListTab, { VALUE_TYPE } from "../../components/elements/projects/ProjectListTab"
import { useParams, useSearchParams,  } from "react-router-dom"
import { MainWrapper } from "../../styles/styled/layouts/MainWrapper.styled"
import { AccountHero } from "../../styles/styled/account/AccountHero.styled"
import Pagination from "../../components/elements/list/Pagination"

//
const options = [
  {
    label: "Owned",
    value: 1,
  },
  {
    label: "Collaborating",
    value: 2,
  },
]

//
const CollaboratorDetail: React.FC = () => {
  //
  const { userId } = useParams()
  const { data: account } = useSWRImmutable(`/user/${userId}`, { suspense: true })

  //
  const [ searchParams, setSearchParams ] = useSearchParams()

  //
  const [selected, setSelected] = useState<VALUE_TYPE>(options[0].value)
  const [totalCount, setTotalCount] = useState(0)
  //
  const [selectedParams, setSelectedParams] = useState<{ [key: string]: string}>({ isOwned: 'true' })

  //
  const onSelect = useCallback((value: VALUE_TYPE) => {
    setSelected(value)
    //
    if (value === 1) {
      //owned
      setSelectedParams({
        isOwned: 'true',
      })
    } else if (value === 2) {
      // collabo
      setSelectedParams({
        isCollaborated: 'true'
      })
    }
    // クエリの更新（ページングを先頭に戻す）
    const q = Object.fromEntries(searchParams.entries())
    setSearchParams({...q, ...{ page: '1'}})
  }, [searchParams, setSearchParams])

  //
  const projectLoaded = useCallback(({ count }: { count: number}) => {
    setTotalCount(count)
  }, [])

  //
  if (!account) {
    return (<></>)
  }

  //
  return (
    <>
      {/* AccountIndexと同じく */}
      <AccountHero coverSrc={account.coverImageUrl}>
        <AccountCard data={account} disabled />
      </AccountHero>
      
      <MainWrapper style={{ marginTop: '3rem' }}>
        {/* */}
        <div css={styles.head}>
          <ProjectListTab onSelect={onSelect} options={options} selected={selected} />
        </div>

        {/* 絞り込み後のプロジェクトリスト */}
        <Suspense>
          <ProjectList
            swrKey={`/user/${userId}/project?${new URLSearchParams({ ...Object.fromEntries(searchParams), ...selectedParams})}`}
            onLoaded={projectLoaded}
          />
        </Suspense>

        {/* ページネーション */}
        <Pagination
          query={searchParams}
          page={Number(searchParams.get('page') || 1)}
          count={totalCount}
          path={`/collaborators/${userId}`}
        />
      </MainWrapper>
    </>
  )
}

//
export default CollaboratorDetail

// --- --- ---
// style
const styles = {
  head: css`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 60px 0;

    @media (max-width: 768px) {
      display: block;
    }
  `,
}