import styled from "@emotion/styled";
import { TextAnchor } from "../common/buttons/TextButton.styled";
import { Heading5 } from "../common/typography/Heading.styled";
import { SmallText } from "../common/typography/Text.styled";

//
interface FooterCompProps extends React.HtmlHTMLAttributes<HTMLElement> {}

/**
 * 
 */
const FooterComp = styled((props: FooterCompProps) => (
  <footer {...props}>
    <FooterContent>
      <div className="footer-head">
        <img src="/images/logo_blk.svg" width="150" height="27" alt="kickoff" />
      </div>

      {/* フッターメニュー */}
      <StyledFootMenu>
        <StyledFootMenuColumn>
          <StyledFootMenuList>
            <li><Heading5>Product</Heading5></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
          </StyledFootMenuList>
          <StyledFootMenuList>
            <li><Heading5>Product</Heading5></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
          </StyledFootMenuList>
        </StyledFootMenuColumn>

        <StyledFootMenuColumn>
          <StyledFootMenuList>
            <li><Heading5>Product</Heading5></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
          </StyledFootMenuList>
        </StyledFootMenuColumn>

        <StyledFootMenuColumn>
          <StyledFootMenuList>
            <li><Heading5>Product</Heading5></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
          </StyledFootMenuList>
        </StyledFootMenuColumn>

        <StyledFootMenuColumn>
          <StyledFootMenuList>
            <li><Heading5>Product</Heading5></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
          </StyledFootMenuList>
        </StyledFootMenuColumn>

        <StyledFootMenuColumn>
          <StyledFootMenuList>
            <li><Heading5>Product</Heading5></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
          </StyledFootMenuList>
          <StyledFootMenuList>
            <li><Heading5>Product</Heading5></li>
            <li><TextAnchor size="middle" underline href="/">Sub Menu</TextAnchor></li>
            <li><TextAnchor as="a" size="middle" underline href="/">Sub Menu</TextAnchor></li>
          </StyledFootMenuList>
        </StyledFootMenuColumn>
      </StyledFootMenu>

      {/* */}
      <div className="footer-bottom">
        <SmallText>© kickoff 2022 - </SmallText>
      </div>
    </FooterContent>
  </footer>
))`
  width: 100%;
  background-color: var(--color-100);
  border-top: 1px solid var(--color-200);
  padding: 60px 0;
`

export default FooterComp

// ---
//
const FooterContent = styled.div`
  width: ${({theme}) => theme.content.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: ${({theme}) => theme.spContent.maxWidth};
  }
`

//
 const StyledFootMenu = styled.div`
 width: 100%;
 display: flex;
 justify-content: space-between;
 margin: 60px 0;
`

//
const StyledFootMenuColumn = styled.div`
 width: 18%;
 display: flex;
 flex-direction: column;
 row-gap: 20px;
`

//
const StyledFootMenuList = styled.ul`
 > li {
   &:first-of-type {
     margin-bottom: 0.5em;
   }
 }
`