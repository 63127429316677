import { css } from "@emotion/react";
import styled from "@emotion/styled";

//
interface IconProps extends React.HtmlHTMLAttributes<HTMLSpanElement> {
  name: string;
  size?: string;
  dark?: boolean;
  primary?: boolean;
  fill?: boolean;
}

// DOMのタグに渡す必要が無い要素は引数の段階で削除しておく
export const Icon = styled(({ name, dark, size, primary, fill, ...props }: IconProps) => (
  <span {...props}>
    {name}
  </span>
))`
  display: block;

  font-variation-settings:
    'FILL' ${({fill}) => fill ? 1 : 0},
    'wght' 200,
    'GRAD' 200,
    'opsz' 40;

  font-size: ${({size}) => (size) ? size : '24px'};

  ${({dark}) => dark && css` color: var(--color-100); `};
  ${({primary}) => primary && css` color: var(--primary); `};
`

// 追加Props
Icon.defaultProps = {
  className: 'material-symbols-outlined'
}