import styled from "@emotion/styled";

export const ModalConfirm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  background-color: var(--color-100);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 10px var(--shadow-100);
  overflow: hidden;
`