import { css } from "@emotion/react";
import { Dispatch, FormEvent, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import useSWR from "swr";
import { ErrorType } from "../../../lib/entity/ErrorType";
import { POST_IMAGE } from "../../../lib/entity/PostImage";
import { PhaseProps } from "../../../lib/entity/Project";
import tokenState from "../../../state/Auth0";
import { ResponseError } from "../../../styles/styled/common/blocks/ResponseError.styled";
import { KickoffButton } from "../../../styles/styled/common/buttons/KickoffButton.styled";
import { EuroFormLabel, FormBlock, FormSection } from "../../../styles/styled/common/form/FormLayout.styled";
import { InputText } from "../../../styles/styled/common/form/Input.styled";
import { Textarea } from "../../../styles/styled/common/form/Textarea.styled";
import { Icon } from "../../../styles/styled/common/icons/Icon.styled";
import { ProductIcon } from "../../../styles/styled/common/icons/ProductIcon.styled";
import { EuroHeading3 } from "../../../styles/styled/common/typography/EuroText.styled";
import { Heading4 } from "../../../styles/styled/common/typography/Heading.styled";
import PostJson from "../../common/utls/PostJson";
import CategoryBlock from "./CategoryBlock";
import FormTeamBuild from "./FormTeamBuild";
import PhaseList from "./PhaseList";
import ProjectVisibilityCheck from "./ProjectVisibilityCheck";
import UploadHeroImage from "./UploadHeroImage";

// ---
const apiPath = process.env.REACT_APP_API_PATH!

//
interface ProjectCreateFromProps {
  setLoadingState: Dispatch<React.SetStateAction<boolean>>;
  onCreateComplete: (id: string) => void;
}
//
const ProjectCreateForm: React.FC<ProjectCreateFromProps> = ({ setLoadingState, onCreateComplete }) => {
  // トークン
  const authToken = useRecoilValue(tokenState)
  // ログインユーザーの情報を取得
  const { data: account } = useSWR([ '/user/logging-in', authToken ], { suspense: true })
  //
  const formRef = useRef<HTMLFormElement>(null)
  // フォームバリデーションのフラグ
  const [validate, setValidate] = useState(false)
  // エラー表示用
  const [errorState, setErrorState] = useState<ErrorType>()
  // ヒーロー画像（バリデーション用）
  const [heroImage, setHeroImage] = useState<File>()
  //
  // フェーズ
  const [phaseList, setPhaseList] = useState<PhaseProps<Date>[]>([])


  // --- --- ---
  // バリデーション確認用
  useEffect(() => {
    if (formRef.current) {
      const targetForm = formRef.current
      const checkValication = () => {
        if (targetForm) {
          setValidate(targetForm.checkValidity())
        }
      }
      targetForm.addEventListener('change', checkValication)
      //
      return () => {
        targetForm.removeEventListener('change', checkValication)
      }
    }
  }, [])


  // ---
  // ファイル処理
  const onChangeFileHandle = useCallback((file: File | undefined) => {
    // formの変更イベントが発火しない
    if (!file) {
      // ファイルが削除されたのでバリデーションをfalseに
      setValidate(false)
    }
    setHeroImage(file)
  }, [])


  // --- --- ---
  // fetch処理
  const postFetch = useCallback(async (formData: FormData, addProps: any) => {
    //
    setLoadingState(true)

    // FormDataからjsonへ
    const postJson = PostJson(formData)
    //
    const res = await fetch(`${apiPath}/project`, {
      method: 'POST',
      body: JSON.stringify({ ...postJson, ...addProps }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      })
    })

    //
    setLoadingState(false)
    //
    if (res.ok) {
      // APIへのポストは出来た
      const resJson = await res.json()
      // 作成の完了をイベント通知
      onCreateComplete(resJson.id)
    } else {
      const errorJson = await res.json()
      const message =  Array.isArray(errorJson.detail) ? errorJson.detail.join('\n') : errorJson.detail.toString()
      setErrorState({
        id: errorState ? errorState.id + 1 : 0,
        isError: true,
        message: message,
        status: res.status,
      })
    }
  }, [authToken, errorState, onCreateComplete, setLoadingState])

  // ---
  // 送信前処理
  const onSubmitHandle = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (formRef.current) {

      //
      setErrorState(undefined)

      const fd = new FormData(formRef.current)
      // オーナー
      fd.append('ownerUserId', account.id)
      // ---
      // coFounderUserIds: 配列へパース
      const coFounderUserIds = fd.get('coFounderUserIds') as string
      fd.delete('coFounderUserIds')
      // collaborators: 配列へパース
      const collaborators = fd.get('collaborators') as string
      fd.delete('collaborators')
      // 画像の情報取得
      const postHero: POST_IMAGE = {
        file: fd.get('heroImage_base64')?.toString() || '',
        name: fd.get('heroImage_name')?.toString() || ''
      }
      // 要らなくなった要素は削除
      fd.delete('heroImage')
      fd.delete('heroImage_base64')
      fd.delete('heroImage_name')


      // ポスト
      // フェーズデータ（構造化されているので、一旦formDataとは別に処理）
      postFetch(fd, {
        heroImage: postHero,
        collaborators: collaborators ? collaborators.split(',').map(v => Number(v)) : [],
        coFounderUserIds: coFounderUserIds ? coFounderUserIds.split(',').map(v => Number(v)) : [],
        phases: phaseList,
      })
    }
  }, [account.id, phaseList, postFetch])

  // ---
  if (!account) {
    return <></>
  }

  // ---
  return (

    <form ref={formRef} style={{display: 'contents'}} onSubmit={onSubmitHandle}>
      {/* プロジェクト */}
      <FormSection width="800px">
        <FormBlock>
          <EuroFormLabel required>Project Name</EuroFormLabel>
          <InputText name="name" id="name" type="text" placeholder="プロジェクト名を入力して下さい" required />
        </FormBlock>
        <FormBlock>
          <EuroFormLabel required>Hero Image</EuroFormLabel>
          <div style={{ height: '270px' }}>
            <UploadHeroImage onChangeFile={onChangeFileHandle} name="heroImage" />
          </div>
        </FormBlock>
        <FormBlock>
          <EuroFormLabel required>Description</EuroFormLabel>
          <Textarea name="description" id="description" rows={6} style={{ resize: "vertical" }} placeholder="プロジェクト概要を入力して下さい" required />
        </FormBlock>

        {/* カテゴリ */}
        <CategoryBlock />

        {/* */}
        <FormBlock>
          <EuroFormLabel>Project Visibility</EuroFormLabel>
          <ProjectVisibilityCheck name="isPrivate" />
        </FormBlock>
      </FormSection>

      {/* チーム・ビルド */}
      <Suspense>
        <FormTeamBuild ownerName={account.name} />
      </Suspense>

      {/* Integration */}
      <FormSection width="800px">
        <EuroHeading3>Integration</EuroHeading3>
        {/** Jira */}
        <div css={styles.integrationBlock}>
          <div className="block-head">
            <ProductIcon name="jira" />
            <Heading4>Jira</Heading4>
          </div>
          <FormBlock>
            <EuroFormLabel>Token:</EuroFormLabel>
            <InputText name="jiraApiToken" />
          </FormBlock>
          <FormBlock>
            <EuroFormLabel>Domain:</EuroFormLabel>
            <InputText name="jiraDomain" />
          </FormBlock>
        </div>
        {/** Slack */}
        <div css={styles.integrationBlock}>
          <div className="block-head">
            <ProductIcon name="slack" />
            <Heading4>Slack</Heading4>
          </div>
          <FormBlock>
            <EuroFormLabel>API Token:</EuroFormLabel>
            <InputText name="slackApiToken" />
          </FormBlock>
          <FormBlock>
            <EuroFormLabel>Team ID:</EuroFormLabel>
            <InputText name="slackTeamId" />
          </FormBlock>
          <FormBlock>
            <EuroFormLabel>Channel ID:</EuroFormLabel>
            <InputText name="slackChannelId" />
          </FormBlock>
        </div>
      </FormSection>

      {/* Start & Goal */}
      <FormSection width="800px">
        <EuroHeading3>Start &amp; Goal</EuroHeading3>
        <div css={styles.dateBlock}>
          <FormBlock>
            <EuroFormLabel>Start:</EuroFormLabel>
            <input name="projectStartDate" type="date" />
          </FormBlock>
          <Icon name="arrow_right_alt" />
          <FormBlock>
            <EuroFormLabel>Goal:</EuroFormLabel>
            <input name="projectCompletionDate" type="date" />
          </FormBlock>
        </div>
        <FormBlock>
          <Heading4>Phase</Heading4>
          <PhaseList phaseList={phaseList} setPhaseList={setPhaseList} />
        </FormBlock>
        <FormBlock>
          <Heading4>Goal（自由記述）</Heading4>
          <Textarea name="memo" id="memo" style={{ resize: "vertical" }} cols={30} rows={10} />
        </FormBlock>
      </FormSection>

      {/** エラー */}
      {(errorState && errorState.isError) && 
        <ResponseError
          style={{width: '600px'}}
          key={`error_${errorState.id}`}
          title="エラーが発生しました"
          status={errorState.status?.toString()}
          message={errorState.message}
        />
      }

      {/* 送信ボタン */}
      <div css={styles.bottomBlock}>
        <KickoffButton disabled={!validate || !heroImage} />
      </div>
    </form>
  )
}

//
export default ProjectCreateForm

// --- --- ---
// style:
//
const styles = {
  section: css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.7rem;
    border: 1px solid var(--color-300);
    border-radius: 12px;
  `,
  fixedBlock: css`
    display: flex;
    > input {
      width: 100%;
    }
    > button {
      padding: 0 0 0 .5rem;
    }
  `,
  integrationBlock: css`
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    .block-head {
      display: flex;
      align-items: center;
      column-gap: .3em;
    }
  `,
  dateBlock: css`
    display: flex;
    column-gap: 2rem;
    align-items: center;
  `,
  bottomBlock: css`
    display: flex;
    justify-content: center;
  `
}