import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link, LinkProps } from "react-router-dom";
import { Icon } from "../icons/Icon.styled";

interface IconLinkProps extends LinkProps {
  name: string;
  dark?: boolean;
}
//
export const IconLink = styled(({ name, dark, ...props}: IconLinkProps) => (
  <Link {...props}>
    <Icon name={name} />
  </Link>
))`
  ${({dark}) => dark && css` color: var(--color-100); `};
`